import { connect } from '../../data/connect'
import { TaxIrStuffOption } from '../../models/mfactor/TaxIrStuffOption'
import { GtinService } from '../../services/GtinService'
import NumberIonInput from '../NumberIonInput'
import { convertToEnglish } from '../util/Number'
import TkSpinner from '../util/TkSpinner'
import { failure } from '../util/Toast'
import remoteErrorExtractor from '../util/remoteErrorExtractor'
import './StuffIdModal.scss'
import {
  IonButton,
  IonCol,
  IonContent,
  IonHeader,
  IonIcon,
  IonInput,
  IonText,
  IonTitle,
  IonToolbar,
} from '@ionic/react'
import { IonItem, IonLabel, IonPage, IonRow, IonSelect, IonSelectOption, useIonToast } from '@ionic/react'
import { t } from 'i18next'
import { search } from 'ionicons/icons'
import { FC, memo, useState } from 'react'

interface OwnProps {
  defaultId?: number
  defaultDesc?: string
  productName?: string
  onDismiss: (data?: TaxIrStuffOption | null | undefined | number, role?: string) => void
}
interface StateProps {}
interface DispatchProps {}
interface StuffIdModalProps extends OwnProps, StateProps, DispatchProps {}
const StuffIdModal: FC<StuffIdModalProps> = ({ defaultId, defaultDesc, productName, onDismiss }) => {
  const [id, setId] = useState(defaultId)
  const [desc, setDesc] = useState(defaultDesc)
  const [type, setType] = useState()
  const [fetching, setFetching] = useState(false)
  const [items, setItems] = useState<TaxIrStuffOption[]>([])
  const [toast] = useIonToast()

  const fetchItems = async () => {
    setFetching(true)
    try {
      const res: any = await GtinService.Instance.get(
        `/v1/tax-ir/stuff/search?${!!id ? `id=${id}` : ''}${!!desc ? `&desc=${desc}` : ''}${
          !!type ? `&type=${type}` : ''
        }`
      )
      if (res) {
        setItems(res.data || [])
      }
    } catch (err) {
      failure(remoteErrorExtractor(err), toast)
    }
    setFetching(false)
  }

  const highlightMatch = (text: string, searchTerm?: string) => {
    if (!searchTerm || !text) return text

    const cleanSearchTerm = searchTerm.replace(/،+$/, '')
    const searchWords = cleanSearchTerm.split(' ').map((word) => word.replace(/،/g, ''))

    const parts = text.split(' ')

    return parts.map((part, index) => {
      const cleanPart = part.replace(/،/g, '')
      const isMatch = searchWords.some((word) => word.toLowerCase() === cleanPart.toLowerCase())
      return isMatch ? <b key={index}>{part} </b> : `${part} `
    })
  }

  return (
    <IonPage id='stuff-id-modal'>
      {!!productName && (
        <IonHeader>
          <IonToolbar>
            <IonText>{productName}</IonText>
          </IonToolbar>
        </IonHeader>
      )}
      <IonContent>
        <IonRow>
          <IonCol>
            <NumberIonInput value={id} label={t('StuffId')} onValueChange={setId} />
          </IonCol>
        </IonRow>
        <IonRow>
          <IonCol>
            <IonInput
              value={desc}
              label={t('StuffDesc')}
              onIonInput={(e: any) => setDesc(convertToEnglish(e.detail.value))}
            />
          </IonCol>
        </IonRow>
        <IonRow>
          <IonCol>
            <IonSelect value={type} onIonChange={(ev) => setType(ev.detail.value)} interface='popover'>
              <IonSelectOption value={'UNKNOWN'}>{t<string>('All')}</IonSelectOption>
              <IonSelectOption value={'KALA_ID_DEDICATED_INTERNAL_PRODUCTION'}>
                {t<string>('KALA_ID_DEDICATED_INTERNAL_PRODUCTION')}
              </IonSelectOption>
              <IonSelectOption value={'KALA_ID_DEDICATED_IMPORTED'}>
                {t<string>('KALA_ID_DEDICATED_IMPORTED')}
              </IonSelectOption>
              <IonSelectOption value={'SERVICE_ID_DEDICATED'}>{t<string>('SERVICE_ID_DEDICATED')}</IonSelectOption>
              <IonSelectOption value={'KALA_ID_GENERAL_INTERNAL_PRODUCTION'}>
                {t<string>('KALA_ID_GENERAL_INTERNAL_PRODUCTION')}
              </IonSelectOption>
              <IonSelectOption value={'KALA_ID_GENERAL_IMPORTED'}>
                {t<string>('KALA_ID_GENERAL_IMPORTED')}
              </IonSelectOption>
              <IonSelectOption value={'SERVICE_ID_GENERAL'}>{t<string>('SERVICE_ID_GENERAL')}</IonSelectOption>
            </IonSelect>
          </IonCol>
        </IonRow>
        <IonRow>
          <IonCol>
            <IonButton onClick={fetchItems} disabled={fetching} expand='block'>
              <IonIcon icon={search} slot='start' />
              {t<string>('Search')}
            </IonButton>
          </IonCol>
        </IonRow>
        <IonRow>
          <IonCol>
            {fetching && <TkSpinner />}
            {!fetching &&
              items.map((ti: TaxIrStuffOption) => (
                <IonItem
                  key={ti.value}
                  button={true}
                  onClick={(event: React.MouseEvent) => {
                    event.stopPropagation()
                    onDismiss(ti)
                  }}
                >
                  <IonLabel className='ion-text-wrap'>
                    {ti.value}
                    <p>{highlightMatch(ti.label, desc)}</p>
                  </IonLabel>
                </IonItem>
              ))}
          </IonCol>
        </IonRow>
      </IonContent>
    </IonPage>
  )
}

export default connect<OwnProps, StateProps, DispatchProps>({
  mapStateToProps: (state) => ({}),
  mapDispatchToProps: {},
  component: memo(StuffIdModal),
})
