import { error, log } from '../../components/util/Log'
import { failure } from '../../components/util/Toast'
import { connect } from '../../data/connect'
import { User } from '../../models/User'
import PageContainer from '../PageContainer'
import './PlanInvoice.scss'
import { IonButton, IonCol, IonGrid, IonIcon, IonRow, IonThumbnail, useIonToast } from '@ionic/react'
import { t } from 'i18next'
import { refresh } from 'ionicons/icons'
import { FC, useEffect, useRef, useState } from 'react'
import { RouteComponentProps, useParams } from 'react-router'
import TaakSDK from 'taak-sdk'
import { FactorDTO, FactorItem } from 'taak-sdk/dist/mfactor/types'
import { ObtainTokenCommand } from 'taak-sdk/dist/mfactor/types'
import { TaakResponse } from 'taak-sdk/dist/taak-response'

interface OwnProps extends RouteComponentProps {}
interface StateProps {
  user?: User
  lang?: string
  isAuthenticated: boolean
}
interface DispatchProps {}
interface PlanInvoiceProps extends OwnProps, StateProps, DispatchProps {}
const PlanInvoice: FC<PlanInvoiceProps> = ({ user, isAuthenticated, lang, history }) => {
  const taakClient = new TaakSDK({
    apiKey: import.meta.env.VITE_APP_WEB_PUSH_API_KEY || '',
    debug: false,
    local: false,
  })
  const [inprogress, setInprogress] = useState(false)
  const [tokenInprogress, setTokenInprogress] = useState(false)
  const [token, setToken] = useState('')
  const [toast] = useIonToast()
  const [factor, setFactor] = useState<FactorDTO>()
  const { factorPublicId } = useParams<any>()
  const productMap = useRef<any>()

  const initFactor = async () => {
    setInprogress(true)
    const res: TaakResponse = await taakClient.getFactor(factorPublicId)
    if (res.status === 200) {
      setFactor(res.data)
      if (res.data.paid) {
        setTimeout(() => {
          history.push(`/plan/invoice/callback?publicId=${res.data.publicId}`)
          window.location.reload()
        }, 300)
      }
    } else failure(`${res.status} - ${res.error}`, toast)
    setInprogress(false)
  }

  const initToken = async () => {
    setTokenInprogress(true)
    if (factor?.publicId && factor?.total) {
      const cmd: ObtainTokenCommand = {
        publicId: factor?.publicId,
        appPublicId: import.meta.env.VITE_APP_WEB_PUSH_APP_PUBLIC_ID,
        ipgCompany: 'SAMAN',
        amount: factor?.total,
      }
      const res: TaakResponse = await taakClient.ipgObtainToken(cmd)
      if (res.status === 200 && res.data?.status === 1) {
        setToken(res.data?.token)
      } else {
        failure(res?.data?.errorDesc, toast)
      }
    }
    setTokenInprogress(false)
  }

  const initProductMap = async () => {
    if (factor?.items?.length) {
      for (let i = 0; i < factor.items.length; i++) {
        taakClient
          .getProduct(factor.items[i].i)
          .then((res: TaakResponse) => {
            if (res.status === 200)
              productMap.current = {
                ...productMap.current,
                [factor.items[i].i]: res.data,
              }
          })
          .catch((err: any) => error('Fetch product failure', err))
      }
    }
  }

  useEffect(() => {
    if (!factor && user && !inprogress && import.meta.env.VITE_APP_WEB_PUSH_APP_PUBLIC_ID && isAuthenticated) {
      initFactor()
    }
    if (factor && user && !token && !tokenInprogress && isAuthenticated && !factor.paid) {
      initToken()
      initProductMap()
    }
  }, [factor, user])

  useEffect(() => {
    log(productMap?.current)
  }, [productMap?.current])

  return (
    <PageContainer id='plan-invoice' title={t<string>('Startup Plan Invoice')} isPrivate={true}>
      <IonGrid>
        <IonRow>
          <IonCol className='header'>{t<string>('Name')}</IonCol>
          <IonCol>
            {user?.given_name} {user?.family_name}
          </IonCol>
        </IonRow>
        <IonRow>
          <IonCol className='header'>{t<string>('Email')}</IonCol>
          <IonCol>{user?.email}</IonCol>
        </IonRow>
        {!factor && !inprogress && (
          <IonButton onClick={initFactor} disabled={inprogress}>
            <IonIcon icon={refresh} slot='icon-only' />
          </IonButton>
        )}
        {factor && (
          <>
            <IonRow>
              <IonCol className='header'>{t<string>('Product')}</IonCol>
              <IonCol className='header'>{t<string>('Month count')}</IonCol>
              <IonCol className='header'>{t<string>('Price')}</IonCol>
            </IonRow>
            {factor?.items.map((itm: FactorItem) => (
              <IonRow key={itm.i}>
                <IonCol>
                  {lang === 'fa'
                    ? productMap?.current?.[itm.i]?.nameFa || productMap?.current?.[itm.i]?.name
                    : productMap?.current?.[itm.i]?.name}
                </IonCol>
                <IonCol>{itm.q}</IonCol>
                <IonCol>{itm.p.toLocaleString(navigator.language)} ﷼</IonCol>
              </IonRow>
            ))}
            <IonRow>
              <IonCol size='8' className='header'>
                {t<string>('Total')}
              </IonCol>
              <IonCol>{factor?.total?.toLocaleString(navigator.language)} ﷼</IonCol>
            </IonRow>
            <IonRow>
              <IonCol>{t<string>('Tracking code')}</IonCol>
              <IonCol>
                <strong>{factor?.trackingCode}</strong>
              </IonCol>
            </IonRow>
            <IonRow className='ion-text-end'>
              {!token && !tokenInprogress && !inprogress && isAuthenticated && user && factor && (
                <IonCol>
                  <IonButton onClick={initToken} disabled={tokenInprogress}>
                    {t<string>('Retry')}
                    <IonIcon icon={refresh} slot='end' />
                  </IonButton>
                </IonCol>
              )}
              <IonCol>
                <form method='POST' action='https://sep.shaparak.ir/OnlinePG/OnlinePG'>
                  <input type='hidden' name='Token' value={token} />
                  <input type='hidden' name='GetMethod' value='true' />
                  <IonButton size='large' disabled={!factor || !token} type='submit'>
                    <span className='ion-padding-end'>{t<string>('Pay with IPG Shaparak')}</span>
                    <IonThumbnail slot='end'>
                      <img src='/assets/img/ipg/sep.jpg' alt='sep' />
                    </IonThumbnail>
                  </IonButton>
                </form>
              </IonCol>
            </IonRow>
          </>
        )}
      </IonGrid>
    </PageContainer>
  )
}

export default connect<OwnProps, StateProps, DispatchProps>({
  mapStateToProps: (state) => ({
    user: state.user.user,
    lang: state.user.lang,
    isAuthenticated: state.user.isAuthenticated,
  }),
  mapDispatchToProps: {},
  component: PlanInvoice,
})
