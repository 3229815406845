import { memo } from 'react'

const Enamad = () => {
  return (
    <>
      {import.meta.env.VITE_APP_NAME === 'taak' && (
        <a
          referrerPolicy='origin'
          target='_blank'
          href='https://trustseal.enamad.ir/?id=357563&amp;Code=CGCuDCzrGWgwJ9QcPZx8'
        >
          <img
            referrerPolicy='origin'
            src='https://Trustseal.eNamad.ir/logo.aspx?id=357563&amp;Code=CGCuDCzrGWgwJ9QcPZx8'
            alt=''
            style={{ cursor: 'pointer' }}
            id='CGCuDCzrGWgwJ9QcPZx8'
          />
        </a>
      )}
      {import.meta.env.VITE_APP_NAME === 'mFactor' && (
        <a
          referrerPolicy='origin'
          target='_blank'
          href='https://trustseal.enamad.ir/?id=584603&Code=Bok33acuxKOff7Dmnc0zD1YTWzizw27C'
        >
          <img
            referrerPolicy='origin'
            src='https://trustseal.enamad.ir/logo.aspx?id=584603&Code=Bok33acuxKOff7Dmnc0zD1YTWzizw27C'
            alt=''
            style={{ cursor: 'pointer' }}
            data-code='Bok33acuxKOff7Dmnc0zD1YTWzizw27C'
            id='Bok33acuxKOff7Dmnc0zD1YTWzizw27C'
          />
        </a>
      )}
    </>
  )
}

export default memo(Enamad)
