import { memo } from 'react'

const SamandehiTaakcloud = () => {
  return (
    <>
      {import.meta.env.VITE_APP_NAME === 'taak' && (
        <img
          referrerPolicy='origin'
          id='rgvjwlaosizpwlaooeukesgt'
          style={{ cursor: 'pointer' }}
          onClick={() =>
            window.open(
              'https://logo.samandehi.ir/Verify.aspx?id=349480&p=xlaoaodspfvlaodsmcsiobpd',
              'Popup',
              'toolbar=no, scrollbars=no, location=no, statusbar=no, menubar=no, resizable=0, width=450, height=630, top=30'
            )
          }
          alt='logo-samandehi'
          src='https://logo.samandehi.ir/logo.aspx?id=349480&p=qftishwlbsiyshwlaqgwlyma'
        />
      )}
    </>
  )
}

export default memo(SamandehiTaakcloud)
