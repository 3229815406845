import { Model } from '@nozbe/watermelondb'
import { Associations } from '@nozbe/watermelondb/Model'
import { date, text, readonly, json, writer, nochange } from '@nozbe/watermelondb/decorators'
import { fixKafYe } from '../../components/util/Word'

export default class Person extends Model {
  static table = 'person'
  static associations: Associations = {
    factor: { type: 'has_many', foreignKey: 'payer' },
  }

  @nochange @text('app_public_id') appPublicId!: string
  @text('person_type') personType!: string
  @text('name') name!: string
  @text('national_id') nationalId?: string
  @text('economic_code') economicCode?: string
  @text('postal_code') postalCode?: string
  @text('passport_number') passportNumber?: string
  @json('detail', (json) => json) detail?: string
  @readonly @date('created_at') createdAt!: number
  @readonly @date('updated_at') updatedAt!: number

  @writer async markAsDeletedForReal() {
    await super.markAsDeleted()
  }

  workaroundSetRaw() {
    this._setRaw('app_public_id', this.appPublicId)
    this._setRaw('person_type', this.personType)
    this._setRaw('name', fixKafYe(this.name) as string)
    this._setRaw('national_id', this.nationalId as string)
    this._setRaw('economic_code', this.nationalId as string)
    this._setRaw('postal_code', this.nationalId as string)
    this._setRaw('passport_number', this.passportNumber as string)
    this._setRaw('detail', this.detail || null)
    return this
  }

  workaroundSetModel() {
    this.appPublicId = this._getRaw('app_public_id') as string
    this.personType = this._getRaw('person_type') as string
    this.name = this._getRaw('name') as string
    this.nationalId = this._getRaw('national_id') as string
    this.economicCode = this._getRaw('economic_code') as string
    this.postalCode = this._getRaw('postal_code') as string
    this.passportNumber = this._getRaw('passport_number') as string
    this.detail = this._getRaw('detail') as string
    this.createdAt = this._getRaw('created_at') as number
    this.updatedAt = this._getRaw('updated_at') as number
    return this
  }
}
