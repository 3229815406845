import PersonTypeIcon from '../../components/icon/PersonTypeIcon'
import TkSpinner from '../../components/util/TkSpinner'
import { failure } from '../../components/util/Toast'
import { fixKafYe } from '../../components/util/Word'
import remoteErrorExtractor from '../../components/util/remoteErrorExtractor'
import { connect } from '../../data/connect'
import { setRefreshPersonList } from '../../data/factor/factor.actions'
import { App } from '../../models/App'
import Person from '../../models/mfactor/Person'
import { PersonType } from '../../models/mfactor/PersonType'
import { MFactorService } from '../../services/MFactorService'
import PersonEditModal from './PersonEditModal'
import { IonButton, IonCol, IonRow, IonSearchbar, IonSelect, IonSelectOption, IonText } from '@ionic/react'
import { IonIcon, IonItem, IonItemOption } from '@ionic/react'
import { IonItemOptions, useIonModal } from '@ionic/react'
import { IonItemSliding, IonLabel, useIonToast } from '@ionic/react'
import { AxiosResponse } from 'axios'
import { t } from 'i18next'
import { trashBin } from 'ionicons/icons'
import React, { FC, useEffect, useState } from 'react'

interface OwnProps {}
interface StateProps {
  app?: App
  lang?: string
  refreshPersonList: boolean
}
interface DispatchProps {
  setRefreshPersonList: typeof setRefreshPersonList
}
interface PersonListProps extends OwnProps, StateProps, DispatchProps {}
const PersonList: FC<PersonListProps> = ({ app, refreshPersonList }) => {
  const [toast] = useIonToast()
  const [people, setPeople] = useState<Person[]>([])
  const [deleting, setDeleting] = useState(false)
  const [id, setId] = useState<string>()
  const [inprogress, setInprogress] = useState(false)
  const [page, setPage] = useState(0)
  const [size, setSize] = useState(10)
  const [present, dismiss] = useIonModal(PersonEditModal, {
    id,
    onDismiss: (data: string, role: string) => dismiss(data, role),
  })
  const [search, setSearch] = useState<string>()

  const fetchPeople = async () => {
    if (app?.publicId) {
      setInprogress(true)

      try {
        const res: AxiosResponse = await MFactorService.Instance.get(
          `/v1/person/${app?.publicId}?page=${page}&size=${size}${!!search ? `&search=${search}` : ''}`,
          {}
        )
        if (res.status === 200) setPeople(res.data)
      } catch (err: any) {
        failure(remoteErrorExtractor(err), toast)
      }
      setInprogress(false)
    }
  }

  const deletePerson = async (person: Person) => {
    if (app?.publicId) {
      setDeleting(true)
      try {
        const res: AxiosResponse = await MFactorService.Instance.delete(`/v1/person/${app?.publicId}/${person.id}`, {})
        if (res.status === 204) await fetchPeople()
      } catch (err) {
        failure(remoteErrorExtractor(err), toast)
      }
      setRefreshPersonList(!refreshPersonList)
      setDeleting(false)
    }
  }

  useEffect(() => {
    fetchPeople()
  }, [page, size, refreshPersonList, search, app?.publicId])

  useEffect(() => {
    setPage(0)
  }, [search, app?.publicId])

  return (
    <>
      <IonRow>
        <IonCol>
          <IonSearchbar
            debounce={500}
            placeholder={t('Search person')}
            onIonInput={(e: any) => setSearch(fixKafYe(e.detail.value))}
          ></IonSearchbar>
        </IonCol>
        <IonCol size='1'>
          <IonSelect
            value={size}
            onIonChange={(e: any) => setSize(e.detail.value)}
            okText={t('Select')}
            cancelText={t('Cancel')}
            interface='popover'
          >
            <IonSelectOption value={5}>5</IonSelectOption>
            <IonSelectOption value={10}>10</IonSelectOption>
            <IonSelectOption value={25}>25</IonSelectOption>
            <IonSelectOption value={50}>50</IonSelectOption>
          </IonSelect>
        </IonCol>
      </IonRow>
      {inprogress && <TkSpinner />}
      {people.map((p: Person) => (
        <IonItemSliding key={p.id}>
          <IonItem
            button={true}
            onClick={() => {
              setId(p.id)
              present({
                breakpoints: [0, 1],
                initialBreakpoint: 1,
              })
            }}
          >
            <IonText slot='start'>
              <PersonTypeIcon personType={p.personType as PersonType} />
            </IonText>
            <IonLabel title={p.id}>{p.name}</IonLabel>
          </IonItem>
          <IonItemOptions>
            <IonItemOption color={'danger'} onClick={() => deletePerson(p)} disabled={deleting}>
              <IonIcon slot='icon-only' icon={trashBin} />
            </IonItemOption>
          </IonItemOptions>
        </IonItemSliding>
      ))}
      <IonRow className='ion-align-items-center ion-padding-top'>
        <IonCol className='ion-text-center'>
          {page > 0 && (
            <IonButton onClick={() => setPage(page - 1)} disabled={inprogress} fill='clear'>
              {t<string>('Previous')}
            </IonButton>
          )}
        </IonCol>
        <IonCol className='ion-text-center'>{t<string>('Page X', { X: page + 1 })}</IonCol>
        <IonCol className='ion-text-center'>
          {people.length === size && (
            <IonButton onClick={() => setPage(page + 1)} disabled={inprogress} fill='clear'>
              {t<string>('Next')}
            </IonButton>
          )}
        </IonCol>
      </IonRow>
    </>
  )
}

export default connect<OwnProps, StateProps, DispatchProps>({
  mapStateToProps: (state) => ({
    app: state.user.app,
    refreshPersonList: state.factor.refreshPersonList,
  }),
  mapDispatchToProps: {
    setRefreshPersonList,
  },
  component: React.memo(PersonList),
})
