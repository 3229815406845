import { log } from '../../components/util/Log'
import { toFarsiText } from '../../components/util/MeasureUnitUtil'
import currencySign from '../../components/util/currencySign'
import { connect } from '../../data/connect'
import { addFactorItem, removeFactorItem, setFactorItemList } from '../../data/factor/factor.actions'
import { FactorItem } from '../../models/mfactor/FactorItem'
import './FactorItems.scss'
import { IonButton, IonButtons, IonIcon, IonItem, IonText } from '@ionic/react'
import { IonLabel, IonReorder, IonReorderGroup, ItemReorderEventDetail } from '@ionic/react'
import { trashBin } from 'ionicons/icons'
import { FC, memo } from 'react'

interface OwnProps {
  disabled?: boolean
}
interface StateProps {
  factorItemList: FactorItem[]
  lang?: string
}
interface DispatchProps {
  addFactorItem: typeof addFactorItem
  removeFactorItem: typeof removeFactorItem
  setFactorItemList: typeof setFactorItemList
}
interface FactorItemsProps extends OwnProps, StateProps, DispatchProps {}
const FactorItems: FC<FactorItemsProps> = ({
  factorItemList,
  lang,
  addFactorItem,
  removeFactorItem,
  setFactorItemList,
  disabled,
}) => {
  function handleReorder(evt: CustomEvent<ItemReorderEventDetail>) {
    log('Dragged from index', evt.detail.from, 'to', evt.detail.to)
    let movedItem = factorItemList.splice(evt.detail.from, 1)[0]
    factorItemList.splice(evt.detail.to, 0, movedItem)
    evt.detail.complete()
  }

  return (
    <IonReorderGroup disabled={false} onIonItemReorder={handleReorder} id='factor-items'>
      {factorItemList?.map((factorItem: FactorItem) => (
        <IonItem key={factorItem.id} button={true} disabled={disabled}>
          <IonReorder slot='start' className='ion-no-margin'></IonReorder>
          <input
            value={factorItem.qty}
            type='number'
            min={0}
            onInput={(e: any) => {
              const updatedList = factorItemList.map((item) =>
                item.id === factorItem.id || item.taxIrStuffId === factorItem.taxIrStuffId
                  ? { ...item, qty: +e.target.value }
                  : item
              )
              setFactorItemList(updatedList)
            }}
            style={{ width: '80px' }}
            className='ion-margin-start dir-ltr'
          />
          <IonLabel onClick={() => addFactorItem(factorItem)}>
            {lang === 'fa' && (factorItem.nameFa || factorItem.name)}
            {lang !== 'fa' && (factorItem.name || factorItem.nameFa)}
            <p className='dir-ltr ion-padding-start'>
              <span className='dir-rtl'>{factorItem.price?.toLocaleString(navigator.language)} </span>
              {!!factorItem.discount && (
                <IonText color={'danger'}>-{factorItem.discount.toLocaleString(navigator.language)}</IonText>
              )}{' '}
              {currencySign(factorItem.currency)}
              <IonText style={{ float: 'right' }}>{toFarsiText(factorItem.measurementUnit)}</IonText>
            </p>
          </IonLabel>
          <IonButtons slot='end' className='ion-no-margin'>
            <IonButton onClick={() => removeFactorItem(factorItem)} color='danger' disabled={disabled}>
              <IonIcon icon={trashBin}></IonIcon>
            </IonButton>
          </IonButtons>
        </IonItem>
      ))}
    </IonReorderGroup>
  )
}

export default connect<OwnProps, StateProps, DispatchProps>({
  mapStateToProps: (state) => ({
    factorItemList: state.factor.factorItemList,
    lang: state.user.lang,
  }),
  mapDispatchToProps: {
    addFactorItem,
    removeFactorItem,
    setFactorItemList,
  },
  component: memo(FactorItems),
})
