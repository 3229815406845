import PersonTypeIcon from '../../components/icon/PersonTypeIcon'
import PersonTypeSelect from '../../components/select/PersonTypeSelect'
import { log } from '../../components/util/Log'
import { generateRandomId } from '../../components/util/Random'
import { failure } from '../../components/util/Toast'
import { fixKafYe } from '../../components/util/Word'
import remoteErrorExtractor from '../../components/util/remoteErrorExtractor'
import { mainSlot, minorSlot } from '../../components/util/resolveSlot'
import { connect } from '../../data/connect'
import { setRefreshPersonList } from '../../data/factor/factor.actions'
import { App } from '../../models/App'
import Person from '../../models/mfactor/Person'
import { PersonType } from '../../models/mfactor/PersonType'
import { TaxPayerModel } from '../../models/mfactor/TaxPayerModel'
import { MFactorService } from '../../services/MFactorService'
import PersonAddField from './PersonAddField'
import { IonButton, IonButtons, IonCol, IonItem } from '@ionic/react'
import { IonReorder, IonReorderGroup } from '@ionic/react'
import { IonText, ItemReorderEventDetail } from '@ionic/react'
import { IonContent, IonFooter, IonPage } from '@ionic/react'
import { IonRow, useIonToast } from '@ionic/react'
import { IonHeader, IonIcon, IonInput, IonSpinner } from '@ionic/react'
import { IonTitle, IonToolbar } from '@ionic/react'
import { AxiosError, AxiosResponse } from 'axios'
import { t } from 'i18next'
import { add, cloudDownload, cloudDownloadOutline } from 'ionicons/icons'
import React, { FC, useEffect, useState } from 'react'

interface OwnProps {
  id?: string
  onDismiss: (data?: any, role?: string) => void
}
interface StateProps {
  hand: 'right' | 'left'
  app?: App
  refreshPersonList: boolean
}
interface DispatchProps {
  setRefreshPersonList: typeof setRefreshPersonList
}
interface PersonEditModalProps extends OwnProps, StateProps, DispatchProps {}
const PersonEditModal: FC<PersonEditModalProps> = ({
  id,
  onDismiss,
  hand,
  app,
  refreshPersonList,
  setRefreshPersonList,
}) => {
  const [person, setPerson] = useState<Person | null>({
    id,
    personType: 'REAL',
  } as Person)
  const [fields, setFields] = useState<any[]>([])
  const [inprogress, setInprogress] = useState(false)
  const [looking, setLooking] = useState(false)
  const [taxPayerModel, setTaxPayerModel] = useState<TaxPayerModel>()
  const [toast] = useIonToast()

  const findById = async () => {
    if (id) {
      try {
        const res: AxiosResponse = await MFactorService.Instance.get(`/v1/person/${app?.publicId}/${id}/get`, {})
        if (res.status === 200) {
          setPerson(res.data)
          if (res.data.detail) setFields(JSON.parse(res.data.detail)?.fields || [])
        }
      } catch (err) {
        failure(remoteErrorExtractor(err), toast)
      }
    }
  }

  const store = async () => {
    setInprogress(true)
    if (app?.publicId) {
      fields.map((f: any, idx: number) => {
        // Re-index
        f.r = idx
        return f
      })
      if (id) {
        try {
          const res: AxiosResponse = await MFactorService.Instance.put(`/v1/person/${app?.publicId}/${id}`, {
            id: id,
            personType: person?.personType,
            name: fixKafYe(person?.name),
            nationalId: person?.nationalId,
            economicCode: person?.economicCode,
            postalCode: person?.postalCode,
            passportNumber: person?.passportNumber,
            detail: JSON.stringify({
              ...JSON.parse(person?.detail!),
              fields,
            }),
          })
        } catch (err) {
          failure(remoteErrorExtractor(err), toast)
        }
      } else {
        try {
          const res: AxiosResponse = await MFactorService.Instance.post(`/v1/person/${app?.publicId}`, {
            id: generateRandomId(),
            appPublicId: app.publicId,
            personType: person?.personType,
            name: fixKafYe(person?.name),
            nationalId: person?.nationalId,
            economicCode: person?.economicCode,
            postalCode: person?.postalCode,
            passportNumber: person?.passportNumber,
            detail: JSON.stringify({ fields }),
          })
        } catch (err) {
          failure(remoteErrorExtractor(err), toast)
        }
      }

      setRefreshPersonList(!refreshPersonList)
      setPerson(null)
      onDismiss(person, 'edited')
    } else {
      failure('Please select your app first', toast)
    }
    setInprogress(false)
  }

  const getTaxPayer = async () => {
    setLooking(true)
    try {
      const res: AxiosResponse = await MFactorService.Instance.post(
        `/v1/tax-profile/${app?.publicId}/lookup/${person?.economicCode}`,
        {}
      )
      if (res.status === 200 || res.status === 201) {
        const model: TaxPayerModel = res.data
        setPerson({
          ...person,
          name: model.nameTrade || person?.name,
          nationalId: model.nationalId || person?.nationalId,
          postalCode: model.postalcodeTaxpayer || person?.postalCode,
        } as Person)
        setTaxPayerModel({
          ...model,
          taxpayerStatus: model.taxpayerStatus || 'NOT_FOUND',
        })
      }
    } catch (err: AxiosError | any) {
      failure(remoteErrorExtractor(err), toast)
    }
    setLooking(false)
  }

  const addToDetail = (key: string, value: string) => {
    setFields((fields) => [...fields, { r: fields.length, k: key, v: value }])
  }

  const setDetailKeyValue = (key: string, value: string) => {
    const elem = fields.find((f: any) => f.k === key)
    elem.v = value
    fields.map((x: any) => (x.k === key ? { ...elem } : x))
    setFields(fields)
  }

  const handleReorder = (evt: CustomEvent<ItemReorderEventDetail>) => {
    const movedItem = fields.splice(evt.detail.from, 1)[0]
    fields.splice(evt.detail.to, 0, movedItem)
    setFields(fields)
    evt.detail.complete()
  }

  useEffect(() => {
    findById()
  }, [])

  return (
    <IonPage>
      <IonHeader>
        <IonToolbar>
          <IonButtons slot={minorSlot(hand, document.documentElement.dir)}>
            <IonButton onClick={() => onDismiss(null, 'cancel')}>{t<string>('Cancel')}</IonButton>
          </IonButtons>
          <IonTitle>{t<string>(id ? 'Edit person' : 'New person')}</IonTitle>
          <IonButtons slot={mainSlot(hand, document.documentElement.dir)}>
            {!!taxPayerModel?.taxpayerStatus && (
              <IonText color={taxPayerModel?.taxpayerStatus === 'ACTIVE' ? 'success' : ''}>
                {taxPayerModel?.taxpayerStatus}
              </IonText>
            )}
          </IonButtons>
        </IonToolbar>
      </IonHeader>
      <IonContent>
        <IonRow className='ion-padding-horizontal'>
          <IonCol>
            <IonInput
              disabled={inprogress}
              value={person?.economicCode}
              onIonInput={(e) => setPerson({ ...person, economicCode: e.detail.value } as Person)}
              label={t('Economic code')}
              placeholder={t('Economic code')}
            ></IonInput>
          </IonCol>
          {(person?.economicCode?.length || 0) >= 10 && (
            <IonCol size='2'>
              <IonButton
                onClick={getTaxPayer}
                fill='clear'
                disabled={looking}
                color={taxPayerModel?.taxpayerStatus === 'ACTIVE' ? 'success' : 'dark'}
              >
                <IonIcon icon={cloudDownloadOutline} slot='icon-only' />
              </IonButton>
            </IonCol>
          )}
        </IonRow>
        <IonRow className='ion-padding-horizontal ion-align-items-center'>
          <IonCol size='1'>
            <PersonTypeIcon personType={person?.personType as PersonType} />
          </IonCol>
          <IonCol>
            <PersonTypeSelect
              defaultValue={person?.personType}
              onChange={(value: string) => setPerson({ ...person, personType: value } as Person)}
            />
          </IonCol>
        </IonRow>
        <IonRow className='ion-padding-horizontal'>
          <IonCol>
            <IonInput
              disabled={inprogress}
              value={person?.name}
              onIonInput={(e) => setPerson({ ...person, name: e.detail.value } as Person)}
              label={t('Name in', { personType: t(person?.personType ?? '') } as Person)}
              placeholder={t('Name')}
              required={true}
            ></IonInput>
          </IonCol>
        </IonRow>
        <IonRow className='ion-padding-horizontal'>
          <IonCol>
            <IonInput
              disabled={inprogress}
              value={person?.nationalId}
              onIonInput={(e) => setPerson({ ...person, nationalId: e.detail.value } as Person)}
              label={t('National Id')}
              placeholder={t('National Id')}
            ></IonInput>
          </IonCol>
        </IonRow>
        <IonRow className='ion-padding-horizontal'>
          <IonCol>
            <IonInput
              disabled={inprogress}
              value={person?.postalCode}
              onIonInput={(e) => setPerson({ ...person, postalCode: e.detail.value } as Person)}
              label={t('Postal code')}
              placeholder={t('Postal code')}
            ></IonInput>
          </IonCol>
        </IonRow>
        <IonRow className='ion-padding-horizontal'>
          <IonCol>
            <IonInput
              disabled={inprogress}
              value={person?.passportNumber}
              onIonInput={(e) => setPerson({ ...person, passportNumber: e.detail.value } as Person)}
              label={t('Passport number')}
              placeholder={t('Passport number')}
            ></IonInput>
          </IonCol>
        </IonRow>
        <IonReorderGroup disabled={false} onIonItemReorder={handleReorder}>
          {fields.map((f: any, idx: number) => (
            <IonItem key={`${idx}_${f.k}_${f.v}`} className='ion-padding-horizontal'>
              <IonReorder slot='start' />
              <IonInput
                value={f.v}
                onIonInput={(e: any) => setDetailKeyValue(f.k, e.detail.value)}
                placeholder={f.k}
                label={f.k}
              />
              <IonText>{f.r}</IonText>
            </IonItem>
          ))}
        </IonReorderGroup>
        <div className='ion-padding-horizontal'>
          <PersonAddField onAdd={(key: string, value: string) => addToDetail(key, value)} />
        </div>
        {!!taxPayerModel && (
          <>
            <IonRow className='ion-padding-horizontal'>
              <IonCol size='4'>{t<string>('Tax payer status')}</IonCol>
              <IonCol>
                <IonText
                  color={
                    taxPayerModel?.taxpayerStatus === 'INVALID'
                      ? 'danger'
                      : taxPayerModel?.taxpayerStatus === 'ACTIVE'
                      ? 'success'
                      : ''
                  }
                >
                  {taxPayerModel?.taxpayerStatus}
                </IonText>
              </IonCol>
            </IonRow>
          </>
        )}
      </IonContent>
      <IonFooter>
        <IonToolbar>
          <IonButtons slot={mainSlot(hand, document.documentElement.dir)}>
            <IonButton onClick={() => store()} disabled={inprogress} color='primary' fill='outline'>
              {!inprogress && <IonIcon icon={add} slot='start' />}
              {inprogress && <IonSpinner slot='start' />}
              {t<string>('Save')}
            </IonButton>
          </IonButtons>
        </IonToolbar>
      </IonFooter>
    </IonPage>
  )
}

export default connect<OwnProps, StateProps, DispatchProps>({
  mapStateToProps: (state) => ({
    hand: state.user.hand,
    app: state.user.app,
    refreshPersonList: state.factor.refreshPersonList,
  }),
  mapDispatchToProps: {
    setRefreshPersonList,
  },
  component: React.memo(PersonEditModal),
})
