import TaxIdText from '../../components/text/TaxIdText'
import { dateTimeDisplay } from '../../components/util/Calendar'
import TkSpinner from '../../components/util/TkSpinner'
import { failure, info } from '../../components/util/Toast'
import { fixKafYe } from '../../components/util/Word'
import currencySign from '../../components/util/currencySign'
import remoteErrorExtractor from '../../components/util/remoteErrorExtractor'
import { connect } from '../../data/connect'
import { setFactorItemList, setTaxFactor } from '../../data/factor/factor.actions'
import { App } from '../../models/App'
import { FactorItem } from '../../models/mfactor/FactorItem'
import { RawFactorItem } from '../../models/mfactor/RawFactorItem'
import { TaxRecord } from '../../models/mfactor/TaxRecord'
import { FactorDTO } from '../../models/mfactor/dto/FactorDTO'
import { TaxRecordGroupDTO } from '../../models/mfactor/dto/TaxRecordGroupDTO'
import { GtinDbService } from '../../services/GtinDbService'
import { MFactorService } from '../../services/MFactorService'
import DeskInqueryButton from '../desk/DeskInqueryButton'
import { IonAccordion, IonAccordionGroup, IonButton, IonButtons, IonSelect, IonSelectOption } from '@ionic/react'
import { IonCheckbox, IonCol, IonRow } from '@ionic/react'
import { IonChip, IonList, IonSearchbar, IonText } from '@ionic/react'
import { IonIcon, IonItem, IonItemOption } from '@ionic/react'
import { IonItemOptions } from '@ionic/react'
import { IonItemSliding, IonLabel, useIonToast } from '@ionic/react'
import { AxiosResponse } from 'axios'
import { t } from 'i18next'
import { add, checkmark, eye, leaf, pencil, remove, squareOutline, trashBin } from 'ionicons/icons'
import React, { FC, useEffect, useState } from 'react'
import CopyToClipboard from 'react-copy-to-clipboard'
import { useHistory } from 'react-router-dom'

interface OwnProps {}
interface StateProps {
  app?: App
  lang?: string
  refreshTaxRecordList: boolean
}
interface DispatchProps {
  setTaxFactor: typeof setTaxFactor
  setFactorItemList: typeof setFactorItemList
}
interface TaxRecordListProps extends OwnProps, StateProps, DispatchProps {}
const TaxRecordList: FC<TaxRecordListProps> = ({ app, setTaxFactor, setFactorItemList, refreshTaxRecordList }) => {
  const [toast] = useIonToast()
  const [items, setItems] = useState<TaxRecordGroupDTO[]>([])
  const [deleting, setDeleting] = useState(false)
  const [inprogress, setInprogress] = useState(false)
  const [search, setSearch] = useState<string>()
  const [showDeleted, setShowDeleted] = useState(false)
  const [page, setPage] = useState(0)
  const [size, setSize] = useState(10)
  const [switchExpand, setSwitchExpand] = useState(false)
  const history = useHistory()

  const fetchItems = async () => {
    if (app?.publicId) {
      setInprogress(true)
      try {
        const res: AxiosResponse = await MFactorService.Instance.get(
          `/v1/tax-record/${app?.publicId}?size=${size}&page=${page}&deleted=${showDeleted}${
            !!search ? `&search=${search}` : ''
          }`
        )
        if (res.status === 200) setItems(res.data)
      } catch (err: any) {
        failure(remoteErrorExtractor(err), toast)
      }
      setInprogress(false)
    }
  }

  const deleteTaxRecord = async (itm: TaxRecord, factorPublicId: string) => {
    if (app?.publicId) {
      setDeleting(true)
      try {
        const res: AxiosResponse = await MFactorService.Instance.delete(
          `/v1/tax-record/${app?.publicId}/${factorPublicId}/${itm.id}`,
          {}
        )
        if (res.status === 200) await fetchItems()
      } catch (err) {
        failure(remoteErrorExtractor(err), toast)
      }
      setDeleting(false)
    }
  }

  const restoreTaxRecord = async (itm: TaxRecord, factorPublicId: string) => {
    if (app?.publicId) {
      setDeleting(true)
      try {
        const res: AxiosResponse = await MFactorService.Instance.put(
          `/v1/tax-record/${app?.publicId}/${factorPublicId}/${itm.id}/restore`,
          {}
        )
        if (res.status === 200) await fetchItems()
      } catch (err) {
        failure(remoteErrorExtractor(err), toast)
      }
      setDeleting(false)
    }
  }

  const convertToFactorItemList = async (factor: FactorDTO) => {
    const res: any[] = []
    const promises: Promise<FactorItem>[] = []
    JSON.parse(factor.detail!)?.items?.forEach((rfi: RawFactorItem) =>
      promises.push(GtinDbService.Instance.lookupFactorItem(rfi, factor.currency!, factor.appPublicId, 'fa'))
    )
    return res.concat.apply([], await Promise.all(promises))
  }

  const hasSuccess = (group: TaxRecordGroupDTO) => {
    let success = false
    for (const item of group?.records!) {
      if (item.status === 'SUCCESS') success = true
    }
    return success
  }

  const hasCorrected = (group: TaxRecordGroupDTO, record: TaxRecord) => {
    let corrected = false
    for (const item of group?.records!) {
      if (item.subject === 'CORRECTION' && statusForReal(group, item) === 'SUCCESS') corrected = true
    }
    return corrected
  }

  const hasNotInvalidated = (group: TaxRecordGroupDTO, record: TaxRecord) => {
    let invalidated = true
    for (const item of group?.records!) {
      if (item.subject === 'CORRECTION' && statusForReal(group, item) && statusForReal(group, item) !== 'INVALIDATED')
        invalidated = false
    }
    return invalidated
  }

  const statusForReal = (group: TaxRecordGroupDTO, record: TaxRecord) => {
    let invalidated = false
    let corrected = false
    for (const item of group?.records!) {
      if (item.subject === 'INVALID' && item.status && item.status === 'SUCCESS' && item.mainId === record?.id)
        invalidated = true
      if (item.subject === 'CORRECTION' && item.status && item.status === 'SUCCESS' && item.mainId === record?.id)
        corrected = true
    }
    return invalidated ? 'INVALIDATED' : corrected ? 'CORRECTED' : record?.status
  }

  const showCorrection = (group: TaxRecordGroupDTO, record: TaxRecord) => {
    const s = statusForReal(group, record)
    const sub = record?.subject
    return s && s !== 'INVALIDATED' && sub === 'MAIN' && !hasCorrected(group, record)
  }

  const showInvalidation = (group: TaxRecordGroupDTO, record: TaxRecord) => {
    const s = statusForReal(group, record)
    const sub = record?.subject
    return s && ((sub === 'MAIN' && hasNotInvalidated(group, record)) || sub === 'CORRECTION') && s !== 'INVALIDATED'
  }

  const renderItems = (group: TaxRecordGroupDTO, record: TaxRecord) => {
    return (
      <IonItemSliding key={record.id}>
        <IonItem lines='none'>
          <IonLabel>
            <p>
              <CopyToClipboard
                text={`${record.serial ?? ''}`}
                onCopy={() => info('Copied X', toast, { X: record.serial })}
              >
                <IonText color={record.deleted ? 'danger' : ''}>{record.serial ?? ''}</IonText>
              </CopyToClipboard>

              <IonChip>{t<string>(`Subject ${record.invoice?.header?.invoiceSubject}`)}</IonChip>
              {record.subject !== 'CORRECTION' ? (
                <IonText className='ion-padding-start'>{record.mainSerial}</IonText>
              ) : (
                ''
              )}
              {record?.taxId && <TaxIdText taxId={record?.taxId} />}
            </p>
          </IonLabel>
          <IonButtons slot='end'>
            {showInvalidation(group, record) && (
              <IonButton
                fill='clear'
                onClick={async () => {
                  setFactorItemList(await convertToFactorItemList(record.factor!))
                  setTaxFactor({
                    mainId: record.id,
                    factor: record.factor,
                    taxRecord: {
                      ...record,
                      id: undefined,
                      taxId: undefined,
                      status: undefined,
                      serial: undefined,
                      responseData: undefined,
                      invoice: {
                        ...record?.invoice,
                        header: {
                          ...record?.invoice?.header,
                          invoiceSubject: 'INVALID',
                        },
                      },
                    },
                  })
                  history.push('/desk')
                }}
                color={'danger'}
              >
                <IonIcon icon={trashBin} slot='start' />
                {t<string>('Do invalid')}
              </IonButton>
            )}
            {showCorrection(group, record) && (
              <IonButton
                fill='clear'
                onClick={async () => {
                  setFactorItemList(await convertToFactorItemList(record.factor!))
                  setTaxFactor({
                    mainId: record.id,
                    factor: record.factor,
                    taxRecord: {
                      ...record,
                      id: undefined,
                      taxId: undefined,
                      status: undefined,
                      serial: undefined,
                      responseData: undefined,
                      invoice: {
                        ...record?.invoice,
                        header: {
                          ...record?.invoice?.header,
                          invoiceSubject: 'CORRECTION',
                        },
                      },
                    },
                  })
                  history.push('/desk')
                }}
              >
                <IonIcon icon={pencil} slot='start' />
                {t<string>('Do correction')}
              </IonButton>
            )}
            <DeskInqueryButton
              chip={true}
              record={record}
              group={group}
              onStateChange={() => {
                fetchItems()
              }}
            />
            <IonButton
              fill='clear'
              onClick={async () => {
                setFactorItemList(await convertToFactorItemList(record.factor ?? group.factor))
                setTaxFactor({
                  factor: record?.factor ?? group.factor,
                  taxRecord: record,
                })
                history.push('/desk')
              }}
            >
              <IonIcon icon={record?.status === 'SUCCESS' ? eye : pencil} slot='icon-only' />
            </IonButton>
          </IonButtons>
        </IonItem>
        <IonItemOptions>
          {!record.deleted && (
            <IonItemOption
              color={'danger'}
              onClick={() => deleteTaxRecord(record, group.factor.id!)}
              disabled={deleting}
            >
              <IonIcon slot='icon-only' icon={trashBin} />
            </IonItemOption>
          )}
          {record.deleted && (
            <IonItemOption
              onClick={() => restoreTaxRecord(record, group.factor.id!)}
              disabled={deleting}
              color={'success'}
            >
              <IonIcon slot='icon-only' icon={leaf} />
            </IonItemOption>
          )}
        </IonItemOptions>
      </IonItemSliding>
    )
  }

  useEffect(() => {
    fetchItems()
  }, [search, app?.publicId, refreshTaxRecordList, showDeleted, size, page])

  useEffect(() => {
    setPage(0)
  }, [search, app?.publicId])

  return (
    <>
      <IonRow className='ion-align-items-center ion-no-padding'>
        <IonCol className='ion-no-padding'>
          <IonSearchbar
            debounce={500}
            placeholder={t('Search tax record')}
            onIonInput={(e: any) => setSearch(fixKafYe(e.detail.value))}
          ></IonSearchbar>
        </IonCol>
        <IonCol size='1'>
          <IonSelect
            value={size}
            onIonChange={(e: any) => setSize(e.detail.value)}
            okText={t('Select')}
            cancelText={t('Cancel')}
            interface='popover'
          >
            <IonSelectOption value={5}>5</IonSelectOption>
            <IonSelectOption value={10}>10</IonSelectOption>
            <IonSelectOption value={25}>25</IonSelectOption>
            <IonSelectOption value={50}>50</IonSelectOption>
          </IonSelect>
        </IonCol>
        <IonCol size='2' className='ion-no-padding'>
          <IonCheckbox checked={showDeleted} onIonChange={(e: any) => setShowDeleted(e.detail.checked)}>
            <IonLabel>{t<string>('Show deleted')}</IonLabel>
          </IonCheckbox>
        </IonCol>
        <IonCol size='1'>
          <IonButton fill='clear' onClick={() => setSwitchExpand(!switchExpand)} color='dark'>
            <IonIcon icon={switchExpand ? remove : add} slot='icon-only' />
          </IonButton>
        </IonCol>
      </IonRow>
      {inprogress && <TkSpinner />}
      <IonList>
        {items.map((item: TaxRecordGroupDTO) => (
          <IonAccordionGroup key={item.factor.id} value={item.factor.id} expand='inset'>
            <IonAccordion value={switchExpand ? item.factor.id : ''}>
              <IonItem slot='header' key={item.factor.id} button={true}>
                <IonIcon icon={hasSuccess(item) ? checkmark : squareOutline} slot='start' />
                <IonText slot={'start'}>{item.factor.payerName}</IonText>
                <IonLabel className='dir-ltr'>
                  {currencySign(item.factor.currency)} {item.factor.total?.toLocaleString(navigator.language)}
                  <p>
                    <span
                      title={item.factor.factorDate ?? ''}
                      style={{ display: 'inline-block' }}
                      className={'dir-rtl'}
                    >
                      {dateTimeDisplay(item.factor.factorDate!, 'fa')}
                    </span>
                  </p>
                </IonLabel>
              </IonItem>
              <div slot='content'>
                <IonList>{item.records.map((record: TaxRecord) => renderItems(item, record))}</IonList>
              </div>
            </IonAccordion>
          </IonAccordionGroup>
        ))}
      </IonList>
      <IonRow className='ion-align-items-center'>
        <IonCol className='ion-text-center'>
          {page > 0 && (
            <IonButton onClick={() => setPage(page - 1)} disabled={inprogress} fill='clear'>
              {t<string>('Previous')}
            </IonButton>
          )}
        </IonCol>
        <IonCol className='ion-text-center'>{t<string>('Page X', { X: page + 1 })}</IonCol>
        <IonCol className='ion-text-center'>
          {items.map((i) => i.records.length).reduce((acc, curr) => acc + curr, 0) === size && (
            <IonButton onClick={() => setPage(page + 1)} disabled={inprogress} fill='clear'>
              {t<string>('Next')}
            </IonButton>
          )}
        </IonCol>
      </IonRow>
    </>
  )
}

export default connect<OwnProps, StateProps, DispatchProps>({
  mapStateToProps: (state) => ({
    app: state.user.app,
    refreshTaxRecordList: state.factor.refreshTaxRecordList,
  }),
  mapDispatchToProps: {
    setTaxFactor,
    setFactorItemList,
  },
  component: React.memo(TaxRecordList),
})
