import CopyRightAndVersion from '../../components/CopyRightAndVersion'
import TaxIdText from '../../components/text/TaxIdText'
import { connect } from '../../data/connect'
import { TaxFactor } from '../../models/mfactor/TaxFactor'
import PageContainer from '../../pages/PageContainer'
import FactorAddButton from '../factor/FactorAddButton'
import DeskForm from './DeskForm'
import DeskInqueryButton from './DeskInqueryButton'
import DeskMenuFab from './DeskMenuFab'
import DeskSaveFab from './DeskSaveButton'
import { isPlatform } from '@ionic/core'
import { IonCol, IonItem, IonLabel, IonRow, IonTextarea, useIonToast } from '@ionic/react'
import React, { FC, lazy } from 'react'

const DeskScannerFab = lazy(() => import('./DeskScannerFab'))

interface OwnProps {}
interface StateProps {
  showScanner: boolean
  taxFactor?: TaxFactor
  factorSaving: boolean
}
interface DispatchProps {}
interface DeskProp extends OwnProps, StateProps, DispatchProps {}
const Desk: FC<DeskProp> = ({ showScanner, taxFactor, factorSaving }) => {
  const [toast] = useIonToast()
  return (
    <PageContainer
      isPrivate={true}
      id='mfactor-desk'
      actionButtonsStart={<FactorAddButton />}
      footer={<CopyRightAndVersion />}
      padding={!isPlatform('mobile')}
      scrollEvents={true}
      titleWidget={<TaxIdText taxId={taxFactor?.taxRecord?.taxId} />}
    >
      {showScanner && <DeskScannerFab />}
      <DeskMenuFab />
      <DeskForm />
      {taxFactor?.taxRecord?.responseData && (
        <IonItem lines='none'>
          <IonLabel></IonLabel>
          <IonTextarea
            value={JSON.stringify(
              (({ status, data }) => ({ status, data }))(JSON.parse(taxFactor?.taxRecord.responseData)),
              null,
              2
            )}
            readonly={true}
            rows={7}
            className='dir-ltr'
          ></IonTextarea>
        </IonItem>
      )}
      <IonRow>
        <IonCol size='12' sizeMd='4'>
          {taxFactor?.taxRecord?.id && <DeskSaveFab saveAndSend={true} />}
        </IonCol>
        <IonCol size='12' sizeMd='4'>
          <DeskSaveFab />
        </IonCol>
        <IonCol size='12' sizeMd='4'>
          {taxFactor?.taxRecord?.id && !factorSaving && <DeskInqueryButton />}
        </IonCol>
      </IonRow>
    </PageContainer>
  )
}

export default connect<{}, StateProps, {}>({
  mapStateToProps: (state) => ({
    showScanner: state.factor.showScanner,
    taxFactor: state.factor.taxFactor,
    factorSaving: state.factor.factorSaving,
  }),
  mapDispatchToProps: {},
  component: React.memo(Desk),
})
