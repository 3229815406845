import { connect } from '../../data/connect'
import { setScanBeep, setShowScanner } from '../../data/factor/factor.actions'
import { setDarkMode, setHand, setLang } from '../../data/user/user.actions'
import ProductListModal from '../../pages-gtin/ProductListModal'
import PersonListModal from '../../pages-mfactor/person/PersonListModal'
import SettingsModal from '../settings/SettingsModal'
import { IonFab, IonFabButton, IonFabList, IonIcon } from '@ionic/react'
import { barcodeOutline, barcodeSharp, chevronDown, handLeft } from 'ionicons/icons'
import { handRight, volumeHigh, volumeMute } from 'ionicons/icons'
import React, { FC } from 'react'

interface StateProps {
  isAuthenticated: boolean
  darkMode: boolean
  lang?: string
  hand: string
  scanBeep: boolean
  showScanner: boolean
}
interface DispatchProps {
  setDarkMode: typeof setDarkMode
  setLang: typeof setLang
  setHand: typeof setHand
  setScanBeep: typeof setScanBeep
  setShowScanner: typeof setShowScanner
}
interface DeskMenuFabProps extends StateProps, DispatchProps {}
const DeskMenuFab: FC<DeskMenuFabProps> = ({
  isAuthenticated,
  darkMode,
  setDarkMode,
  lang,
  setLang,
  hand,
  setHand,
  scanBeep,
  setScanBeep,
  showScanner,
  setShowScanner,
}) => {
  return (
    <>
      <IonFab slot='fixed' vertical='top' horizontal='end' edge={true}>
        <IonFabButton color={'light'}>
          <IonIcon icon={chevronDown}></IonIcon>
        </IonFabButton>
        <IonFabList side='bottom'>
          {!scanBeep && (
            <IonFabButton onClick={() => setScanBeep(true)} title='Scanner beep is mute'>
              <IonIcon icon={volumeMute} />
            </IonFabButton>
          )}
          {scanBeep && (
            <IonFabButton onClick={() => setScanBeep(false)} color='dark' title='Scanner beep is on'>
              <IonIcon icon={volumeHigh} />
            </IonFabButton>
          )}
          {!showScanner && (
            <IonFabButton onClick={() => setShowScanner(true)} title='Scanner button is hidden'>
              <IonIcon icon={barcodeSharp} />
            </IonFabButton>
          )}
          {showScanner && (
            <IonFabButton onClick={() => setShowScanner(false)} color='dark' title='Scanner button is visible'>
              <IonIcon icon={barcodeOutline} />
            </IonFabButton>
          )}
          {hand === 'right' && (
            <IonFabButton
              onClick={(evt: any) => {
                evt.preventDefault()
                evt.stopPropagation()
                setHand('left')
              }}
            >
              <IonIcon icon={handRight} title='Right hand selected' />
            </IonFabButton>
          )}
          {hand === 'left' && (
            <IonFabButton
              onClick={(evt: any) => {
                evt.preventDefault()
                evt.stopPropagation()
                setHand('right')
              }}
            >
              <IonIcon icon={handLeft} title='Left hand selected' />
            </IonFabButton>
          )}
        </IonFabList>
      </IonFab>
      <ProductListModal />
      <PersonListModal />
      <SettingsModal />
    </>
  )
}

export default connect<{}, StateProps, DispatchProps>({
  mapStateToProps: (state) => ({
    isAuthenticated: state.user.isAuthenticated,
    darkMode: state.user.darkMode,
    lang: state.user.lang,
    hand: state.user.hand,
    scanBeep: state.factor.scanBeep,
    showScanner: state.factor.showScanner,
  }),
  mapDispatchToProps: {
    setDarkMode,
    setLang,
    setHand,
    setScanBeep,
    setShowScanner,
  },
  component: React.memo(DeskMenuFab),
})
