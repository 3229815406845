import { failure } from '../../components/util/Toast'
import remoteErrorExtractor from '../../components/util/remoteErrorExtractor'
import { connect } from '../../data/connect'
import { setRefreshTaxRecordList, setTaxFactor } from '../../data/factor/factor.actions'
import { App } from '../../models/App'
import { TaxFactor } from '../../models/mfactor/TaxFactor'
import { TaxRecord } from '../../models/mfactor/TaxRecord'
import { TaxRecordGroupDTO } from '../../models/mfactor/dto/TaxRecordGroupDTO'
import { MFactorService } from '../../services/MFactorService'
import { IonButton, IonChip, IonIcon, IonLabel, IonSpinner, useIonToast } from '@ionic/react'
import { AxiosResponse } from 'axios'
import { t } from 'i18next'
import { codeDownload } from 'ionicons/icons'
import { FC, memo, useEffect, useState } from 'react'

interface OwnProps {
  chip?: boolean
  iconOnly?: boolean
  record?: TaxRecord
  group?: TaxRecordGroupDTO
  onStateChange?: () => void
}
interface StateProps {
  app?: App
  taxFactor?: TaxFactor
  refreshTaxRecordList: boolean
}
interface DispatchProps {
  setTaxFactor: typeof setTaxFactor
  setRefreshTaxRecordList: typeof setRefreshTaxRecordList
}
interface DeskInqueryButtonProps extends OwnProps, StateProps, DispatchProps {}
const DeskInqueryButton: FC<DeskInqueryButtonProps> = ({
  app,
  taxFactor,
  setTaxFactor,
  iconOnly,
  chip,
  record,
  group,
  onStateChange,
  refreshTaxRecordList,
  setRefreshTaxRecordList,
}) => {
  const [toast] = useIonToast()
  const [updatedRecord, setUpdatedRecord] = useState(record)
  const [inprogress, setInprogress] = useState(false)

  const inqueryTaxRecord = async () => {
    if (!record && taxFactor?.taxRecord?.status === 'SUCCESS') return
    try {
      setInprogress(true)
      const factorId = record?.factor?.id ?? taxFactor?.factor?.id
      const recordId = record?.id ?? taxFactor?.taxRecord?.id
      const res: AxiosResponse = await MFactorService.Instance.get(
        `/v1/tax-record/${app?.publicId}/${factorId}/inquiry/${recordId}`
      )
      if (res.status === 200) {
        if (!record) {
          setTaxFactor({
            ...taxFactor,
            taxRecord: {
              ...taxFactor?.taxRecord,
              ...res.data,
            },
          })
          setRefreshTaxRecordList(!refreshTaxRecordList)
        } else {
          setUpdatedRecord(res.data)
        }
        if (onStateChange && res?.data?.status !== 'IN_PROGRESS') onStateChange()
      }
    } catch (err) {
      failure(remoteErrorExtractor(err), toast)
    } finally {
      setInprogress(false)
    }
  }

  const statusForReal = () => {
    let hasInvalidated = false
    let hasCorrected = false
    if (!!group) {
      for (const item of group.records!) {
        if (item.subject === 'INVALID' && item.status === 'SUCCESS' && item.mainId === record?.id) hasInvalidated = true
        if (item.subject === 'CORRECTION' && item.status === 'SUCCESS' && item.mainId === record?.id)
          hasCorrected = true
      }
    }
    return hasInvalidated ? 'INVALIDATED' : hasCorrected ? 'CORRECTED' : record?.status
  }

  useEffect(() => {
    if (app?.publicId && !record) inqueryTaxRecord()
  }, [app?.publicId])

  return (
    <>
      {chip && (
        <>
          {record?.status !== 'IN_PROGRESS' && (
            <IonChip
              color={statusForReal() === 'SUCCESS' ? 'success' : statusForReal() === 'FAILED' ? 'danger' : 'dark'}
            >
              {t<string>(`Status ${statusForReal()}`)}
            </IonChip>
          )}
          {record?.status === 'IN_PROGRESS' && (
            <IonChip onClick={inqueryTaxRecord}>
              {!inprogress && <IonIcon icon={codeDownload} />}
              {inprogress && <IonSpinner name='bubbles' />}
              <IonLabel>{t<string>(`Status ${record?.status}`)}</IonLabel>
            </IonChip>
          )}
        </>
      )}
      {!chip && (
        <IonButton
          disabled={inprogress || (!updatedRecord && taxFactor?.taxRecord?.status === 'SUCCESS')}
          onClick={inqueryTaxRecord}
          expand='block'
          color={'light'}
        >
          {!inprogress && <IonIcon slot={iconOnly ? 'icon-only' : 'start'} icon={codeDownload} color='dark' />}
          {inprogress && (
            <IonSpinner slot={iconOnly ? 'icon-only' : 'start'} name='bubbles' color={iconOnly ? 'dark' : 'medium'} />
          )}
          {!iconOnly && (
            <>{updatedRecord?.status ? t<string>(`Status ${updatedRecord.status}`) : t<string>('Inquery')}</>
          )}
        </IonButton>
      )}
    </>
  )
}

export default connect<OwnProps, StateProps, DispatchProps>({
  mapStateToProps: (state) => ({
    app: state.user.app,
    taxFactor: state.factor.taxFactor,
    factorDate: state.factor.factorDate,
    factorPayer: state.factor.factorPayer,
    factorSaving: state.factor.factorSaving,
    factorItemList: state.factor.factorItemList,
    refreshFactorList: state.factor.refreshFactorList,
    refreshTaxRecordList: state.factor.refreshTaxRecordList,
  }),
  mapDispatchToProps: {
    setTaxFactor,
    setRefreshTaxRecordList,
  },
  component: memo(DeskInqueryButton),
})
