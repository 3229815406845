const map: any = {
  ﻙ: 'ک',
  ك: 'ک', // Note this line is different from previous line!
  ي: 'ی',
  ی: 'ی',
  ى: 'ی', // Note this line is different from previous line!
}

const fixKafYe = (text?: string) => {
  if (text) {
    return text.replace(/[كﻙيیى]/g, function (match: any) {
      return map[match]
    })
  }
  return text
}

function farsiToEnglish(input: string): string {
  const farsiToEnglishMap: { [key: string]: string } = {
    آ: 'a',
    ا: 'a',
    ب: 'b',
    پ: 'p',
    ت: 't',
    ث: 's',
    ج: 'j',
    چ: 'ch',
    ح: 'h',
    خ: 'kh',
    د: 'd',
    ذ: 'z',
    ر: 'r',
    ز: 'z',
    ژ: 'zh',
    س: 's',
    ش: 'sh',
    ص: 's',
    ض: 'z',
    ط: 't',
    ظ: 'z',
    ع: 'a',
    غ: 'gh',
    ف: 'f',
    ق: 'gh',
    ک: 'k',
    گ: 'g',
    ل: 'l',
    م: 'm',
    ن: 'n',
    و: 'v',
    ه: 'h',
    ی: 'y',
    ئ: 'e',
    أ: 'a',
    ؤ: 'u',
  }

  return input
    .split('')
    .map((char) => farsiToEnglishMap[char] || char)
    .join('')
}

export { fixKafYe, farsiToEnglish }
