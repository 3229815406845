import PageContainer from '../PageContainer'
import React from 'react'

const Privacy = () => {
  return (
    <PageContainer id='privacy-page' isPrivate={false} dir='ltr' title={'Privacy'}>
      <h3 className='text-bold'>Privacy Policy</h3>
      <br />
      <span>Last modified: July 31, 2022</span>
      <br />
      <br />
      <p>
        There are different ways you can use our services - to search for and share information, to communicate with
        other people or to create new content. When you share information with us, for example creating a mFactor
        Account, we can make those services even better -- to show you more relevant search results, to help you connect
        with people or to make sharing with others quicker and easier. As you use our services, we want you to be clear
        how we're using information and the ways in which you can protect your privacy.
        <br />
        <br />
        Our Privacy Policy explains:
        <br />
        <ul>
          <li>What information we collect and why we collect it.</li>
          <li>How we use that information</li>
          <li>The choices we offer, including how to access and update information.</li>
        </ul>
        <br />
      </p>
      <h4 className='text-bold'>Information we collect</h4>
      <p>
        We collect information to provide better services to all of our users -- from figuring out basic stuff like
        which langauge you speak, to more complex things like which product you'll find most useful or the authors who
        matter most to you online.
        <br />
        <br />
        We collect information in two ways:
        <br />
        <ul>
          <li>
            <span className='text-bold'>Information you give us.</span> For example, when you create a app, we'll ask
            for app information, like name, description and contact information. We might also ask you to create default
            options, which you could override them before using your app.
          </li>
          <li>
            <span className='text-bold'>Information we get from your use of our services.</span> We may collect
            information about the services that you use and how you use them. This information includes:
            <ul>
              <li className='text-bold'>Device information</li>
              <li className='text-bold'>Log information</li>
              <li className='text-bold'>Location information</li>
              <li className='text-bold'>Local storage</li>
              <li className='text-bold'>Cookie and anonymous identifiers</li>
            </ul>
          </li>
        </ul>
        <br />
      </p>
      <h4 className='text-bold'>How we use information we collect</h4>
      <p>
        We use the information we collect from all of our services to provide, maintain, protect and improve them, to
        develop new ones, and to protect mFactor and our users. We also use this information to offer you tailored
        content -- like giving you more relevant search results and apps.
        <br />
        <br />
        When you contact mFactor, we may keep a record of your communication to help solve any issues you might be
        facing. We may use your email address to inform you about our services, such as letting you know about upcomming
        changes or improvements.
        <br />
        <br />
        We will ask for your consent before using information for a purpose other than those that are set out in this
        Privacy Policy.
        <br />
        <br />
        mFactor processes personal information on our servers in many countries around the world. We may process your
        personal information on a server located outside the country where you live.
        <br />
        <br />
      </p>
      <h4 className='text-bold'>Transparency and choice</h4>
      <p>
        People have different privacy concerns. Our goal is to be clear about what information we collect, so that you
        can make meaningful choices about how it is used. For example, you can:
        <ul>
          <li>Review and control certain types of information tied to your mFactor Account.</li>
          <li>Use our forms to see and adjust how your mFactor Profile appears to particular individuals.</li>
          <li>Control who you share information with.</li>
          <li>Take information out of many of our services.</li>
        </ul>
        <br />
      </p>
      <h4 className='text-bold'>Information you share</h4>
      <p>
        Many of our services let you share information with others. Remember that when you share information publicly,
        it may be indexable by search engines , including mFactor. Our services provide you with different options on
        sharing and removing your content.
        <br />
        <br />
      </p>
      <h4 className='text-bold'>Accessing and updating your personal information</h4>
      Whenever you use our services, we aim to provide you with access to your personal information. If that information
      is wrong, we strive to give you ways to update it quickly or to delete it -- unless we have to keep that
      information for legitimate business or legal purposes. When updating your personal information, we may ask you to
      verify your identity before we can act on your request.
      <br />
      <br />
      We may reject requests that are unreasonably repetitive, require disproportionate technical effort (for example,
      developing a new system or fundamentally changing an existing practice), risk the privacy of others, or would be
      extremely impractical (for instance, requests concerning information residing on backup tapes).
      <br />
      <br />
      Where we can provide information access and correction, we will do so for free, except where it would require a
      disproportionate effort. We aim to maintain our services in a manner that protects information from accidental or
      malicious destruction. Because of this, after you delete information from our services, we may not immediately
      delete residual copies from our active servers and may not remove information from our backup systems.
      <br />
      <br />
      <h4 className='text-bold'>Information we share</h4>
      <p>
        We do not share personal information with companies, organizations and individuals outside of mFactor unless one
        of the following circumstances apply:
        <ul>
          <li className='text-bold'>With your consent</li>
          We will share personal information with companies, organizations and individuals outside of mFactor when whe
          have your consent to do so. We require opt-in consent for the sharing of any sensitive personal information.
          <br />
          <br />
          <li className='text-bold'>For legal reasons</li>
          We will share personal information with companies, organizations or individuals outside of mFactor if we have
          a good-faith belief that access, use, preservation or disclosure of the information is reasonably necessary
          to:
          <ul>
            <li>meet any applicable law, regulation, legal process or enforceable governmental request.</li>
            <li>enforce applicable Terms of Service, including investigation of potential violations.</li>
            <li>detect, prevent, or otherwise address fraud, security or technical issues.</li>
            <li>
              protect against harm to the rights, property or safety of mFactor, our user or the public as required or
              permitted by law.
            </li>
          </ul>
        </ul>
        We may share aggregated, non-personally identifiable information publicly and with our partners -- like
        publishers, advertisers or connected sites. For example, we may share information publicly to show trends about
        the general use of our services.
        <br />
        <br />
        If mFactor is involved in a merger, acquisition or asset sale, we will continue to ensure the confidentiality of
        any personal information and give affected users notice before personal information is transferred or becomes
        subject to a different privacy policy.
        <br />
        <br />
      </p>
      <h4 className='text-bold'>Changes</h4>
      <p>
        Our Privacy Policy may change form time to time. We will not reduce your rights under this Privacy Policy
        without your explicit consent. We will post any privacy policy changes on this page and, if the changes are
        significant, we will provide a more prominent notice (including, for certain services, email notification of
        privacy policy changes). We will also keep prior versions of this Privacy Policy in an archive for your review.
        <br />
        <br />
        <br />
      </p>
    </PageContainer>
  )
}

export default React.memo(Privacy)
