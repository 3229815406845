import { connect } from '../../data/connect'
import { setFactorItemList, setTaxFactor } from '../../data/factor/factor.actions'
import { TaxFactor } from '../../models/mfactor/TaxFactor'
import { IonButton, IonIcon } from '@ionic/react'
import { t } from 'i18next'
import { add } from 'ionicons/icons'
import { FC, memo } from 'react'
import { useHistory } from 'react-router'

interface StateProps {
  taxFactor?: TaxFactor
}
interface DispatchProps {
  setFactorItemList: typeof setFactorItemList
  setTaxFactor: typeof setTaxFactor
}
interface FactorAddButtonProps extends StateProps, DispatchProps {}
const FactorAddButton: FC<FactorAddButtonProps> = ({ setFactorItemList, setTaxFactor, taxFactor }) => {
  const history = useHistory()
  return (
    <>
      <IonButton
        onClick={() => {
          setFactorItemList([])
          setTaxFactor({
            ...taxFactor,
            factor: {
              ...taxFactor?.factor,
              factorDate: null,
              payer: null, // to reset PersonSelect widget
              payerName: null,
            },
          })
          setTimeout(() => {
            setTaxFactor(null)
          }, 300)
          history.push('/desk')
        }}
      >
        <IonIcon icon={add} slot='start' />
        {t<string>('New factor')}
      </IonButton>
    </>
  )
}

export default connect<{}, {}, DispatchProps>({
  mapStateToProps: () => ({}),
  mapDispatchToProps: {
    setFactorItemList,
    setTaxFactor,
  },
  component: memo(FactorAddButton),
})
