import { t } from 'i18next'

const success = (message: string, present: Function, duration = 3000) => {
  present({ message: t(message), color: 'success', duration })
}

const failure = (message: string, present: Function) => {
  present({ message: t(message), color: 'danger', duration: 3000 })
}

const info = (message: string, present: Function, options?: any) => {
  present({ message: t(message, options), color: 'tertiary', duration: 3000 })
}

export { success, failure, info }
