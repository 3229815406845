import PageContainer from '../PageContainer'
import { t } from 'i18next'
import React from 'react'

const TermsFa = () => {
  return (
    <PageContainer id='terms-page' isPrivate={false} dir='rtl' title={t<string>('Terms')}>
      <section>
        <div>
          <div>
            <h3>توافقنامه خدمات نرم‌افزاری میکرو فاکتور (ویرایش 1403/12/06)</h3>

            <p>
              این توافقنامه میان <strong>شرکت راستین پایدار تاک</strong> (ارائه‌دهنده سامانه میکرو فاکتور) و
              <strong>مالک اشتراک</strong> (شما به عنوان کاربر) منعقد می‌شود.
            </p>

            <h3>مدت قرارداد</h3>
            <p>• مدت اعتبار قرارداد مطابق پلن انتخابی شما تعیین می‌شود</p>
            <p>
              • حساب‌های کاربری غیرفعال به مدت 1 سال، حذف خواهند شد (صورتحساب‌های ارسالی به سامانه مؤدیان باقی می‌ماند)
            </p>
            <p>• عدم وجود اعتبار کافی، امکان ایجاد صورتحساب جدید را مسدود می‌کند</p>

            <h3>تعهدات کاربران</h3>
            <div className='clause-section'>
              <h4>امنیت و مسئولیت‌پذیری</h4>
              <p>• انتقال یا واگذاری حساب کاربری به دیگران مجاز نیست</p>
              <p>• حفظ امنیت حساب (شامل انتخاب رمز عبور قوی) بر عهده شماست</p>
              <p>• مسئولیت محتوای وارد شده در سامانه کاملاً بر عهده کاربر است</p>

              <h4>ارسال صورتحساب</h4>
              <p>• تهیه گواهی دیجیتال و کلید خصوصی بر عهده کاربر است</p>
              <p>• مسئولیت صحت و زمان ارسال صورتحساب‌ها به عهده کاربر می‌باشد</p>
              <p>• نمایش کد 22 رقمی پس از ارسال موفق به سامانه مؤدیان انجام می‌شود</p>

              <h4>زیرساخت فنی</h4>
              <p>• تأمین اینترنت پرسرعت و دستگاه‌های مناسب بر عهده کاربر است</p>
              <p>• در صورت قطعی سامانه مؤدیان، امکان ارسال صورتحساب وجود ندارد</p>
            </div>

            <h3>حقوق مالکیت</h3>
            <p>• کلیه حقوق نرم‌افزار متعلق به شرکت راستین پایدار تاک است</p>
            <p>• هرگونه استفاده غیرمجاز پیگرد قانونی دارد</p>

            <h3>مقررات محتوا و نظرات</h3>
            <p>• محتوای توهین‌آمیز یا غیراخلاقی حذف خواهد شد</p>
            <p>• درج اطلاعات شخصی یا تبلیغات ممنوع است</p>
            <p>• نظرات با نام کاربری نامناسب تأیید نمی‌شوند</p>

            <h3>تعرفه و پرداخت</h3>
            <p>• هزینه اشتراک بر اساس تعداد فاکتور یا مدت زمان تعیین می‌شود</p>
            <p>• پس از پرداخت، امکان تغییر بسته یا بازگشت وجه وجود ندارد</p>
            <p>• اطلاعات خرید پس از ثبت، قابل ویرایش نیست</p>

            <h3>به‌روزرسانی‌ها</h3>
            <p>• شرکت حق به‌روزرسانی توافقنامه را محفوظ می‌دارد</p>
            <p>• بروزرسانی‌های مالیاتی در اسرع وقت اعمال می‌شود</p>
            <p>• بروزرسانی‌ها در ساعات کم‌ترافیک انجام می‌شود</p>

            <h3>پشتیبانی</h3>
            <p>• پشتیبانی از طریق تیکت در ساعات اداری انجام می‌شود</p>
            <p>• مشکلات فنی سامانه مؤدیان بر عهده سازمان مالیات است</p>
            <p>• نظارت 24 ساعته بر عملکرد سامانه انجام می‌گیرد</p>

            <h3>پایان قرارداد</h3>
            <p>• ادامه استفاده از سامانه به منزله پذیرش کلیه بندهاست</p>
            <p>• نسخه کامل توافقنامه در منوی سامانه قابل دسترسی است</p>
          </div>
        </div>
      </section>
    </PageContainer>
  )
}

export default React.memo(TermsFa)
