import { appSchema, tableSchema } from '@nozbe/watermelondb'

export default appSchema({
  version: 1,
  tables: [
    tableSchema({
      name: 'factor',
      columns: [
        { name: 'app_public_id', type: 'string' },
        { name: 'currency', type: 'string' },
        { name: 'total', type: 'number' },
        { name: 'detail', type: 'string' },
        { name: 'payer', type: 'string' },
        { name: 'factor_date', type: 'string' },
        { name: 'paid', type: 'boolean' },
        { name: 'paid_ref', type: 'string' },
        { name: 'created_at', type: 'number' },
        { name: 'updated_at', type: 'number' },
      ],
    }),
    tableSchema({
      name: 'person',
      columns: [
        { name: 'app_public_id', type: 'string' },
        { name: 'person_type', type: 'string' },
        { name: 'name', type: 'string' },
        { name: 'national_id', type: 'string' },
        { name: 'economic_code', type: 'string' },
        { name: 'postal_code', type: 'string' },
        { name: 'detail', type: 'string' },
        { name: 'created_at', type: 'number' },
        { name: 'updated_at', type: 'number' },
      ],
    }),
  ],
})
