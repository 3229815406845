import currencySign from '../../components/util/currencySign'
import { FactorItem } from '../../models/mfactor/FactorItem'
import { IonItem, IonLabel, IonText } from '@ionic/react'
import { t } from 'i18next'
import { FC, memo, useEffect, useState } from 'react'

interface OwnProps {
  factorItems: FactorItem[]
  disabled?: boolean
}
const FactorTotal: FC<OwnProps> = ({ factorItems, disabled }) => {
  const [price, setPrice] = useState<number>(0)
  const [discount, setDiscount] = useState<number>(0)
  const [currencies, setCurrencies] = useState<Set<string>>(new Set())

  const calcPrice = () => {
    setPrice(factorItems.reduce((a: any, c: any) => a + c.qty * c.price, 0))
    setDiscount(factorItems.reduce((a: any, c: any) => a + c.qty * c.discount, 0))
  }

  const hasMultiCurrency = () => {
    const currencySet = new Set<string>()
    factorItems.map((fi) => {
      if (fi.currency) currencySet.add(fi.currency)
    })
    setCurrencies(currencySet)
    return currencySet.size > 1
  }

  useEffect(() => {
    calcPrice()
    hasMultiCurrency()
  }, [factorItems])

  return (
    <IonItem disabled={disabled}>
      <IonLabel color={currencies?.size > 1 ? 'danger' : ''}>{t<string>('Total price')}</IonLabel>
      <IonText
        color={currencies?.size > 1 ? 'danger' : ''}
        style={{ paddingLeft: !discount ? '50px' : '5px' }}
        className='dir-ltr'
      >
        {price?.toLocaleString(navigator.language)}{' '}
        {!!discount && <IonText color='danger'>-{discount.toLocaleString(navigator.language)}</IonText>}
        <span>{currencies.size === 1 && currencySign([...currencies][0])}</span>
        <span className='ion-padding'>{currencies?.size > 1 && `${Array.from(currencies).join(' & ')}`}</span>
      </IonText>
      {!!discount && (
        <IonText style={{ paddingLeft: '50px' }} className='dir-ltr'>
          {(+price - +discount).toLocaleString(navigator.language)} =
        </IonText>
      )}
    </IonItem>
  )
}

export default memo(FactorTotal)
