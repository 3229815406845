import { info } from '../util/Toast'
import { IonText, useIonToast } from '@ionic/react'
import { FC } from 'react'
import CopyToClipboard from 'react-copy-to-clipboard'

interface TaxIdTextProps {
  taxId?: string
}
const TaxIdText: FC<TaxIdTextProps> = ({ taxId }) => {
  const [toast] = useIonToast()
  return (
    <CopyToClipboard text={taxId ?? ''} onCopy={() => info('Copied X', toast, { X: taxId })}>
      <span>
        {taxId?.length === 22 && (
          <>
            <IonText color='tertiary'>{taxId?.substring(0, 6) ?? ''}</IonText>
            <IonText>{taxId?.substring(6, 11) ?? ''}</IonText>
            <IonText color='tertiary'>{taxId?.substring(11, 21) ?? ''}</IonText>
            <IonText>{taxId?.substring(21) ?? ''}</IonText>
          </>
        )}
        {!!taxId && taxId?.length !== 22 && <IonText color={'danger'}>{taxId}</IonText>}
      </span>
    </CopyToClipboard>
  )
}

export default TaxIdText
