import '../../components/select.scss'
import { connect } from '../../data/connect'
import { TaxIrStuffOption } from '../../models/mfactor/TaxIrStuffOption'
import { GtinService } from '../../services/GtinService'
import TkSpinner from '../util/TkSpinner'
import useDebounce from '../util/useDebounce'
import StuffIdModal from './StuffIdModal'
import { IonButton, IonCol, IonIcon, IonRow, useIonModal } from '@ionic/react'
import { t } from 'i18next'
import { add } from 'ionicons/icons'
import { FC, lazy, memo, useEffect, useState } from 'react'

const Select = lazy(() => import('react-select'))

interface OwnProps {
  defaultValue?: TaxIrStuffOption
  defaultId?: number
  defaultDesc?: string
  productName?: string
  onChange: (value?: TaxIrStuffOption) => void
  disabled?: boolean
}
interface StateProps {}
interface DispatchProps {}
interface TaxIrStuffSelectProps extends OwnProps, StateProps, DispatchProps {}
const TaxIrStuffSelect: FC<TaxIrStuffSelectProps> = ({
  defaultValue,
  defaultId,
  defaultDesc,
  productName,
  onChange,
  disabled = false,
}) => {
  const [options, setOptions] = useState<TaxIrStuffOption[]>()
  const [items, setItems] = useState<TaxIrStuffOption[]>([])
  const [search, setSearch] = useState<string>()
  const [selectedOption, setSelectedOption] = useState<TaxIrStuffOption | undefined>(defaultValue)
  const [fetching, setFetching] = useState(false)
  const [searching, setSearching] = useState(false)
  const searched = useDebounce(search, 2000)
  const [present, dismiss] = useIonModal(StuffIdModal, {
    id: defaultId,
    desc: defaultDesc,
    productName,
    onDismiss: (data: TaxIrStuffOption, role: string) => {
      dismiss(data, role)
      setSelectedOption(data)
    },
  })

  const handleChange = (itm: any) => {
    setSelectedOption(itm)
  }

  const doSearch = async () => {
    setSearching(true)
    const res: any = await GtinService.Instance.get(`/v1/tax-ir/stuff/search${!!searched ? `?desc=${searched}` : ''}`)
    if (res) {
      setItems(res.data || [])
      setOptions(res.data || [])
    }
    setSearching(false)
  }

  const fetchOptions = async () => {
    setFetching(true)
    const res: any = await GtinService.Instance.get(
      `/v1/tax-ir/stuff/search${!!defaultId ? `?id=${defaultId}` : `${!!defaultDesc ? `?desc=${defaultDesc}` : ''}`}`
    )
    if (res) {
      setItems(res.data || [])
      setOptions(res.data)
    }
    setTimeout(() => {
      setFetching(false)
    }, 300)
  }

  useEffect(() => {
    if (searched) doSearch()
  }, [searched])

  useEffect(() => {
    onChange(selectedOption)
  }, [selectedOption])

  useEffect(() => {
    if (!!defaultDesc || !!defaultId) fetchOptions()
  }, [defaultDesc, defaultId]) // eslint-disable-line

  useEffect(() => {
    if (defaultId && items) {
      const defaultStuff = items.find((p: TaxIrStuffOption) => p.value === defaultId)
      if (defaultStuff)
        setSelectedOption({
          value: defaultId,
          label: defaultStuff.label,
        })
    }
  }, [defaultId, items])

  useEffect(() => {
    setSelectedOption(defaultValue)
  }, [defaultValue])

  return (
    <>
      <IonRow>
        <IonCol size='1' className='ion-margin-end'>
          <IonButton
            fill='clear'
            onClick={() =>
              present({
                breakpoints: [0, 1],
                initialBreakpoint: 1,
              })
            }
          >
            <IonIcon icon={add} slot='icon-only' />
          </IonButton>
        </IonCol>
        <IonCol>
          <Select
            isDisabled={disabled || fetching}
            isLoading={fetching}
            value={selectedOption}
            options={options}
            filterOption={() => true}
            onInputChange={(newValue) => setSearch(newValue)}
            onChange={handleChange}
            placeholder={t<string>('Tax IR Stuff')}
            isClearable={true}
            className='my-react-select-container'
            classNamePrefix='my-react-select'
          />
        </IonCol>
        {(fetching || searching) && (
          <IonCol size='2'>
            <TkSpinner />
          </IonCol>
        )}
      </IonRow>
    </>
  )
}

export default connect<OwnProps, StateProps, DispatchProps>({
  mapStateToProps: () => ({}),
  component: memo(TaxIrStuffSelect),
})
