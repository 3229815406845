import { connect } from '../../data/connect'
import { toGregorian, toGregorianDay, toJalali } from './Calendar'
import { log } from './Log'
import './TkDateTime.scss'
import { IonCol, IonDatetimeButton, IonModal, IonRow } from '@ionic/react'
import { Day } from '@taak/react-modern-calendar-datepicker'
import '@taak/react-modern-calendar-datepicker/lib/DatePicker.css'
import { isValid, parseISO } from 'date-fns'
import formatISO from 'date-fns/formatISO'
import { FC, lazy, memo, useEffect, useState } from 'react'
import { lazily } from 'react-lazily'

const { IonDatetime } = lazily(() => import('@ionic/react'))
const DatePicker = lazy(() => import('@taak/react-modern-calendar-datepicker'))

const isValidISO = (isoDate?: string): boolean => {
  try {
    return !!isoDate && isValid(parseISO(isoDate))
  } catch (ignore) {
    return false
  }
}

interface OwnProps {
  id: string
  defaultDate: string | null
  onChange: (value: string | null) => void
  calendarPopperPosition?: 'auto' | 'top' | 'bottom'
  disabled?: boolean
}
interface StateProps {
  lang?: string
}
interface DispatchProps {}
interface TkDatetimeProps extends OwnProps, StateProps, DispatchProps {}
const TkDateTime: FC<TkDatetimeProps> = ({
  id,
  lang,
  onChange,
  defaultDate,
  calendarPopperPosition = 'auto',
  disabled = false,
}) => {
  const [faDay, setFaDay] = useState<Day | any>()
  const [faTime, setFaTime] = useState<string>()
  const [enDateTime, setEnDateTime] = useState<string>()

  useEffect(() => {
    if (lang === 'fa' && faDay && faTime) onChange(`${toGregorian(faDay)}${faTime.substring(faTime.indexOf('T'))}`)
    else if (lang !== 'fa' && enDateTime) onChange(enDateTime)
  }, [faDay, faTime, lang, enDateTime])

  useEffect(() => {
    log('dd', defaultDate)
    setFaDay(undefined)
    setFaTime(undefined)
    setEnDateTime(undefined)
    if (defaultDate && isValidISO(defaultDate)) {
      setTimeout(() => {
        setFaDay(toJalali(toGregorianDay(defaultDate)))
        setFaTime(formatISO(parseISO(defaultDate)))
        setEnDateTime(formatISO(parseISO(defaultDate)))
      }, 300)
    }
  }, [defaultDate])

  return (
    <>
      {lang === 'fa' && (
        <IonRow className='ion-align-items-center'>
          <IonCol className='ion-text-center ion-no-padding'>
            <IonDatetimeButton
              datetime={`${id}-fa`}
              style={{ maxWidth: '70px', display: 'unset' }}
              disabled={disabled}
            ></IonDatetimeButton>
            <IonModal keepContentsMounted={true}>
              <IonDatetime
                id={`${id}-fa`}
                locale='fa-IR'
                value={faTime}
                onIonChange={(e: any) => setFaTime(e.detail.value)}
                presentation='time'
              ></IonDatetime>
            </IonModal>
            <DatePicker
              value={faDay}
              onChange={setFaDay}
              shouldHighlightWeekends
              locale='fa'
              inputClassName={'tk-date-picker-input'}
              calendarPopperPosition={calendarPopperPosition}
              inputProps={{ disabled }}
            />
          </IonCol>
        </IonRow>
      )}
      {lang !== 'fa' && (
        <>
          <IonDatetimeButton datetime={id} disabled={disabled}></IonDatetimeButton>
          <IonModal keepContentsMounted={true}>
            <IonDatetime
              id={id}
              value={enDateTime}
              onIonChange={(e: any) => setEnDateTime(e.detail.value)}
            ></IonDatetime>
          </IonModal>
        </>
      )}
    </>
  )
}

export default connect<OwnProps, StateProps, DispatchProps>({
  mapStateToProps: (state) => ({
    lang: state.user.lang,
  }),
  component: memo(TkDateTime),
})
