import TaxProfileName from '../../components/text/TaxProfileName'
import { error } from '../../components/util/Log'
import TkSpinner from '../../components/util/TkSpinner'
import { failure } from '../../components/util/Toast'
import { connect } from '../../data/connect'
import { setApp, setNet } from '../../data/user/user.actions'
import { App } from '../../models/App'
import { TaxProfile } from '../../models/TaxProfile'
import { MFactorService } from '../../services/MFactorService'
import TaxProfileEditModal from './TaxProfileEditModal'
import { IonLabel, IonRow, IonSearchbar, IonSelect, IonText } from '@ionic/react'
import { IonButton, IonCol, IonIcon, IonItem, IonSelectOption } from '@ionic/react'
import { useIonModal, useIonToast } from '@ionic/react'
import { AxiosResponse } from 'axios'
import { t } from 'i18next'
import { checkmark } from 'ionicons/icons'
import React, { FC, useEffect, useState } from 'react'

interface OwnProps {
  onSuccess?: Function
  selectMode?: boolean
}
interface StateProps {
  app?: App
  net: boolean
}
interface DispatchProps {
  setNet: typeof setNet
  setApp: typeof setApp
}
interface TaxProfileListProps extends OwnProps, StateProps, DispatchProps {}
const TaxProfileList: FC<TaxProfileListProps> = ({ app, net, setNet, onSuccess, setApp, selectMode = false }) => {
  const [items, setItems] = useState<any[]>([])
  const [toast] = useIonToast()
  const [search, setSearch] = useState('')
  const [appPublicId, setAppPublicId] = useState<string>()
  const [present, dismiss] = useIonModal(TaxProfileEditModal, {
    appPublicId,
    onDismiss: (data: string, role: string) => {
      dismiss(data, role)
      fetchItems()
    },
  })
  const [page, setPage] = useState(0)
  const [size, setSize] = useState(10)

  const fetchItems = async () => {
    setNet(true)
    try {
      const res: AxiosResponse = await MFactorService.Instance.get(
        `/v1/tax-profile?page=${page}&size=${size}${!!search ? `&search=${search}` : ''}`
      )
      if (res.status === 200) {
        if (Array.isArray(res.data)) {
          setItems(res.data)
        } else {
          error('Expected an array but got:', res.data)
        }
      }
    } catch (err: any) {
      failure(err?.message, toast)
      error('TaxProfilees#fetchItems', err)
    }
    setNet(false)
  }

  useEffect(() => {
    fetchItems()
    return () => {}
  }, [search, page, size]) // eslint-disable-line

  useEffect(() => {
    setPage(0)
  }, [search])

  return (
    <>
      <IonRow>
        <IonCol>
          <IonSearchbar
            debounce={500}
            placeholder={t<string>('Search modi')}
            onIonInput={(e: any) => setSearch(e.detail.value?.toLocaleLowerCase())}
          />
        </IonCol>
        <IonCol size='1'>
          <IonSelect
            value={size}
            onIonChange={(e: any) => setSize(e.detail.value)}
            okText={t('Select')}
            cancelText={t('Cancel')}
            interface='popover'
          >
            <IonSelectOption value={5}>5</IonSelectOption>
            <IonSelectOption value={10}>10</IonSelectOption>
            <IonSelectOption value={25}>25</IonSelectOption>
            <IonSelectOption value={50}>50</IonSelectOption>
          </IonSelect>
        </IonCol>
      </IonRow>
      {net && <TkSpinner />}
      {!net &&
        items.map((modi: TaxProfile) => (
          <IonItem
            key={modi.appPublicId}
            button={true}
            color={app?.publicId === modi.appPublicId ? (modi?.verified ? 'success' : 'medium') : ''}
            onClick={() => {
              if (selectMode) {
                setApp({
                  name: modi.name,
                  publicId: modi.appPublicId,
                  verified: modi.verified,
                  sandbox: modi.sandbox,
                })
                if (!!onSuccess) onSuccess(app)
              } else {
                setAppPublicId(modi.appPublicId)
                present({
                  breakpoints: [0, 1],
                  initialBreakpoint: 1,
                  backdropDismiss: false,
                })
              }
            }}
          >
            {modi?.verified && <IonIcon slot='start' icon={checkmark} />}
            <IonLabel>
              <TaxProfileName taxProfile={modi} />
              <p>
                {modi.appPublicId
                  .split('-')
                  .map((segment) => segment[0])
                  .join('')}
              </p>
            </IonLabel>
            <IonText slot='end'>{modi.memoryId}</IonText>
          </IonItem>
        ))}
      <IonRow className='ion-align-items-center ion-padding-top'>
        <IonCol className='ion-text-center'>
          {page > 0 && (
            <IonButton onClick={() => setPage(page - 1)} disabled={net} fill='clear'>
              {t<string>('Previous')}
            </IonButton>
          )}
        </IonCol>
        <IonCol className='ion-text-center'>{t<string>('Page X', { X: page + 1 })}</IonCol>
        <IonCol className='ion-text-center'>
          {items.length === size && (
            <IonButton onClick={() => setPage(page + 1)} disabled={net} fill='clear'>
              {t<string>('Next')}
            </IonButton>
          )}
        </IonCol>
      </IonRow>
    </>
  )
}

export default connect<OwnProps, StateProps, DispatchProps>({
  mapStateToProps: (state) => ({
    app: state.user.app,
    net: state.user.net,
  }),
  mapDispatchToProps: {
    setNet,
    setApp,
  },
  component: React.memo(TaxProfileList),
})
