import { Model } from '@nozbe/watermelondb'
import { Associations } from '@nozbe/watermelondb/Model'
import { date, text, readonly, json, writer, nochange } from '@nozbe/watermelondb/decorators'

export default class Factor extends Model {
  static table = 'factor'
  static associations: Associations = {
    person: {
      type: 'belongs_to',
      key: 'payer',
    },
  }

  @nochange @text('app_public_id') appPublicId!: string
  @text('currency') currency!: string
  @text('total') total!: number
  @json('detail', (json) => json) detail!: string
  @text('factor_date') factorDate!: string
  @text('payer') payer?: string
  @text('payer_name') payerName?: string
  @text('paid') paid?: boolean
  @text('paid_ref') paidRef?: string
  @readonly @date('created_at') createdAt!: number
  @readonly @date('updated_at') updatedAt!: number

  @writer async markAsDeletedForReal() {
    await super.markAsDeleted()
  }

  workaroundSetRaw() {
    this._setRaw('app_public_id', this.appPublicId)
    this._setRaw('currency', this.currency)
    this._setRaw('total', this.total)
    this._setRaw('detail', this.detail)
    this._setRaw('factor_date', this.factorDate)
    this._setRaw('payer', this.payer || null)
    this._setRaw('payer_name', this.payerName || null)
    this._setRaw('paid', this.paid || null)
    this._setRaw('paid_ref', this.paidRef || null)
    return this
  }

  workaroundSetModel() {
    this.appPublicId = this._getRaw('app_public_id') as string
    this.currency = this._getRaw('currency') as string
    this.total = this._getRaw('total') as number
    this.detail = this._getRaw('detail') as string
    this.factorDate = this._getRaw('factor_date') as string
    this.payer = this._getRaw('payer') as string
    this.payerName = this._getRaw('payer_name') as string
    this.paid = this._getRaw('paid') as boolean
    this.paidRef = this._getRaw('paid_ref') as string
    this.createdAt = this._getRaw('created_at') as number
    this.updatedAt = this._getRaw('updated_at') as number
    return this
  }

  toString() {
    return `[${this.appPublicId} ${this.currency} ${this.total} ${this.detail} ${this.factorDate} ${this.payer} ${this.paid} ${this.paidRef}]`
  }
}
