import '../../components/select.scss'
import { connect } from '../../data/connect'
import { GenericOption } from '../../models/mfactor/GenericOption'
import { MeasurementUnit } from '../../models/mfactor/MeasurementUnit'
import { toFarsiText } from '../util/MeasureUnitUtil'
import { t } from 'i18next'
import { FC, lazy, memo, useEffect, useState } from 'react'

const Select = lazy(() => import('react-select'))

const generateOptions = (): GenericOption[] => {
  return Object.keys(MeasurementUnit)
    .filter((key) => isNaN(Number(key)))
    .map((key) => ({
      value: key,
      label: toFarsiText(key as unknown as MeasurementUnit),
    }))
}

interface OwnProps {
  defaultOption?: GenericOption
  defaultValue?: string
  onChange: (value?: GenericOption) => void
  disabled?: boolean
}
interface StateProps {}
interface DispatchProps {}
interface MeasurementUnitSelectProps extends OwnProps, StateProps, DispatchProps {}
const MeasurementUnitSelect: FC<MeasurementUnitSelectProps> = ({
  defaultOption,
  defaultValue,
  onChange,
  disabled = false,
}) => {
  const [selectedOption, setSelectedOption] = useState<GenericOption | undefined>(
    defaultOption ?? !defaultValue ? { label: 'عدد', value: 'PIECE' } : undefined
  )
  const [options] = useState<GenericOption[]>(generateOptions())

  const handleChange = (itm: any) => {
    setSelectedOption(itm)
  }

  useEffect(() => {
    onChange(selectedOption)
  }, [selectedOption])

  useEffect(() => {
    setSelectedOption(defaultOption)
  }, [defaultOption])

  useEffect(() => {
    const dd = options.filter((o) => o.value === defaultValue)?.[0]
    if (dd) setSelectedOption(dd)
  }, [defaultValue, options])

  return (
    <Select
      isDisabled={disabled}
      value={selectedOption}
      options={options}
      onChange={handleChange}
      placeholder={t<string>('Measurement unit')}
      isClearable={true}
      className='my-react-select-container'
      classNamePrefix='my-react-select'
    />
  )
}

export default connect<OwnProps, StateProps, DispatchProps>({
  mapStateToProps: () => ({}),
  component: memo(MeasurementUnitSelect),
})
