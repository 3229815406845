import { connect } from '../data/connect'
import { setCurrency } from '../data/user/user.actions'
import { convertToEnglish } from './util/Number'
import createNumberMask from './util/createNumberMask'
import currencySign from './util/currencySign'
import { IonCol, IonInput, IonRow, IonSelect, IonSelectOption } from '@ionic/react'
import React, { useState, useEffect } from 'react'
import { conformToMask } from 'react-text-mask'

const defaultMaskOptions = {
  prefix: '',
  suffix: '',
  includeThousandsSeparator: true,
  thousandsSeparatorSymbol: ',',
  allowDecimal: false,
  decimalSymbol: '.',
  decimalLimit: 2, // how many digits allowed after the decimal
  // integerLimit: 7, // limit length of integer numbers
  allowNegative: false,
  allowLeadingZeroes: false,
}

interface OwnProps {
  onValueChange: React.Dispatch<React.SetStateAction<string | undefined>>
  onCurrencyChange: React.Dispatch<React.SetStateAction<string | undefined>>
  defaultValue?: string
  defaultCurrency?: string
  label?: string
  labelPlacement?: 'fixed' | 'start' | 'end' | 'floating' | 'stacked'
  maskOptions?: any
  componentDidMount?: Function
  placeholder?: string
  debounce?: number
  disabled?: boolean
}
interface StateProps {
  lang?: string
  currency?: string
}
interface DispatchProps {
  setCurrency: typeof setCurrency
}
interface CurrencyIonInputProps extends OwnProps, StateProps, DispatchProps {}
const CurrencyIonInput: React.FC<CurrencyIonInputProps> = ({
  label,
  labelPlacement,
  maskOptions,
  onValueChange,
  onCurrencyChange,
  defaultValue,
  defaultCurrency,
  componentDidMount = () => {},
  placeholder,
  debounce,
  disabled,
  lang,
  currency,
  setCurrency,
  ...inputProps
}) => {
  const currencyMask = createNumberMask({
    ...defaultMaskOptions,
    ...maskOptions,
    prefix: currencySign(currency),
  })
  const [amount, setAmount] = useState<string>()
  const maskAmount = (rawAmount = '') => {
    rawAmount = convertToEnglish(rawAmount)
    rawAmount = rawAmount.replace(/\D+\\./g, '')
    const res = conformToMask(rawAmount, currencyMask(rawAmount), {})
    let value = res.conformedValue.replace('_', '')
    value = convertToEnglish(value) // this is necessary for some Android phone e.g. Samsung G5
    setAmount(value)
    onValueChange(value.replace(/[^\d.]/g, ''))
  }

  useEffect(() => {
    if (!!defaultCurrency) setCurrency(defaultCurrency)
  }, [defaultCurrency])

  useEffect(() => {
    componentDidMount()
  }, []) // eslint-disable-line

  return (
    <IonRow>
      <IonCol>
        <IonInput
          value={amount || defaultValue}
          onIonInput={(e: any) => maskAmount(e.detail.value)}
          className='dir-ltr ion-text-start font-roboto'
          placeholder={!!placeholder ? placeholder : currencySign(currency)}
          inputMode='numeric'
          pattern='[0-9]*'
          disabled={disabled}
          debounce={300}
          counter={true}
          maxlength={20}
          {...(!!labelPlacement && { labelPlacement })}
          {...(!!label && { label })}
          {...(!!debounce && { debounce: debounce })}
          {...inputProps}
        />
      </IonCol>
      <IonCol size='2'>
        <IonSelect
          disabled={true}
          className='dir-ltr ion-text-start'
          value={currency}
          onIonChange={(e) => {
            setCurrency(e.detail.value)
            onCurrencyChange(e.detail.value)
          }}
        >
          {lang === 'fa' ? (
            <IonSelectOption value='IRR'>{currencySign('IRR')} IRR</IonSelectOption>
          ) : (
            <IonSelectOption value='USD'>{currencySign('USD')} USD</IonSelectOption>
          )}
          {lang !== 'fa' ? (
            <IonSelectOption value='IRR'>{currencySign('IRR')} IRR</IonSelectOption>
          ) : (
            <IonSelectOption value='USD'>{currencySign('USD')} USD</IonSelectOption>
          )}
          <IonSelectOption value='CAD'>{currencySign('CAD')} CAD</IonSelectOption>
        </IonSelect>
      </IonCol>
    </IonRow>
  )
}

export default connect<OwnProps, StateProps, DispatchProps>({
  mapStateToProps: (state) => ({
    lang: state.user.lang,
    currency: state.user.currency,
  }),
  mapDispatchToProps: {
    setCurrency,
  },
  component: React.memo(CurrencyIonInput),
})
