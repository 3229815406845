import Modal from '../../components/Modal'
import TaxProfileName from '../../components/text/TaxProfileName'
import { connect } from '../../data/connect'
import { App } from '../../models/App'
import TaxProfileList from './TaxProfileList'
import { IonButton, IonIcon } from '@ionic/react'
import { t } from 'i18next'
import { caretDown, search } from 'ionicons/icons'
import React, { FC, useState } from 'react'

interface StateProps {
  app?: App
}
interface OwnProps {
  hideSearchIcon?: boolean
  hideIcon?: boolean
}
interface TaxProfileListButtonProps extends OwnProps, StateProps {}
const TaxProfileListButton: FC<TaxProfileListButtonProps> = ({ app, hideSearchIcon = true, hideIcon = true }) => {
  const [showModal, setShowModal] = useState(false)

  return (
    <>
      <IonButton onClick={() => setShowModal(true)} className='ion-margin-bottom'>
        {!hideSearchIcon && <IonIcon icon={search} slot='start'></IonIcon>}
        {!!app?.name ? <TaxProfileName taxProfile={app} /> : t<string>('Select App')}
        {!hideIcon && <IonIcon icon={caretDown} slot='end'></IonIcon>}
      </IonButton>
      <Modal id='app-list' isOpen={showModal} onDidDismiss={() => setShowModal(false)} title={t<string>('Select App')}>
        <TaxProfileList
          selectMode={true}
          onSuccess={async () => {
            setShowModal(false)
          }}
        />
      </Modal>
    </>
  )
}

export default connect<OwnProps, StateProps, {}>({
  mapStateToProps: (state) => ({
    app: state.user.app,
  }),
  component: React.memo(TaxProfileListButton),
})
