import '../../components/select.scss'
import { connect } from '../../data/connect'
import { IonCol, IonRow, IonSelect, IonSelectOption } from '@ionic/react'
import { t } from 'i18next'
import { FC, memo, useEffect, useState } from 'react'

interface OwnProps {
  defaultValue?: string
  onChange: (value: string) => void
  disabled?: boolean
}
interface StateProps {}
interface DispatchProps {}
interface PersonTypeSelectProps extends OwnProps, StateProps, DispatchProps {}
const PersonTypeSelect: FC<PersonTypeSelectProps> = ({ defaultValue, onChange, disabled = false }) => {
  const [selectedOption, setSelectedOption] = useState<string | undefined>(defaultValue)

  useEffect(() => {
    if (selectedOption) onChange(selectedOption)
  }, [selectedOption])

  useEffect(() => {
    setSelectedOption(defaultValue)
  }, [defaultValue])

  return (
    <>
      <IonRow>
        <IonCol>
          <IonSelect
            label={t('Person type')}
            disabled={disabled}
            value={selectedOption}
            onIonChange={(ev) => setSelectedOption(ev.detail.value)}
            okText={t('Select')}
            cancelText={t('Cancel')}
            interface='popover'
          >
            <IonSelectOption value={'REAL'}>{t<string>('Real')}</IonSelectOption>
            <IonSelectOption value={'COMPANY'}>{t<string>('Company')}</IonSelectOption>
            <IonSelectOption value={'CIVIL_PARTNERSHIP'}>{t<string>('Civil partnership')}</IonSelectOption>
            <IonSelectOption value={'FOREIGNERS'}>{t<string>('Foreigners')}</IonSelectOption>
            <IonSelectOption value={'END_USER'} disabled={true}>
              {t<string>('End user')}
            </IonSelectOption>
          </IonSelect>
        </IonCol>
      </IonRow>
    </>
  )
}

export default connect<OwnProps, StateProps, DispatchProps>({
  mapStateToProps: () => ({}),
  component: memo(PersonTypeSelect),
})
