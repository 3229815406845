import NumberIonInput from '../../components/NumberIonInput'
import { failure } from '../../components/util/Toast'
import remoteErrorExtractor from '../../components/util/remoteErrorExtractor'
import { connect } from '../../data/connect'
import { App } from '../../models/App'
import { MFactorService } from '../../services/MFactorService'
import { useIonToast } from '@ionic/react'
import { AxiosResponse } from 'axios'
import { t } from 'i18next'
import React, { FC, useEffect, useState } from 'react'

interface OwnProps {
  defaultSerial?: number
  serial?: number
  serialType: 'MAIN' | 'OTHER'
  onChange: React.Dispatch<React.SetStateAction<number | undefined>>
  disabled?: boolean
}
interface StateProps {
  app?: App
}
interface DispatchProps {}
interface TaxRecordSerialInputProps extends OwnProps, StateProps, DispatchProps {}
const TaxRecordSerialInput: FC<TaxRecordSerialInputProps> = ({
  defaultSerial,
  serial,
  serialType,
  app,
  onChange,
  disabled = false,
}) => {
  const [inprogress, setInprogress] = useState(false)
  const [toast] = useIonToast()

  const fetchNextSerial = async () => {
    if (app?.publicId) {
      try {
        setInprogress(true)
        const res: AxiosResponse = await MFactorService.Instance.get(
          `/v1/tax-record/serial/${app?.publicId}/${serialType}`,
          {}
        )
        if (res.status === 200) {
          onChange(res.data)
        }
      } catch (err) {
        failure(remoteErrorExtractor(err), toast)
      } finally {
        setInprogress(false)
      }
    }
  }

  useEffect(() => {
    if (serial) onChange(serial)
  }, [serial])

  useEffect(() => {
    if (!defaultSerial) fetchNextSerial()
  }, [defaultSerial, serialType])

  return (
    <NumberIonInput
      label={t('Tax record serial')}
      labelPlacement='floating'
      value={serial}
      onValueChange={onChange}
      disabled={disabled || inprogress}
      maxlength={10}
      minlength={10}
    />
  )
}

export default connect<OwnProps, StateProps, DispatchProps>({
  mapStateToProps: (state) => ({
    app: state.user.app,
  }),
  mapDispatchToProps: {},
  component: React.memo(TaxRecordSerialInput),
})
