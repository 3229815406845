import { convertIsoToZoned } from '../../components/util/Date'
import { log } from '../../components/util/Log'
import { generateRandomId } from '../../components/util/Random'
import { failure, success } from '../../components/util/Toast'
import remoteErrorExtractor from '../../components/util/remoteErrorExtractor'
import { connect } from '../../data/connect'
import { setFactorItemList, setRefreshTaxRecordList, setTaxFactor } from '../../data/factor/factor.actions'
import { setFactorSaving, setRefreshFactorList } from '../../data/factor/factor.actions'
import { App } from '../../models/App'
import { FactorItem } from '../../models/mfactor/FactorItem'
import { TaxFactor } from '../../models/mfactor/TaxFactor'
import { MFactorService } from '../../services/MFactorService'
import { IonButton, IonIcon, IonSpinner, useIonToast } from '@ionic/react'
import { AxiosResponse } from 'axios'
import { t } from 'i18next'
import { save, send } from 'ionicons/icons'
import { FC, memo, useState } from 'react'

interface OwnProps {
  saveAndSend?: boolean
}
interface StateProps {
  app?: App
  taxFactor?: TaxFactor
  factorSaving: boolean
  factorItemList: FactorItem[]
  refreshFactorList: boolean
  refreshTaxRecordList: boolean
}
interface DispatchProps {
  setFactorSaving: typeof setFactorSaving
  setFactorItemList: typeof setFactorItemList
  setRefreshFactorList: typeof setRefreshFactorList
  setTaxFactor: typeof setTaxFactor
  setRefreshTaxRecordList: typeof setRefreshTaxRecordList
}
interface DeskSaveButtonProps extends OwnProps, StateProps, DispatchProps {}
const DeskSaveButton: FC<DeskSaveButtonProps> = ({
  app,
  taxFactor,
  factorSaving,
  factorItemList,
  refreshFactorList,
  refreshTaxRecordList,
  setTaxFactor,
  setFactorSaving,
  setRefreshFactorList,
  setRefreshTaxRecordList,
  saveAndSend = false,
}) => {
  const [toast] = useIonToast()
  const [sending, setSending] = useState(false)

  const sendTaxRecord = async () => {
    try {
      setSending(true)
      const res: AxiosResponse = await MFactorService.Instance.post(
        `/v1/tax-record/${app?.publicId}/${taxFactor?.factor?.id}/send/${taxFactor?.taxRecord?.id}`,
        {}
      )
      if (res.status === 200) {
        success('Tax record sent successfully', toast)
        setTaxFactor({
          ...taxFactor,
          taxRecord: {
            ...taxFactor?.taxRecord,
            ...res.data,
          },
        })
      }
    } catch (err) {
      failure(remoteErrorExtractor(err), toast)
    } finally {
      setSending(false)
    }
  }

  const storeTaxRecord = async () => {
    try {
      setFactorSaving(true)
      if (app?.publicId) {
        if (!factorDetail()) {
          return
        }
        const res: AxiosResponse = await MFactorService.Instance.post(`/v1/tax-record/${app?.publicId}`, {
          ...taxFactor,
          factor: {
            ...taxFactor?.factor,
            id: taxFactor?.factor?.id ?? generateRandomId(),
            appPublicId: app?.publicId,
            currency: factorItemList[0].currency,
            total: factorItemList.reduce((a: any, c: any) => a + c.qty * c.price, 0),
            detail: factorDetail(),
            factorDate: convertIsoToZoned(taxFactor?.factor?.factorDate || null),
          },
          taxRecord: {
            ...taxFactor?.taxRecord,
            invoice: {
              ...taxFactor?.taxRecord?.invoice,
              header: {
                ...taxFactor?.taxRecord?.invoice?.header,
                invoiceSubject: taxFactor?.taxRecord?.invoice?.header?.invoiceSubject ?? 'MAIN',
                invoicePattern: taxFactor?.taxRecord?.invoice?.header?.invoicePattern ?? 'SELL',
                invoiceType: taxFactor?.taxRecord?.invoice?.header?.invoiceType ?? 'TYPE_1',
                settlementMethod: taxFactor?.taxRecord?.invoice?.header?.settlementMethod ?? 'CASH',
              },
              payments: [
                {
                  ...taxFactor?.taxRecord?.invoice?.payments?.[0],
                  paymentDateTime: convertIsoToZoned(
                    taxFactor?.taxRecord?.invoice?.payments?.[0]?.paymentDateTime ?? null
                  ),
                },
              ],
            },
          },
        })
        if (res.status === 200) {
          if (saveAndSend) {
            await sendTaxRecord()
          } else {
            if (taxFactor?.taxRecord?.id) {
              success('Tax record updated successfully', toast)
            } else {
              success('Tax record created successfully', toast)
            }
          }
          setTaxFactor(res.data)
          setRefreshFactorList(!refreshFactorList)
          setRefreshTaxRecordList(!refreshTaxRecordList)
        }
      } else failure('Select an app first', toast)
    } catch (err) {
      log('Error', err)
      failure(remoteErrorExtractor(err), toast)
    } finally {
      setFactorSaving(false)
    }
  }

  const factorDetail = (): string => {
    const items: any[] = []
    for (let i = 0; i < factorItemList.length; i++) {
      if (!factorItemList[i].taxIrStuffId) {
        failure('StuffId is required', toast)
        return ''
      }
      const fi: any = {
        r: i,
        i: factorItemList[i].id.split('_')[1],
        p: factorItemList[i].price,
        d: factorItemList[i].discount,
        q: factorItemList[i].qty,
        mu: factorItemList[i].measurementUnit || 'PIECE',
        v: factorItemList[i].vat || '10',
        iti: factorItemList[i].taxIrStuffId,
        // itn: factorItemList[i].taxIrStuffDesc,
      }
      // console.log(fi)
      items.push(fi)
    }
    return JSON.stringify({ items })
  }

  const disabled = () => {
    return taxFactor?.taxRecord?.status === 'SUCCESS' || taxFactor?.taxRecord?.status === 'IN_PROGRESS'
  }

  return (
    <>
      {saveAndSend && (
        <IonButton disabled={factorSaving || sending || disabled()} onClick={storeTaxRecord} expand='block'>
          {!sending && <IonIcon slot='start' icon={saveAndSend ? send : save} />}
          {sending && <IonSpinner slot='start' name='bubbles' />}
          {t<string>('Send to tax office')}
        </IonButton>
      )}
      {!saveAndSend && (
        <IonButton
          disabled={factorSaving || factorItemList.length === 0 || disabled()}
          onClick={storeTaxRecord}
          expand='block'
        >
          {!factorSaving && <IonIcon slot='start' icon={saveAndSend ? send : save} />}
          {factorSaving && <IonSpinner slot='start' name='bubbles' />}
          {t<string>('Save')}
        </IonButton>
      )}
    </>
  )
}

export default connect<OwnProps, StateProps, DispatchProps>({
  mapStateToProps: (state) => ({
    app: state.user.app,
    taxFactor: state.factor.taxFactor,
    factorDate: state.factor.factorDate,
    factorPayer: state.factor.factorPayer,
    factorSaving: state.factor.factorSaving,
    factorItemList: state.factor.factorItemList,
    refreshFactorList: state.factor.refreshFactorList,
    refreshTaxRecordList: state.factor.refreshTaxRecordList,
  }),
  mapDispatchToProps: {
    setTaxFactor,
    setFactorSaving,
    setFactorItemList,
    setRefreshFactorList,
    setRefreshTaxRecordList,
  },
  component: memo(DeskSaveButton),
})
