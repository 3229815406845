import { connect } from '../../data/connect'
import AppContainer from '../../pages/AppContainer'
import FactorAddButton from '../factor/FactorAddButton'
import TaxRecordList from './TaxRecordList'
import UploadXlsModal from './UploadXlsModal'
import { faFileExcel } from '@fortawesome/free-solid-svg-icons'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { IonButton, useIonModal } from '@ionic/react'
import { t } from 'i18next'
import React, { FC } from 'react'

interface OwnProps {}
interface StateProps {}
interface DispatchProps {}
interface TaxRecordListPageProp extends OwnProps, StateProps, DispatchProps {}
const TaxRecordListPage: FC<TaxRecordListPageProp> = ({}) => {
  const [presentXlsModal, dismiss] = useIonModal(UploadXlsModal, {
    onDismiss: (data: string, role: string) => dismiss(data, role),
  })
  return (
    <AppContainer
      id='tax-record-list'
      actionButtonsStart={<FactorAddButton />}
      actionButtonsEnd={
        <IonButton
          onClick={() =>
            presentXlsModal({
              breakpoints: [0, 1],
              initialBreakpoint: 1,
              backdropDismiss: false,
            })
          }
        >
          <FontAwesomeIcon icon={faFileExcel} className='ion-padding-horizontal' />
          {t<string>('Import Xls')}
        </IonButton>
      }
    >
      <TaxRecordList />
      <div className='ion-padding-vertical'></div>
    </AppContainer>
  )
}

export default connect<{}, StateProps, {}>({
  mapStateToProps: () => ({}),
  mapDispatchToProps: {},
  component: React.memo(TaxRecordListPage),
})
