import TkSpinner from '../components/util/TkSpinner'
import { failure } from '../components/util/Toast'
import { fixKafYe } from '../components/util/Word'
import currencySign from '../components/util/currencySign'
import remoteErrorExtractor from '../components/util/remoteErrorExtractor'
import { connect } from '../data/connect'
import { setRefreshProductList } from '../data/factor/factor.actions'
import { App } from '../models/App'
import Product from '../models/gtin/Product'
import { GtinService } from '../services/GtinService'
import ProductEditModal from './ProductEditModal'
import { IonBadge, IonButton, IonButtons, IonChip } from '@ionic/react'
import { IonCol, IonRow, IonSearchbar, IonSelect, IonSelectOption } from '@ionic/react'
import { IonIcon, IonItem, IonItemOption, IonItemOptions, useIonModal } from '@ionic/react'
import { IonItemSliding, IonLabel, useIonToast } from '@ionic/react'
import { AxiosResponse } from 'axios'
import { t } from 'i18next'
import { trashBin } from 'ionicons/icons'
import React, { FC, useEffect, useState } from 'react'

interface OwnProps {}
interface StateProps {
  app?: App
  lang?: string
  refreshProductList: boolean
}
interface DispatchProps {
  setRefreshProductList: typeof setRefreshProductList
}
interface ProductListProps extends OwnProps, StateProps, DispatchProps {}
const ProductList: FC<ProductListProps> = ({ app, lang, refreshProductList, setRefreshProductList }) => {
  const [toast] = useIonToast()
  const [products, setProducts] = useState<Product[]>([])
  const [deleting, setDeleting] = useState(false)
  const [gtin, setGtin] = useState<string>()
  const [present, dismiss] = useIonModal(ProductEditModal, {
    gtin,
    onDismiss: (data: string, role: string) => dismiss(data, role),
  })
  const [search, setSearch] = useState<string>()
  const [inprogress, setInprogress] = useState(false)
  const [page, setPage] = useState(0)
  const [size, setSize] = useState(10)

  const fetchProducts = async () => {
    if (app?.publicId) {
      setInprogress(true)
      try {
        const res: AxiosResponse = await GtinService.Instance.get(
          `/v1/app-product/${app?.publicId}?page=${page}&size=${size}`,
          {}
        )
        if (res.status === 200) setProducts(res.data)
      } catch (err: any) {
        failure(remoteErrorExtractor(err), toast)
      }
      setInprogress(false)
    }
  }

  const deleteProduct = async (product: Product) => {
    setDeleting(true)
    try {
      const res: AxiosResponse = await GtinService.Instance.delete(`/v1/app-product/${app?.publicId}/${product.gtin}`)
      if (res.status === 204) await fetchProducts()
      setRefreshProductList(!refreshProductList)
    } catch (err) {
      failure(remoteErrorExtractor(err), toast)
    }
    setDeleting(false)
  }

  useEffect(() => {
    fetchProducts()
  }, [refreshProductList, search, app?.publicId, page, size])

  useEffect(() => {
    setPage(0)
  }, [search, app?.publicId])

  return (
    <>
      <IonRow>
        <IonCol>
          <IonSearchbar
            debounce={500}
            placeholder={t('Search product')}
            onIonInput={(e: any) => setSearch(fixKafYe(e.detail.value))}
          ></IonSearchbar>
        </IonCol>
        <IonCol size='1'>
          <IonSelect
            value={size}
            onIonChange={(e: any) => setSize(e.detail.value)}
            okText={t('Select')}
            cancelText={t('Cancel')}
            interface='popover'
          >
            <IonSelectOption value={5}>5</IonSelectOption>
            <IonSelectOption value={10}>10</IonSelectOption>
            <IonSelectOption value={25}>25</IonSelectOption>
            <IonSelectOption value={50}>50</IonSelectOption>
          </IonSelect>
        </IonCol>
      </IonRow>
      {inprogress && <TkSpinner />}
      {products.map((product: Product) => (
        <IonItemSliding key={product.id}>
          <IonItem
            button={true}
            onClick={() => {
              setGtin(product.gtin)
              present({
                breakpoints: [0, 1],
                initialBreakpoint: 1,
                backdropDismiss: false,
              })
            }}
          >
            <IonLabel>
              {lang === 'fa' && (product.nameFa || product.name)}
              {lang !== 'fa' && (product.name || product.nameFa)}
              <p>{product.gtin}</p>
            </IonLabel>
            <IonButtons slot='end' className='dir-ltr'>
              {!!product?.price && (
                <IonChip className='dir-ltr'>
                  {currencySign(product?.currency)} {(+product?.price).toLocaleString(navigator.language)}
                </IonChip>
              )}
              {!!product?.discount && (
                <IonBadge className='dir-ltr' color={'danger'}>
                  -{currencySign(product?.currency)} {(+product?.discount).toLocaleString(navigator.language)}
                </IonBadge>
              )}
            </IonButtons>
          </IonItem>
          <IonItemOptions>
            <IonItemOption color={'danger'} onClick={() => deleteProduct(product)} disabled={deleting}>
              <IonIcon slot='icon-only' icon={trashBin} />
            </IonItemOption>
          </IonItemOptions>
        </IonItemSliding>
      ))}
      <IonRow className='ion-align-items-center ion-padding-top'>
        <IonCol className='ion-text-center'>
          {page > 0 && (
            <IonButton onClick={() => setPage(page - 1)} disabled={inprogress} fill='clear'>
              {t<string>('Previous')}
            </IonButton>
          )}
        </IonCol>
        <IonCol className='ion-text-center'>{t<string>('Page X', { X: page + 1 })}</IonCol>
        <IonCol className='ion-text-center'>
          {products.length === size && (
            <IonButton onClick={() => setPage(page + 1)} disabled={inprogress} fill='clear'>
              {t<string>('Next')}
            </IonButton>
          )}
        </IonCol>
      </IonRow>
    </>
  )
}

export default connect<OwnProps, StateProps, DispatchProps>({
  mapStateToProps: (state) => ({
    app: state.user.app,
    lang: state.user.lang,
    refreshProductList: state.factor.refreshProductList,
  }),
  mapDispatchToProps: {
    setRefreshProductList,
  },
  component: React.memo(ProductList),
})
