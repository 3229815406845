import { dateDisplay } from '../../components/util/Calendar'
import { error } from '../../components/util/Log'
import { connect } from '../../data/connect'
import { UsageBill } from '../../models/mfactor/UsageBill'
import PageContainer from '../../pages/PageContainer'
import { AppApiService } from '../../services/AppApiService'
import { IonItem, IonList, IonListHeader, IonRow, IonSpinner } from '@ionic/react'
import { IonButton, IonCol, IonIcon } from '@ionic/react'
import { AxiosResponse } from 'axios'
import { t } from 'i18next'
import { checkmark, close, refresh } from 'ionicons/icons'
import { FC, useEffect, useState } from 'react'
import { RouteComponentProps } from 'react-router'

interface OwnProps extends RouteComponentProps {}
interface StateProps {}
interface DispatchProps {}
interface UsageBillListPeriodProps extends OwnProps, StateProps, DispatchProps {}
const UsageBillListPeriod: FC<UsageBillListPeriodProps> = () => {
  const [fetching, setFetching] = useState(false)
  const [items, setItems] = useState<UsageBill[]>()

  const fetchItems = async () => {
    setFetching(true)
    try {
      const res: AxiosResponse = await AppApiService.Instance.get(`/v1/usage-bill/list`)
      if (res.status === 200) {
        setItems(res.data)
      }
    } catch (err) {
      error('Fetch error', err)
    }
    setFetching(false)
  }

  useEffect(() => {
    fetchItems()
  }, [])

  return (
    <PageContainer id='usage-bill-list' title='Payment' isPrivate={true}>
      <IonList>
        <IonListHeader>
          {t<string>('Usage bill')}
          {!fetching && (
            <IonButton fill='clear' onClick={fetchItems}>
              <IonIcon icon={refresh} slot='icon-only' />
            </IonButton>
          )}
          {fetching && <IonSpinner className='ion-margin-start' />}
        </IonListHeader>
        <IonRow className='header'>
          <IonCol>{t<string>('Factor number')}</IonCol>
          <IonCol>{t<string>('Create date')}</IonCol>
          <IonCol>{t<string>('Paid status')}</IonCol>
          <IonCol></IonCol>
        </IonRow>
        {items?.length &&
          items.map((itm: UsageBill) => (
            <IonRow key={`${itm.factorId}-${itm.factorPublicId}}`} className='ion-align-items-center'>
              <IonCol>{itm.factorId}</IonCol>
              <IonCol>{dateDisplay(itm.issuedAt, 'fa')}</IonCol>
              <IonCol>
                <IonIcon icon={itm.paid ? checkmark : close} color={itm.paid ? 'success' : 'danger'} size='large' />
              </IonCol>
              <IonCol>
                {!itm.paid && (
                  <IonItem type='button' lines='none' routerLink={`/pay/bill/${itm.factorPublicId}`} color={'primary'}>
                    {t<string>('Payment')}
                  </IonItem>
                )}
              </IonCol>
            </IonRow>
          ))}
      </IonList>
    </PageContainer>
  )
}

export default connect<OwnProps, StateProps, DispatchProps>({
  mapStateToProps: () => ({}),
  component: UsageBillListPeriod,
})
