export enum MeasurementUnit {
  AMPERE = 'AMPERE', // آمپر
  BANKEH = 'BANKEH', // بانکه
  LEAF = 'LEAF', // برگ
  PACKAGE = 'PACKAGE', // بسته
  BARREL = 'BARREL', // بشکه
  BOTTLE = 'BOTTLE', // بطری
  BLOCK = 'BLOCK', // بلوک
  BUNDLE = 'BUNDLE', // بندیل
  PALLET = 'PALLET', // پالت
  PACKET = 'PACKET', // پاکت
  PRESS = 'PRESS', // پرس
  TANKER = 'TANKER', // تانکر
  PLANK = 'PLANK', // تخته
  TON = 'TON', // تن
  TON_KILOMETER = 'TON_KILOMETER', // تن کیلومتر
  BALL = 'BALL', // توپ
  TUBE = 'TUBE', // تیوب
  SECOND = 'SECOND', // ثانیه
  THOBE = 'THOBE', // ثوب
  CUP = 'CUP', // جام
  BOX = 'BOX', // جعبه
  PAIR = 'PAIR', // جفت
  VOLUME = 'VOLUME', // جلد
  CHALIK = 'CHALIK', // چلیک
  CAN = 'CAN', // حلب
  RING_DISC = 'RING_DISC', // حلقه (دیسک)
  CANISTER = 'CANISTER', // دبه
  HAND = 'HAND', // دست
  DEVICE = 'DEVICE', // دستگاه
  OCCURRENCE = 'OCCURRENCE', // دفعه (time)
  MINUTE = 'MINUTE', // دقیقه
  DOZEN = 'DOZEN', // دوجین
  DAY = 'DAY', // روز
  ROLL = 'ROLL', // رول
  ROLL_RING = 'ROLL_RING', // رول - حلقه
  RING = 'RING', // رینگ - حلقه
  SACHET = 'SACHET', // ساشه
  HOUR = 'HOUR', // ساعت
  YEAR = 'YEAR', // سال
  CENTIMETER = 'CENTIMETER', // سانتی متر
  SQUARE_CENTIMETER = 'SQUARE_CENTIMETER', // سانتی متر مربع
  BASKET = 'BASKET', // سبد
  SET = 'SET', // ست
  BUCKET = 'BUCKET', // سطل
  MITHQAL = 'MITHQAL', // سیر
  CYLINDER = 'CYLINDER', // سیلندر
  BRANCH = 'BRANCH', // شاخه
  COMB = 'COMB', // شانه
  FLAME = 'FLAME', // شعله
  SHEET = 'SHEET', // شیت
  PAGE = 'PAGE', // صفحه
  BALE = 'BALE', // طاقه
  TUGHRA = 'TUGHRA', // طغرا
  PIECE = 'PIECE', // عدد
  BALE_LARGE = 'BALE_LARGE', // عدل
  WITHOUT_PACKAGING = 'WITHOUT_PACKAGING', // فاقد بسته بندی
  UNIT_FROND = 'UNIT_FROND', // فروند
  SQUARE_FOOT = 'SQUARE_FOOT', // فوت مربع
  MOLD = 'MOLD', // قالب
  QARASA = 'QARASA', // قراص
  QARASAH_BUNDLE = 'QARASAH_BUNDLE', // قراصه - bundle
  REEL = 'REEL', // قرقره
  ITEM = 'ITEM', // قطعه
  TIN = 'TIN', // قوطی
  CARAT = 'CARAT', // قیراط
  GALLON = 'GALLON', // گالن
  GRAM = 'GRAM', // گرم
  GIGABYTE_PER_SECOND = 'GIGABYTE_PER_SECOND', // گیگا بایت بر ثانیه
  SINGLE_PIECE = 'SINGLE_PIECE', // لنگه
  LITER = 'LITER', // لیتر
  GLASS = 'GLASS', // لیوان
  MONTH = 'MONTH', // ماه
  METER = 'METER', // متر
  SQUARE_METER = 'SQUARE_METER', // مترمربع
  CUBIC_METER = 'CUBIC_METER', // مترمکعب
  MITHQAL_WEIGHT = 'MITHQAL_WEIGHT', // مثقال
  TANK = 'TANK', // مخزن
  MEGAWATT_HOUR = 'MEGAWATT_HOUR', // مگا وات ساعت
  MEGAYONIT = 'MEGAYONIT', // مگایونیت
  MILLIAMPERE = 'MILLIAMPERE', // میلی آمپر
  MILLIGRAM = 'MILLIGRAM', // میلی گرم
  MILLILITER = 'MILLILITER', // میلی لیتر
  MILLIMETER = 'MILLIMETER', // میلی متر
  THREAD = 'THREAD', // نخ
  COPY = 'COPY', // نسخه (جلد)
  PERSON = 'PERSON', // نفر
  PERSON_HOUR = 'PERSON_HOUR', // نفر-ساعت
  TURN = 'TURN', // نوبت
  HALF_DOZEN = 'HALF_DOZEN', // نیم دو جین
  UNIT = 'UNIT', // واحد
  PLATE = 'PLATE', // ورق
  VIAL = 'VIAL', // ویال
  CADRE = 'CADRE', // کادر
  CARTON = 'CARTON', // کارتن
  MASTER_CARTON = 'MASTER_CARTON', // کارتن - master case
  CAPSULE = 'CAPSULE', // کپسول
  COIL = 'COIL', // کلاف
  BAG = 'BAG', // کیسه
  KILOGRAM = 'KILOGRAM', // کیلوگرم
  KILOMETER = 'KILOMETER', // کیلومتر
  KILOWATT_HOUR = 'KILOWATT_HOUR', // کیلووات ساعت
}
