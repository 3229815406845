import { failure } from '../../components/util/Toast'
import { connect } from '../../data/connect'
import { App } from '../../models/App'
import { TaxRecord } from '../../models/mfactor/TaxRecord'
import { MFactorService } from '../../services/MFactorService'
import { faFileExcel } from '@fortawesome/free-solid-svg-icons'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { IonButton, IonIcon, IonSpinner, useIonToast } from '@ionic/react'
import { t } from 'i18next'
import { close } from 'ionicons/icons'
import { FC, memo, useEffect, useRef, useState } from 'react'

interface OwnProps {
  onResult: (result: TaxRecord[]) => void
}
interface StateProps {
  app?: App
}
interface DispatchProps {}
interface UploadXlsButtonProps extends OwnProps, StateProps, DispatchProps {}
const UploadXlsButton: FC<UploadXlsButtonProps> = ({ app, onResult }) => {
  const [file, setFile] = useState<File | null>(null)
  const [toast] = useIonToast()
  const fileInputRef = useRef<HTMLInputElement>(null)
  const [fileName, setFileName] = useState<string>(t('Select Xls'))
  const [inprogress, setInprogress] = useState(false)

  const handleFileChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    if (event.target.files) {
      setFile(event.target.files[0])
      const file: any = event.target.files?.[0]
      if (file) {
        setFileName(file.name)
      } else {
        setFileName(t('Import Xls'))
      }
    }
  }

  const handleUpload = async () => {
    if (!file) {
      failure('Please select a file first!', toast)
      return
    }
    setInprogress(true)
    const formData = new FormData()
    formData.append('file', file)

    try {
      const res = await MFactorService.Instance.post(`/v1/tax-record/upload/${app?.publicId}/xls`, formData, {
        headers: { 'Content-Type': 'multipart/form-data' },
      })
      if (res.status === 200) {
        onResult(res.data)
      }
    } catch (error) {
      failure(`Upload failed: ${(error as any).message}`, toast)
    } finally {
      setInprogress(false)
    }
  }

  useEffect(() => {
    if (file) handleUpload()
  }, [file])

  const handleButtonClick = () => {
    fileInputRef.current?.click()
  }

  const handleFileClear = () => {
    setFileName(t('Select Xls'))
    setFile(null)
    if (fileInputRef.current) {
      fileInputRef.current.value = ''
    }
    onResult([])
  }

  return (
    <>
      <div className='custom-file-input'>
        <input type='file' ref={fileInputRef} style={{ display: 'none' }} onChange={handleFileChange} />

        <IonButton onClick={handleButtonClick} disabled={inprogress}>
          {!inprogress && <FontAwesomeIcon icon={faFileExcel} className='ion-padding-horizontal' />}
          {inprogress && <IonSpinner />}
          {fileName}
        </IonButton>
        <IonButton fill='clear' color={'dark'} onClick={handleFileClear} disabled={inprogress}>
          {!!file && <IonIcon icon={close} slot='icon-only' />}
        </IonButton>
      </div>
    </>
  )
}

export default connect<OwnProps, StateProps, DispatchProps>({
  mapStateToProps: (state) => ({
    app: state.user.app,
  }),
  mapDispatchToProps: {},
  component: memo(UploadXlsButton),
})
