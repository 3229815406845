import { App } from '../../models/App'
import { TaxProfile } from '../../models/TaxProfile'
import { IonText } from '@ionic/react'
import { t } from 'i18next'
import { FC } from 'react'

interface TaxProfileNameProps {
  taxProfile?: TaxProfile | App
}
const TaxProfileName: FC<TaxProfileNameProps> = ({ taxProfile }) => {
  return (
    <IonText>
      {taxProfile?.name} {taxProfile?.sandbox ? `(${t<string>('Testi')})` : ''}
    </IonText>
  )
}

export default TaxProfileName
