import { dateDisplay } from '../../../components/util/Calendar'
import { connect } from '../../../data/connect'
import { User } from '../../../models/User'
import PageContainer from '../../PageContainer'
import { IonButton, IonCol, IonGrid, IonIcon, IonRow, IonText, useIonToast } from '@ionic/react'
import { utils } from '@taak/react-modern-calendar-datepicker'
import isBefore from 'date-fns/isBefore'
import parseISO from 'date-fns/parseISO'
import { t } from 'i18next'
import { checkmarkCircleOutline, cloud, rainy } from 'ionicons/icons'
import { FC, memo } from 'react'

interface StateProps {
  user?: User
  lang?: string
}
interface PlansProps extends StateProps {}
const Plans: FC<PlansProps> = ({ user, lang }) => {
  const [toast] = useIonToast()

  const isExpired = () => {
    const today = utils('en').getToday()
    return user?.planExpire
      ? isBefore(parseISO(user?.planExpire), new Date(today.year, today.month - 1, today.day))
      : true
  }

  const isBasePlanActive = () => {
    const hasPlan = !!user?.plan
    return !hasPlan || isExpired()
  }

  return (
    <PageContainer id='plans' title={t<string>('Support plans')} isPrivate={false}>
      <IonGrid fixed={true}>
        <IonRow>
          <IonCol className='ion-text-center'>{t<string>('')}</IonCol>
          <IonCol className='ion-text-center'>
            <IonRow>
              <IonCol>
                <IonButton fill='clear'>
                  <IonIcon icon={cloud} slot='start' />
                  {t<string>('Base')}
                </IonButton>
              </IonCol>
            </IonRow>
            <IonRow>
              <IonCol>{t<string>('Free')}</IonCol>
            </IonRow>
          </IonCol>
          <IonCol className='ion-text-center'>
            <IonRow>
              <IonCol>
                <IonButton fill='clear'>
                  <IonIcon icon={rainy} slot='start' />
                  {t<string>('Startup')}
                </IonButton>
              </IonCol>
            </IonRow>
            <IonRow>
              <IonCol>
                {(100000).toLocaleString(navigator.language)} {t<string>('Toman')}
              </IonCol>
            </IonRow>
          </IonCol>
        </IonRow>
        <IonRow>
          <IonCol>{t<string>('Max ticket and email response time')}</IonCol>
          <IonCol className='ion-text-center'>{t<string>('24 Hours')}</IonCol>
          <IonCol className='ion-text-center'>{t<string>('8 Hours')}</IonCol>
        </IonRow>
        <IonRow>
          <IonCol>{t<string>('Technical Documents')}</IonCol>
          <IonCol className='ion-text-center'>
            <IonIcon icon={checkmarkCircleOutline} size='large' color='success' />
          </IonCol>
          <IonCol className='ion-text-center'>
            <IonIcon icon={checkmarkCircleOutline} size='large' color='success' />
          </IonCol>
        </IonRow>
        <IonRow>
          <IonCol></IonCol>
          <IonCol>
            {isBasePlanActive() && (
              <IonButton disabled={true} fill='outline' expand='block'>
                {t<string>('Active')}
              </IonButton>
            )}
          </IonCol>
          <IonCol className='ion-text-center'>
            {!isBasePlanActive() && (
              <IonButton disabled={true} fill='outline' expand='block'>
                {t<string>('Active')}
              </IonButton>
            )}
            {isBasePlanActive() && (
              <IonButton expand='block' routerLink='/plan/select/STARTUP'>
                {t<string>('Activate')}
              </IonButton>
            )}
            {user?.planExpire && (
              <IonText color={isExpired() ? 'danger' : 'tertiary'}>{dateDisplay(user?.planExpire, lang)}</IonText>
            )}
          </IonCol>
        </IonRow>
      </IonGrid>
    </PageContainer>
  )
}

export default connect<{}, StateProps, {}>({
  mapStateToProps: (state) => ({
    user: state.user.user,
    lang: state.user.lang,
  }),
  component: memo(Plans),
})
