const utcToLocale = (utcString: string | null | undefined) => {
  if (!!utcString)
    return new Date(utcString).toLocaleString(undefined, {
      year: 'numeric',
      month: 'long',
      day: 'numeric',
      hour: 'numeric',
      minute: 'numeric',
    })
}

const utcToLocaleDate = (utcString: string | null | undefined) => {
  if (!!utcString)
    return new Date(utcString).toLocaleDateString(undefined, {
      year: 'numeric',
      month: 'long',
      day: 'numeric',
    })
}

const defaultTimezone = () => {
  return Intl.DateTimeFormat().resolvedOptions().timeZone
}

// e.g. output +03:30
const getTimeZone = () => {
  var offset = new Date().getTimezoneOffset(),
    o = Math.abs(offset)
  return (offset < 0 ? '+' : '-') + ('00' + Math.floor(o / 60)).slice(-2) + ':' + ('00' + (o % 60)).slice(-2)
}

const convertIsoToZoned = (isoDateTime: string | null) => {
  if (isoDateTime?.endsWith('Z')) {
    return `${isoDateTime.substring(0, isoDateTime.indexOf('Z'))}${getTimeZone()}`
  } else if (isoDateTime?.length === 19) {
    return `${isoDateTime}${getTimeZone()}`
  }
  return isoDateTime
}

export { utcToLocale, utcToLocaleDate, defaultTimezone, getTimeZone, convertIsoToZoned }
