import { connect } from '../../data/connect'
import PageContainer from '../../pages/PageContainer'
import TaxProfileAddButton from './TaxProfileAddButton'
import TaxProfileList from './TaxProfileList'
import React, { FC, useState } from 'react'

interface OwnProps {}
interface StateProps {}
interface DispatchProps {}
interface TaxProfileListPageProp extends OwnProps, StateProps, DispatchProps {}
const TaxProfileListPage: FC<TaxProfileListPageProp> = ({}) => {
  const [reload, setReload] = useState(false)
  return (
    <PageContainer
      id='modi-list'
      isPrivate={true}
      actionButtonsStart={
        <TaxProfileAddButton
          onDismiss={() => {
            setReload(true)
            setTimeout(() => {
              setReload(false)
            }, 300)
          }}
        />
      }
    >
      {!reload && <TaxProfileList />}
      <div className='ion-padding-vertical'></div>
    </PageContainer>
  )
}

export default connect<{}, StateProps, {}>({
  mapStateToProps: () => ({}),
  mapDispatchToProps: {},
  component: React.memo(TaxProfileListPage),
})
