import { error, log } from '../../components/util/Log'
import TkSpinner from '../../components/util/TkSpinner'
import { failure } from '../../components/util/Toast'
import { connect } from '../../data/connect'
import { User } from '../../models/User'
import { UsageBill } from '../../models/mfactor/UsageBill'
import PageContainer from '../../pages/PageContainer'
import { AppApiService } from '../../services/AppApiService'
import { Auth } from '../../services/AuthService'
import { IonChip, IonCol, IonGrid, IonInput, IonSpinner } from '@ionic/react'
import { IonItem, IonLabel, IonList } from '@ionic/react'
import { IonRow, useIonToast } from '@ionic/react'
import { AxiosResponse } from 'axios'
import { t } from 'i18next'
import { FC, useEffect, useState } from 'react'
import { useLocation } from 'react-router'
import TaakSDK from 'taak-sdk'
import { FactorDTO } from 'taak-sdk/dist/mfactor/types'
import { TaakResponse } from 'taak-sdk/dist/taak-response'

interface OwnProps {}
interface StateProps {
  isAuthenticated: boolean
  user?: User
}
interface DispatchProps {}
interface UsageBillCallbackProps extends OwnProps, StateProps, DispatchProps {}
const UsageBillCallback: FC<UsageBillCallbackProps> = ({ isAuthenticated, user }) => {
  const location = useLocation()
  const params = new URLSearchParams(location.search)
  const [publicId] = useState(params.get('publicId'))
  const [factor, setFactor] = useState<FactorDTO>()
  const [usageBill, setUsageBill] = useState<UsageBill>()
  const [trackingCode, setTrackingCode] = useState()
  const [fetching, setFetching] = useState(false)
  const [applying, setApplying] = useState(false)
  const [toast] = useIonToast()
  const taakClient = new TaakSDK({
    appHost: import.meta.env.VITE_APP_BASE_URI,
    gtinHost: import.meta.env.VITE_GTIN_BASE_URI,
    mfactorHost: import.meta.env.VITE_MFACTOR_BASE_URI,
    apiKey: import.meta.env.VITE_APP_WEB_PUSH_API_KEY,
    debug: false,
    local: false,
  })

  const fetchItem = async () => {
    if (fetching) return
    setFetching(true)
    if (publicId) {
      const res: TaakResponse = await taakClient.getFactor(publicId)
      if (res.status === 200) {
        setFactor(res.data)
        setTrackingCode(res.data?.trackingCode)
      } else failure(`${res.status} - ${res.error}`, toast)
    }
    setFetching(false)
  }

  const applyUsageBill = async () => {
    if (applying || !factor?.appPublicId) return
    log('applying with ', factor?.publicId)
    setApplying(true)
    try {
      const res: AxiosResponse = await AppApiService.Instance.put(
        `/v1/usage-bill/public/callback/${factor?.publicId}`,
        {},
        {},
        true
      )
      if (res.status === 200) {
        setUsageBill(res.data)
        if (res.data.applied && isAuthenticated) loadUserInfo()
      } else {
        failure(`Error ${res.status}`, toast)
      }
    } catch (err: any) {
      error('')
    }
    setApplying(false)
  }

  const loadUserInfo = async () => {
    await Auth.Instance.loadUserInfo().catch((err: any) => failure(JSON.stringify(err), toast))
  }

  useEffect(() => {
    if (factor?.paid) applyUsageBill()
  }, [factor?.paid])

  useEffect(() => {
    if (publicId && !fetching) fetchItem()
  }, [publicId])

  return (
    <PageContainer id='usage-bill-callback' title='Usage bill payment result' isPrivate={false}>
      <IonGrid>
        <IonRow>
          <IonCol>
            <IonInput
              disabled={true}
              value={trackingCode}
              onIonInput={(e: any) => setTrackingCode(e.detail.value)}
              label={t<string>('Tracking code')}
            />
          </IonCol>
        </IonRow>
        <IonRow>
          <IonCol>{t<string>('Status')}</IonCol>
          <IonCol>{factor?.paid ? t<string>('Paid') : t<string>('Not paid')}</IonCol>
        </IonRow>
        {applying && <TkSpinner />}
        {!applying && (
          <IonList>
            <IonItem>
              <IonLabel>
                {!usageBill?.factorId && !factor?.trackingCode && !fetching && !applying && t<string>('Not found')}
                <p>
                  {(fetching || applying) && <IonSpinner />}
                  {usageBill?.paid ? (
                    <IonChip color='success' className='unselectable-chip'>
                      {t<string>('Applied')}
                    </IonChip>
                  ) : (
                    <>
                      {!fetching && !applying && (
                        <IonChip color='danger' className='unselectable-chip'>
                          {t<string>('Not applied')}
                        </IonChip>
                      )}
                    </>
                  )}
                </p>
              </IonLabel>
            </IonItem>
            {!factor?.paid && factor?.publicId && !fetching && !applying && (
              <IonItem button={true} routerLink={`/pay/bill/${factor?.publicId}`}>
                <IonLabel>{t<string>('Try again')}</IonLabel>
              </IonItem>
            )}
          </IonList>
        )}
      </IonGrid>
    </PageContainer>
  )
}

export default connect<OwnProps, StateProps, DispatchProps>({
  mapStateToProps: (state) => ({
    user: state.user.user,
    isAuthenticated: state.user.isAuthenticated,
  }),
  component: UsageBillCallback,
})
