import PageContainer from '../PageContainer'
import React from 'react'

const Terms = () => {
  return (
    <PageContainer id='terms-page' isPrivate={false} dir='ltr' title='Terms'>
      <h3 className='text-bold'>mFactor Terms of Service</h3>
      <br />
      <span>Last modified: July 31, 2022</span>
      <br />
      <br />
      <h4 className='text-bold'>Welcome to mFactor!</h4>
      <p>
        Thanks for using our products and services("Services"). The Services are provided by mFactor.ir.
        <br />
        <br />
        By using our Services, you are agreeing to these terms. Please read them carefully.
        <br />
        <br />
        Our Services are diverse, so sometimes additional terms or product requirements may apply. Additional terms will
        be available with the relevant Services, and those additional terms become part of your agreement with us if you
        use those Services.
        <br />
      </p>
      <h4 className='text-bold'>Using our Services</h4>
      <p>
        You must follow any policies made available to you within the Services.
        <br />
        <br />
        Don't misuse our Services. For example, don't interfere with our Services or try to access them using a method
        other than the interface and the instructions that we provide. We may suspend or stop providing our Services to
        you if you do not comply with our terms or policies or if we are investigating suspected misconduct.
        <br />
        <br />
        Using our Services does not give you ownership of any intellectual property rights in our Services or the
        content you access. You may not use content from our Services unless you obtain permission from its owner or are
        otherwise permitted by law. These terms do not grant you the right to use any branding or logos used in our
        Services. Don't remove, obscure, or alter any legal notices displayed in or along with our Services.
        <br />
        <br />
        Our Services display some content that is not mFactor's. This content is the sole responsibility of the entity
        that makes it available. We may review content to determine whether it is illegal or violates our policies, and
        we may remove or refuse to display content that we resonably believe violates our policies or the law. But that
        does not necessarily mean that we review content, so please don't assume that we do.
        <br />
        <br />
        In connection with your use of the Services, we may send you service announcements, administrative messages, and
        other information. You may opt out of some of those communications.
        <br />
        <br />
      </p>
      <h4 className='text-bold'>Privacy and Copyright Protection</h4>
      <p>
        mFactor's <a href='/privacy'>privacy policies</a> explain how we treat your personal data and protect your
        privacy when you use our Services. By using our Services, you agree the mFactor can use such data in accordance
        with our privacy policies.
        <br />
        <br />
        We respond to notices of alleged copyright infringement and terminate accounts of repeat infringers according to
        the process set out in the U.S. Digital Millennium Copyright Act.
        <br />
        <br />
        We provide information to help copyright holders manage their intellectual property online. If you think
        somebody is violating your copyrights and want to notify us, you can find information about submitting notices
        and mFactor's policy about responding to notices <a href='/support'>in our Help Center</a>.
        <br />
        <br />
      </p>
      <h4 className='text-bold'>Your Content in our Services</h4>
      <p>
        Some of our Services allow you to submit content. You retain ownership of any intellectual property rights that
        you hold in that content. In short , what belongs to you stays yours.
        <br />
        <br />
        When you upload or otherwise submit content to our Services, you give mFactor (and those we work with) a
        worldwide license to use, host, store, reproduce, modify, create derivative works (such as those resulting from
        translations, adaptations or other changes we make so that your content works better with our Services),
        communicate, publish, publicy perform, publicly display and distribute such content. The rigths you grant in
        this license are for the limited purpose of operating, promoting, and improving our Services, and to develop new
        ones. This license continues even if you stop using our Services. Some Services may offer you ways to access and
        remove content that has been provided to that Service. Also, in some of our Services, there are terms or
        settings that narrow the scope of our use of the content submitted in those Services. Make sure you have the
        necessary rights to grant us this license for any content that you submit to our Services.
        <br />
        <br />
        If you have a mFactor Account, we may display your Profile name, Profile photo, and actions you take on mFactor
        or on third-party applications connected to your mFactor Account (such as reviews you write and comments you
        post) in our Services, in ads and other commercial contexts. We will respect the choices you make to limit
        sharing or visibility settings in your mFactor Account.
        <br />
        <br />
      </p>
      <h4 className='text-bold'>About Software in our Services</h4>
      <p>
        mFactor gives you a personal, worldwide, royalty-fee, non-assignable and non-exclusive license to use the
        software provided to you by mFactor as part of the Services. This license is for the sole purpose of enabling
        you to use and enjoy the benefit of the Services as provided by mFactor, in the manner permitted by these terms.
        You may not copy, modify, distribute, sell, or lease any part of our Services or included software, unless laws
        prohibit those restrictions or you have our written permission.
        <br />
        <br />
        Open source software is important to us. Some software used in our Services may be offered under an open source
        license that we will make available to you. There may be provisions in the open source license that expressly
        override some of these terms.
        <br />
        <br />
      </p>
      <h4 className='text-bold'>Modifying and Terminating our Services</h4>
      <p>
        We are constantly changing and improving our Services. We may add or remove functionalities or features, and we
        may suspend or stop a Service altogether.
        <br />
        <br />
        You can stop using our Services at any time, although we'll be sorry to see you go. mFactor may also stop
        providing Services to you, or add or create new limits to our Services at any time.
        <br />
        <br />
        We believe that you own your data and preserving your access to such data is important. If we discontinue a
        Service, where reasonably possible, we will give you reasonable advance notice and a chance to get information
        out of that Service.
        <br />
        <br />
      </p>
      <h4 className='text-bold'>Our Warranties and Disclaimers</h4>
      <p>
        We provide our Services using a commercially reasonable level of skill and care and we hope that you will using
        them. But there are certain things that we don't promise about our Services.
        <br />
        <br />
        OTHER THAN AS EXPRESSLY SET OUT IN THESE TERMS OR ADDITIONAL TERMS, NEITHER mFactor NOR ITS SUPPLIERS OR
        DISTRIBUTORS MAKE ANY SPECIFIC PROMISES ABOUT THE SERVICES. FOR EXAMPLE, WE DON'T MAKE ANY COMMITMENTS ABOUT THE
        CONTENT WITHIN THE SERVICES, THE SPECIFIC FUNCTIONS OF THE SERVICES, OR THEIR RELIABILITY, AVAILABILITY, OR
        ABILITY TO MEET YOUR NEEDS. WE PROVIDE THE SERVICES "AS IS".
        <br />
        <br />
        SOME JURISDICTIONS PROVIDE FOR CERTAIN WARRANTIES, LIKE THE IMPLIED WARRANTY OF MERCHANTABILITY, FITNESS FOR A
        PARTICULAR PURPOSE AND NON-INFRINGEMENT. TO THE EXTENT PERMITTED BY LAW, WE EXCLUDE ALL WARRANTIES.
        <br />
        <br />
      </p>
      <h4 className='text-bold'>Liability for our Services</h4>
      <p>
        WHEN PERMITTED BY LAW, mFactor, AND mFactor'S SUPPLIERS AND DISTRIBUTORS , WILL NOT BE RESPONSIBLE FOR LOST
        PROFITS, REVENUES, OR DATA, FINANCIAL LOSSES OR INDIRECT, SPECIAL, CONSEQUENTIAL, EXEMPLARY, OR PUNITIVE
        DAMAGES.
        <br />
        <br />
        TO THE EXTENT PERMITTED BY LAW, THE TOTAL LIABILITY OF mFactor, AND ITS SUPPLIERS AND DISTRIBUTORS, FOR ANY
        CLAIMS UNDER THESE TERMS, INCLUDING FOR ANY IMPLIED WARRANTIES, IS LIMITED TO THE AMOUNT YOU PAID US TO USE THE
        SERVICES(OR, IF WE CHOOSE, TO SUPPLYING YOU THE SERVICES AGAIN).
        <br />
        <br />
        IN ALL CASES, mFactor, AND ITS SUPPLIERS AND DISTRIBUTORS, WILL NOT BE LIABLE FOR ANY LOSS OR DAMAGE THAT IS NOT
        REASONABLY FORESEEABLE.
        <br />
        <br />
      </p>
      <h4 className='text-bold'>Business uses of our Services</h4>
      <p>
        If you are using our Services on behalf of a business, that business accepts these terms. It will hold harmless
        and indemnify mFactor and its affiliates, officers, agents, and employees from any claim, suit or action arising
        from or related to the use of the Services or violation of these terms, including any liability or expense
        arising from claims, losses, damages, suits, judgments, litigation consts and attorneys' fees.
        <br />
        <br />
      </p>
      <h4 className='text-bold'>About these Terms</h4>
      <p>
        We may modify these terms or any additional terms that apply to a Service to, for example, reflect changes to
        the law or changes to our Services. You should look at the terms regularly. We'll post notice of modifications
        to these terms on this page. We'll post notice of modified additional terms in the applicable Service. Changes
        will not apply retroactively and will become effective no sooner that fourteen days after they are posted.
        However, changes addressing new functions for a Service or changes made for legal reasons will be effective
        immediately. If you do not agree to the modified terms for a Service. you should discontinue your use of that
        Service.
        <br />
        <br />
        If there is a conflict between these terms and the additional terms, the additional terms will control for that
        conflict.
        <br />
        <br />
        These terms control the relationship between mFactor and you. They do not create any third party beneficiary
        rights.
        <br />
        <br />
        If you do not comply with these terms, and we don't take action right away, this doesn't meant that we are
        giving up any rights that we may have (such as taking action in future).
        <br />
        <br />
        If turns out that a particular term is not enforceable, this will not effect any other terms.
        <br />
        <br />
        For information about how to contact mFactor, please visit our <a href='/support'>contact page</a>.
        <br />
        <br />
        <br />
      </p>
    </PageContainer>
  )
}

export default React.memo(Terms)
