import { toFarsiText } from '../../components/util/MeasureUnitUtil'
import { generateRandomId } from '../../components/util/Random'
import { failure } from '../../components/util/Toast'
import { farsiToEnglish } from '../../components/util/Word'
import remoteErrorExtractor from '../../components/util/remoteErrorExtractor'
import { mainSlot, minorSlot } from '../../components/util/resolveSlot'
import { connect } from '../../data/connect'
import { setRefreshTaxRecordList } from '../../data/factor/factor.actions'
import { App } from '../../models/App'
import { TaxFactor } from '../../models/mfactor/TaxFactor'
import { TaxRecord } from '../../models/mfactor/TaxRecord'
import { GtinService } from '../../services/GtinService'
import { MFactorService } from '../../services/MFactorService'
import DeskInqueryButton from '../desk/DeskInqueryButton'
import UploadXlsButton from './UploadXlsButton'
import { IonButton, IonButtons, IonChip, IonCol, IonItem, IonNote } from '@ionic/react'
import { IonItemOption, IonItemOptions, IonItemSliding, IonLabel, IonText } from '@ionic/react'
import { IonContent, IonFooter, IonPage } from '@ionic/react'
import { IonRow, useIonToast } from '@ionic/react'
import { IonHeader, IonIcon, IonSpinner } from '@ionic/react'
import { IonTitle, IonToolbar } from '@ionic/react'
import { AxiosResponse } from 'axios'
import { t } from 'i18next'
import { add, send, square, squareOutline, trashBin } from 'ionicons/icons'
import React, { FC, useState } from 'react'

interface OwnProps {
  onDismiss: (data?: string | null | undefined | number, role?: string) => void
}
interface StateProps {
  hand: 'right' | 'left'
  app?: App
  refreshTaxRecordList: boolean
}
interface DispatchProps {
  setRefreshTaxRecordList: typeof setRefreshTaxRecordList
}
interface UploadXlsModalProps extends OwnProps, StateProps, DispatchProps {}
const UploadXlsModal: FC<UploadXlsModalProps> = ({
  onDismiss,
  hand,
  app,
  refreshTaxRecordList,
  setRefreshTaxRecordList,
}) => {
  const [inprogress, setInprogress] = useState(false)
  const [sending, setSending] = useState(false)
  const [items, setItems] = useState<TaxRecord[]>([])
  const [toast] = useIonToast()

  const store = async (andSend = false) => {
    if (andSend) setSending(true)
    else setInprogress(true)
    if (app?.publicId) {
      try {
        const groupedItems = items.reduce((acc: { [key: string]: TaxRecord[] }, item) => {
          const serial = item.serial
          if (serial && !acc[serial]) {
            acc[serial] = []
          }
          if (serial) {
            acc[serial].push(item)
          }
          return acc
        }, {})

        const mergedItems = Object.values(groupedItems).map((group) => {
          return group.reduce((merged: TaxRecord | null, item) => {
            if (!merged) {
              merged = { ...item }
            } else {
              if (item.invoice) {
                merged.invoice = {
                  ...merged.invoice,
                  body: [...(merged.invoice?.body || []), ...(item.invoice.body || [])],
                  payments: [...(merged.invoice?.payments || []), ...(item.invoice.payments || [])],
                }
              }
              if (item.factor) {
                merged.factor = {
                  ...merged.factor,
                  factorDate: item.factor.factorDate || merged.factor?.factorDate || null,
                  payer: item.factor.payer || merged.factor?.payer || null,
                  payerName: item.factor.payerName || merged?.factor?.payerName || null,
                }
              }
            }
            return merged
          }, null as TaxRecord | null)
        })

        const newItems = [...items]
        for (let mergedItem of mergedItems) {
          if (mergedItem) {
            const factorItems: any = []
            let i = 0
            for (let prd of mergedItem.invoice?.body || []) {
              const res1: AxiosResponse = await GtinService.Instance.get(
                `/v1/app-product/${app?.publicId}/${prd.serviceGoodId}`,
                {}
              )
              if (res1.status === 200) {
                factorItems.push({
                  r: i,
                  i: res1.data.gtin,
                  p: res1.data.price,
                  d: prd?.discountAmount || 0,
                  q: prd.quantity,
                  mu: prd.measurementUnit || res1.data.measurementUnit,
                  v: prd.vatRate || 10,
                  iti: res1.data.taxIrStuffId ?? prd.serviceGoodId,
                  itn: res1.data.taxIrStuffDesc,
                })
              } else {
                const res2: AxiosResponse = await GtinService.Instance.post(`/v1/gs1-wiki/create-local-fa`, {
                  name: farsiToEnglish(prd.serviceGoodDescription!),
                  nameFa: prd.serviceGoodDescription,
                })
                if (res2.status === 200 || res2.status === 201) {
                  const res3: AxiosResponse = await GtinService.Instance.post(
                    `/v1/app-product/${app?.publicId}/${res2.data.gtin}`,
                    {
                      gtin: res2.data.gtin,
                      appPublicId: app?.publicId,
                      price: +prd?.unitFee!,
                      discount: +prd?.discountAmount!,
                      currency: 'IRR',
                      measurementUnit: prd?.measurementUnit,
                      taxIrStuffId: prd?.serviceGoodId,
                    }
                  )
                  if (res3.status === 200 || res3.status === 201) {
                    factorItems.push({
                      r: i,
                      i: res3.data.gtin,
                      p: res3.data.price,
                      d: res3.data.discount,
                      q: prd.quantity,
                      mu: res3.data.measurementUnit,
                      v: prd?.vatRate || 10,
                      iti: res3.data.taxIrStuffId || prd.serviceGoodId,
                      itn: res3.data.taxIrStuffDesc,
                    })
                  }
                }
              }
              i++
            }
            const taxFactor: TaxFactor = {
              factor: {
                id: generateRandomId(),
                appPublicId: app?.publicId,
                currency: 'IRR',
                total: factorItems.reduce((a: any, c: any) => a + c.q * c.p, 0),
                detail: JSON.stringify({ items: factorItems }),
                payer: mergedItem?.factor?.payer ?? null,
                payerName: mergedItem?.factor?.payerName ?? null,
                factorDate: mergedItem?.factor?.factorDate ?? null,
              },
              taxRecord: mergedItem,
            }
            try {
              const res4: AxiosResponse = await MFactorService.Instance.post(
                `/v1/tax-record/${app?.publicId}`,
                taxFactor
              )
              if (res4.status === 200) {
                newItems.forEach((itm, index) => {
                  if (itm.serial === taxFactor?.taxRecord?.serial) {
                    newItems[index] = {
                      ...itm,
                      id: res4.data.taxRecord.id,
                    }
                  }
                })
                if (andSend) {
                  const res5: AxiosResponse = await MFactorService.Instance.post(
                    `/v1/tax-record/${app?.publicId}/${taxFactor?.factor?.id}/send/${res4.data.taxRecord.id}`,
                    {}
                  )
                  if (res5.status === 200) {
                    newItems.forEach((itm, index) => {
                      if (itm.serial === taxFactor?.taxRecord?.serial) {
                        newItems[index] = {
                          ...itm,
                          status: res5.data.status,
                        }
                      }
                    })
                  }
                }
              }
            } catch (err) {
              newItems.forEach((itm, index) => {
                if (itm.serial === taxFactor?.taxRecord?.serial) {
                  newItems[index] = {
                    ...itm,
                    errorText: remoteErrorExtractor(err),
                  }
                }
              })
            }
          }
        }
        setItems([])
        setTimeout(() => {
          setItems(newItems)
        }, 300)
      } catch (err) {
        failure(remoteErrorExtractor(err), toast)
      }
    } else {
      failure('Please select your app first', toast)
    }
    if (andSend) setSending(false)
    else setInprogress(false)
  }

  return (
    <IonPage>
      <IonHeader>
        <IonToolbar>
          <IonButtons slot={minorSlot(hand, document.documentElement.dir)}>
            <IonButton
              onClick={() => {
                onDismiss(null, 'cancel')
                setRefreshTaxRecordList(!refreshTaxRecordList)
              }}
            >
              {t<string>('Cancel')}
            </IonButton>
          </IonButtons>
          <IonTitle>{t<string>('Import Xls')}</IonTitle>
        </IonToolbar>
      </IonHeader>
      <IonContent className='ion-padding'>
        <IonRow>
          <IonCol className='ion-text-center'>
            <a href='/factorTemplate.xlsx'>{t<string>('Download sample Xls')}</a>
          </IonCol>
        </IonRow>
        <IonRow className='ion-padding-top'>
          <IonCol className='ion-text-center'>
            <UploadXlsButton onResult={setItems} />
          </IonCol>
        </IonRow>
        {items.map((itm: TaxRecord, idx: number) => (
          <IonItemSliding key={idx}>
            <IonItem>
              {!!itm?.id && <IonIcon slot='start' icon={!!itm.status ? square : squareOutline}></IonIcon>}
              <IonLabel>
                <IonText color={!itm?.factor?.payerName ? 'danger' : ''}>
                  {itm.factor?.payerName ?? itm.invoice?.header?.buyerEconomicCode}
                </IonText>
                <p>
                  {itm.serial}
                  <IonChip>{t<string>(`Subject ${itm.invoice?.header?.invoiceSubject}`)}</IonChip>
                  <IonText color={!itm.invoice?.body?.[0]?.serviceGoodId ? 'danger' : ''}>
                    {itm.invoice?.body?.[0]?.serviceGoodDescription ?? itm.invoice?.body?.[0]?.serviceGoodId}
                  </IonText>
                  {!!itm.errorText && (
                    <>
                      <br />
                      <IonText color='danger'>{itm.errorText}</IonText>
                    </>
                  )}
                </p>
              </IonLabel>
              <IonText slot='end'>
                {itm.invoice?.body?.[0]?.quantity}
                <span>{toFarsiText(itm.invoice?.body?.[0]?.measurementUnit)}</span>
                {' × '}
                {itm.invoice?.body?.[0]?.unitFee?.toLocaleString(navigator.language)}
                <span>{t<string>('IRR')}</span>
                {!sending && !inprogress && itm.id && <DeskInqueryButton record={itm} />}
              </IonText>
            </IonItem>
            <IonItemOptions>
              <IonItemOption
                color='danger'
                disabled={!!itm.id}
                onClick={() => {
                  const newItems = items.filter((_, i) => i !== idx)
                  setItems(newItems)
                }}
              >
                <IonIcon icon={trashBin} slot='icon-only' />
              </IonItemOption>
            </IonItemOptions>
          </IonItemSliding>
        ))}
      </IonContent>
      <IonFooter>
        <IonToolbar>
          <IonButtons slot={mainSlot(hand, document.documentElement.dir)}>
            {items.length > 0 && (
              <>
                <IonButton onClick={() => store()} disabled={inprogress || sending} color='primary' fill='outline'>
                  {!inprogress && <IonIcon icon={add} slot='start' />}
                  {inprogress && <IonSpinner slot='start' />}
                  {t<string>('Save')}
                </IonButton>
                <IonButton onClick={() => store(true)} disabled={sending || inprogress} color='primary' fill='outline'>
                  {!sending && <IonIcon icon={send} slot='start' />}
                  {sending && <IonSpinner slot='start' />}
                  {t<string>('Save and send')}
                </IonButton>
              </>
            )}
          </IonButtons>
        </IonToolbar>
      </IonFooter>
    </IonPage>
  )
}

export default connect<OwnProps, StateProps, DispatchProps>({
  mapStateToProps: (state) => ({
    hand: state.user.hand,
    app: state.user.app,
    refreshTaxRecordList: state.factor.refreshTaxRecordList,
  }),
  mapDispatchToProps: {
    setRefreshTaxRecordList,
  },
  component: React.memo(UploadXlsModal),
})
