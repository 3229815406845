import { connect } from '../data/connect'
import { convertToEnglish } from './util/Number'
import { IonInput } from '@ionic/react'
import React, { useState, useEffect } from 'react'

interface OwnProps {
  onValueChange: React.Dispatch<React.SetStateAction<number | undefined>>
  label?: string
  debounce?: number
  placeholder?: string
  value?: number
  labelPlacement?: 'fixed' | 'start' | 'end' | 'floating' | 'stacked'
  componentDidMount?: Function
  disabled?: boolean
  maxlength?: number
  minlength?: number
}
interface StateProps {
  lang?: string
}
interface DispatchProps {}
interface NumberIonInputProps extends OwnProps, StateProps, DispatchProps {}
const NumberIonInput: React.FC<NumberIonInputProps> = ({
  label,
  labelPlacement,
  onValueChange,
  value,
  componentDidMount = () => {},
  placeholder,
  debounce,
  disabled,
  lang,
  maxlength,
  minlength,
  ...inputProps
}) => {
  const [amount, setAmount] = useState<number | undefined>(value)

  useEffect(() => {
    onValueChange(amount)
  }, [amount])

  useEffect(() => {
    componentDidMount()
  }, []) // eslint-disable-line

  return (
    <IonInput
      value={value}
      defaultValue={value}
      onIonInput={(e: any) => setAmount(convertToEnglish(e.detail.value))}
      className='dir-ltr ion-text-start font-roboto'
      placeholder={placeholder}
      inputMode='numeric'
      pattern='[0-9]*'
      disabled={disabled}
      debounce={300}
      counter={true}
      {...(!!labelPlacement && { labelPlacement })}
      {...(!!label && { label })}
      {...(!!debounce && { debounce: debounce })}
      {...(!!maxlength && { maxlength: maxlength })}
      {...(!!minlength && { minlength: minlength })}
      {...inputProps}
    />
  )
}

export default connect<OwnProps, StateProps, DispatchProps>({
  mapStateToProps: (state) => ({
    lang: state.user.lang,
  }),
  mapDispatchToProps: {},
  component: React.memo(NumberIonInput),
})
