import CopyRightAndVersion from '../components/CopyRightAndVersion'
import Enamad from '../components/Enamad'
import SamandehiTaakcloud from '../components/SamandehiTaakcloud'
import SignInButton from '../components/auth/SignInButton'
import { connect } from '../data/connect'
import { setSyncing } from '../data/factor/factor.actions'
import { setMenuEnabled, setScrollTop } from '../data/sessions/sessions.actions'
import { setIsAuthenticated, setLastRoute, setUser } from '../data/user/user.actions'
import { IonPage, IonContent, useIonViewWillEnter, IonTitle, IonRow, IonCol, IonRouterLink } from '@ionic/react'
import { IonFab, IonFabButton, IonIcon } from '@ionic/react'
import { useIonViewDidLeave, IonFooter, IonProgressBar } from '@ionic/react'
import { IonSpinner, IonRefresher, IonRefresherContent, RefresherEventDetail } from '@ionic/react'
import { IonHeader, IonToolbar, IonButtons, IonMenuButton } from '@ionic/react'
import { t } from 'i18next'
import { chevronUp } from 'ionicons/icons'
import React, { FC, useEffect } from 'react'
import { useRef } from 'react'
import { useLocation } from 'react-router'

interface OwnProps {
  id: string
  title?: string
  titleWidget?: any
  hideHeader?: boolean
  padding?: boolean
  hideMenu?: boolean
  isPrivate?: boolean
  dir?: string
  actionButtonsStart?: any
  actionButtonsEnd?: any
  children?: any
  footer?: any
  scrollEvents?: boolean
}
interface StateProps {
  net: boolean
  isAuthenticated: boolean
  syncing: number
  scrollTop?: number
}
interface DispatchProps {
  setMenuEnabled: typeof setMenuEnabled
  setLastRoute: typeof setLastRoute
  setIsAuthenticated: typeof setIsAuthenticated
  setUser: typeof setUser
  setScrollTop: typeof setScrollTop
  setSyncing: typeof setSyncing
}
interface PageContainerProps extends OwnProps, StateProps, DispatchProps {}
const PageContainer: FC<PageContainerProps> = ({
  id,
  title,
  titleWidget,
  hideHeader = false,
  padding = true,
  hideMenu = false,
  isPrivate = true,
  setMenuEnabled,
  setLastRoute,
  children,
  dir,
  actionButtonsStart,
  actionButtonsEnd,
  net,
  isAuthenticated,
  setIsAuthenticated,
  setUser,
  footer,
  syncing,
  setSyncing,
  scrollEvents,
  scrollTop,
  setScrollTop,
}) => {
  const loc = useLocation()
  const content = useRef<HTMLIonContentElement>(null)

  useIonViewWillEnter(() => {
    //log(id, 'viewWillEnter')
    setMenuEnabled(!hideMenu)
  })

  useIonViewDidLeave(() => {
    //log(id, 'viewDidLeave')
    setMenuEnabled(true)
  })

  const handleRefresh = async (event: CustomEvent<RefresherEventDetail>) => {
    window?.location?.reload()
  }

  const contain = (target: string, part: string) => {
    //log(target, part, target.indexOf(part) !== -1)
    return target.indexOf(part) !== -1
  }

  const notBlackListed = (path?: string) => {
    if (
      !path ||
      path === '/' ||
      contain(path, '/login') ||
      contain(path, '/logout') ||
      contain(path, '/endsession') ||
      contain(path, '/authcallback')
    ) {
      return false
    }
    return true
  }

  const updateLastRoute = (pathname: string) => {
    if (notBlackListed(pathname)) {
      //log(id, 'Last route updated to ', pathname)
      setLastRoute(pathname)
    }
  }

  const onScrollEnd = (evt: any) => {
    setScrollTop(evt?.target?.detail?.scrollTop)
  }

  const scrollToTop = () => {
    content?.current?.scrollToTop(500)
  }

  useEffect(() => {
    updateLastRoute(loc?.pathname)
    return () => {}
  }, [loc?.pathname]) // eslint-disable-line

  useEffect(() => {
    //log('NET', net)
  }, [net])

  return (
    <IonPage id={id} dir={!!dir ? dir : ''}>
      {!hideHeader && (
        <IonHeader>
          <IonToolbar mode='md'>
            <IonButtons slot='start'>
              <IonMenuButton></IonMenuButton>
              {actionButtonsStart}
            </IonButtons>
            {!!title && <IonTitle>{`${t(title)}`}</IonTitle>}
            {!!titleWidget && <IonTitle>{titleWidget}</IonTitle>}
            <IonButtons slot='end'>
              {net && <IonSpinner name='dots' />}
              {actionButtonsEnd}
            </IonButtons>
            {!!syncing && <IonProgressBar value={syncing}></IonProgressBar>}
          </IonToolbar>
        </IonHeader>
      )}
      <IonContent
        ref={content}
        className={padding ? 'ion-padding' : ''}
        onIonScrollEnd={onScrollEnd}
        scrollEvents={scrollEvents}
      >
        <IonRefresher slot='fixed' onIonRefresh={handleRefresh}>
          <IonRefresherContent></IonRefresherContent>
        </IonRefresher>
        {isPrivate && isAuthenticated && <>{children}</>}
        {isPrivate && !isAuthenticated && (
          <>
            {t('Please')} <SignInButton /> {t('first')}
          </>
        )}
        {!isPrivate && <>{children}</>}
        {!!scrollTop && (
          <IonFab slot='fixed' horizontal='center' vertical='bottom' edge={true}>
            <IonFabButton onClick={scrollToTop} color='light'>
              <IonIcon icon={chevronUp} />
            </IonFabButton>
          </IonFab>
        )}
      </IonContent>
      {!!footer && <IonFooter>{footer}</IonFooter>}
      {!footer && (
        <IonFooter>
          <IonToolbar>
            <IonRow dir='ltr'>
              <IonCol>
                <CopyRightAndVersion />
              </IonCol>
              <IonCol>
                <IonRouterLink href='/terms'>{t<string>('Terms')}</IonRouterLink>
              </IonCol>
              <IonCol>{import.meta.env.VITE_ENV === 'production' && <SamandehiTaakcloud />}</IonCol>
              <IonCol>{import.meta.env.VITE_ENV === 'production' && <Enamad />}</IonCol>
            </IonRow>
          </IonToolbar>
        </IonFooter>
      )}
    </IonPage>
  )
}

export default connect<OwnProps, StateProps, DispatchProps>({
  mapStateToProps: (state) => ({
    net: state.user.net,
    isAuthenticated: state.user.isAuthenticated,
    syncing: state.factor.syncing,
    scrollTop: state.data.scrollTop,
  }),
  mapDispatchToProps: {
    setMenuEnabled,
    setLastRoute,
    setIsAuthenticated,
    setUser,
    setScrollTop,
    setSyncing,
  },
  component: React.memo(PageContainer),
})
