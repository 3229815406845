import { connect } from '../data/connect'
import { User } from '../models/User'
import TaxProfileListButton from '../pages-mfactor/tax-profile/TaxProfileListButton'
import { Auth } from '../services/AuthService'
import './Menu.css'
import { isAdmin } from './util/Auth'
import { error } from './util/Log'
import { IonAccordion, IonAccordionGroup, IonListHeader, IonMenu, IonMenuToggle } from '@ionic/react'
import { IonContent, IonIcon, IonItem, IonLabel, IonList } from '@ionic/react'
import { t } from 'i18next'
import { help, home, person, paperPlane, cart, documentText, receipt } from 'ionicons/icons'
import { people, add, list, fileTrayFull, settings, cube, personCircle } from 'ionicons/icons'
import { logIn, logOut } from 'ionicons/icons'
import React, { FC, useEffect, useState } from 'react'
import { RouteComponentProps, useLocation, withRouter } from 'react-router'

const routes = {
  homePages: [{ title: 'Home', path: '/', icon: home }],
  appPages: [
    { title: 'Register factor', path: '/desk', icon: add },
    { title: 'List factors', path: '/list', icon: list },
    // { title: 'Credentials', path: '/apps/credentials', icon: key },
  ],
  taxPages: [
    { title: 'Modies', path: '/entity/modi', icon: settings },
    { title: 'People', path: '/entity/person', icon: people },
    { title: 'Kala and services', path: '/entity/kala', icon: cube },
  ],
  adminPages: [
    { title: 'Users', path: '/admin/users', icon: people },
    { title: 'Web Push subs', path: '/admin/web-push', icon: paperPlane },
  ],
  loggedInPages: [
    { title: 'User info', path: '/account', icon: personCircle },
    { title: 'Plans', path: '/plans', icon: cart },
    { title: 'Terms', path: '/terms', icon: documentText },
    { title: 'Support', path: '/support', icon: help },
  ],
  loggedOutPages: [
    { title: 'Login', path: '/login', icon: logIn },
    { title: 'Plans', path: '/plans', icon: cart },
    { title: 'Terms', path: '/terms', icon: documentText },
    { title: 'Support', path: '/support', icon: help },
  ],
}

interface Pages {
  title: string
  path: string
  icon: string
  routerDirection?: string
}
interface OwnProps extends RouteComponentProps {}
interface StateProps {
  menuEnabled: boolean
  user?: User
  isAuthenticated: boolean
}
interface DispatchProps {}
interface MenuProps extends OwnProps, StateProps, DispatchProps {}

const Menu: FC<MenuProps> = ({ menuEnabled, user, isAuthenticated }) => {
  const location = useLocation()
  const [show, setShow] = useState(true)
  const [signOutInprogress, setSignOutInprogress] = useState(false)

  const handleSignOut = async (e: any) => {
    setSignOutInprogress(true)
    e.preventDefault()
    try {
      await Auth.Instance.signOut().then().catch(error)
    } catch (err) {
      error('Logout error:', err)
    }
    setSignOutInprogress(false)
  }

  const renderListItems = (list: Pages[]) => {
    return list
      .filter((route) => !!route.path)
      .map((p) => (
        <IonMenuToggle key={p.title} auto-hide='false'>
          <IonItem
            detail={false}
            routerLink={p.path}
            routerDirection='none'
            className={location.pathname.endsWith(p.path) ? 'selected' : undefined}
          >
            <IonIcon slot='start' icon={p.icon} />
            <IonLabel>{t<string>(p.title)}</IonLabel>
          </IonItem>
        </IonMenuToggle>
      ))
  }

  useEffect(() => {
    const timeout = setTimeout(() => {
      setShow(true)
    }, 300)

    return () => clearTimeout(timeout)
  }, [show]) // eslint-disable-line

  return (
    <>
      {show && (
        <IonMenu type='overlay' disabled={!menuEnabled} contentId='main'>
          <IonContent forceOverscroll={false}>
            <IonList lines='none'>
              <IonMenuToggle key={'home'} auto-hide='false'>
                {isAuthenticated && (
                  <IonListHeader>
                    <TaxProfileListButton />
                  </IonListHeader>
                )}
              </IonMenuToggle>
              {renderListItems(routes.homePages)}
              {isAuthenticated && (
                <>
                  {renderListItems(routes.appPages)}
                  <IonAccordionGroup>
                    <IonAccordion value='category1'>
                      <IonItem slot='header'>
                        <IonIcon slot='start' icon={fileTrayFull} />
                        <IonLabel>{t<string>('Basic info')}</IonLabel>
                      </IonItem>
                      <div slot='content'>{renderListItems(routes.taxPages)}</div>
                    </IonAccordion>
                  </IonAccordionGroup>
                  <IonItem lines='none' routerLink='/pay' button={true}>
                    <IonIcon slot='start' icon={receipt} />
                    <IonLabel>{t<string>('Payment')}</IonLabel>
                  </IonItem>
                </>
              )}
            </IonList>
            <IonList lines='none'>
              <IonAccordionGroup>
                <IonAccordion value='category2'>
                  <IonItem slot='header'>
                    <IonIcon slot='start' icon={person} />
                    <IonLabel>{t<string>('User account')}</IonLabel>
                  </IonItem>
                  <div slot='content'>
                    {isAuthenticated ? renderListItems(routes.loggedInPages) : renderListItems(routes.loggedOutPages)}
                    {isAuthenticated && isAdmin(user) && (
                      <>
                        <IonListHeader>{t<string>('Admin')}</IonListHeader>
                        {renderListItems(routes.adminPages)}
                      </>
                    )}
                    {isAuthenticated && (
                      <IonMenuToggle key={'logout'} auto-hide='false'>
                        <IonItem
                          onClick={handleSignOut}
                          disabled={signOutInprogress}
                          detail={false}
                          routerDirection='none'
                          className={location.pathname.startsWith('/logout') ? 'selected pointer' : ' pointer'}
                        >
                          <IonIcon slot='start' icon={logOut} />
                          <IonLabel>{t<string>('Logout')}</IonLabel>
                        </IonItem>
                      </IonMenuToggle>
                    )}
                  </div>
                </IonAccordion>
              </IonAccordionGroup>
            </IonList>
          </IonContent>
        </IonMenu>
      )}
    </>
  )
}

export default connect<OwnProps, StateProps, DispatchProps>({
  mapStateToProps: (state) => ({
    lang: state.user.lang,
    menuEnabled: state.data.menuEnabled,
    user: state.user.user,
    isAuthenticated: state.user.isAuthenticated,
  }),
  mapDispatchToProps: {},
  component: React.memo(withRouter(Menu)),
})
