import { App } from '../models/App'
import { Product } from '../models/Product'
import { User } from '../models/User'
import { FactorItem } from '../models/mfactor/FactorItem'
import { TaxFactor } from '../models/mfactor/TaxFactor'
import { PersonOption } from '../pages-mfactor/person/PersonOption'
import { Preferences as Storage } from '@capacitor/preferences'

const dataUrl = '/assets/data/data.json'

const IS_AUTHENTICATED = 'isAuthenticated'
const HAS_SEEN_TUTORIAL = 'hasSeenTutorial'
const USER = 'user'
const DARK_MODE = 'darkMode'
const LANG = 'lang'
const LAST_ROUTE = 'lastRoute'
const APP_DATA = 'appData'
const WEB_PUSH = 'webPush'
const CURRENCY = 'currency'
const HAND = 'hand'
const FACTOR_ITEM_LIST = 'factorItemList'
const SCAN_BEEP = 'scanBeep'
const SHOW_SCANNER = 'showScanner'
const FACTOR_DATE = 'factorDate'
const FACTOR_PAYER = 'factorPayer'
const TAX_FACTOR = 'taxFactor'

const urlParams = new URLSearchParams(window.location.search)

export const getConfData = async () => {
  const response = await Promise.all([fetch(dataUrl), Storage.get({ key: WEB_PUSH })])
  const responseData = await response[0].json()
  const webPush = (await response[1].value) || 'false'
  const products = responseData.products as Product[]
  const data = {
    products,
    webPush: JSON.parse(webPush),
  }
  return data
}

export const getUserData = async () => {
  const response = await Promise.all([
    Storage.get({ key: IS_AUTHENTICATED }),
    Storage.get({ key: HAS_SEEN_TUTORIAL }),
    Storage.get({ key: USER }),
    Storage.get({ key: DARK_MODE }),
    Storage.get({ key: LANG }),
    Storage.get({ key: LAST_ROUTE }),
    Storage.get({ key: APP_DATA }),
    Storage.get({ key: CURRENCY }),
    Storage.get({ key: HAND }),
  ])
  const isAuthenticated = (await response[0].value) === 'false'
  const hasSeenTutorial = (await response[1].value) === 'true'
  const user = (await response[2].value) || undefined
  const darkMode = (await response[3].value) === 'true'
  const lang = (await response[4].value) || urlParams.get('lang') || 'fa'
  const lastRoute = (await response[5].value) || window.location.pathname
  const app = (await response[6].value) || undefined
  const currency = (await response[7].value) || (lang === 'fa' ? 'IRR' : 'USD')
  const hand = (await response[8].value) || 'right'
  const data = {
    isAuthenticated,
    hasSeenTutorial,
    user: !!user ? JSON.parse(user) : null,
    darkMode,
    lang,
    lastRoute,
    app: !!app ? JSON.parse(app) : undefined,
    currency,
    hand,
  }
  return data
}

export const getFactorData = async () => {
  const response = await Promise.all([
    Storage.get({ key: FACTOR_ITEM_LIST }),
    Storage.get({ key: SCAN_BEEP }),
    Storage.get({ key: FACTOR_DATE }),
    Storage.get({ key: FACTOR_PAYER }),
    Storage.get({ key: SHOW_SCANNER }),
    Storage.get({ key: TAX_FACTOR }),
  ])
  const factorItemList = (await response[0].value) || undefined
  const scanBeep = (await response[1].value) || 'true'
  const factorDate = (await response[2].value) || undefined
  const factorPayer = await response[3].value
  const showScanner = (await response[4].value) || 'true'
  const taxFactor = await response[5].value
  const data = {
    factorItemList: factorItemList ? JSON.parse(factorItemList) : [],
    scanBeep: JSON.parse(scanBeep),
    factorDate,
    factorPayer: factorPayer ? JSON.parse(factorPayer) : undefined,
    showScanner: JSON.parse(showScanner),
    taxFactor: taxFactor ? JSON.parse(taxFactor) : undefined,
  }
  return data
}

export const setIsAuthenticatedData = async (isAuthenticated: boolean) => {
  await Storage.set({ key: IS_AUTHENTICATED, value: JSON.stringify(isAuthenticated) })
}

export const setHasSeenTutorialData = async (hasSeenTutorial: boolean) => {
  await Storage.set({ key: HAS_SEEN_TUTORIAL, value: JSON.stringify(hasSeenTutorial) })
}

export const setUserData = async (user?: User) => {
  if (!user) {
    await Storage.remove({ key: USER })
  } else {
    await Storage.set({ key: USER, value: JSON.stringify(user) })
  }
}

export const setDarkModeData = async (darkMode?: boolean) => {
  if (!darkMode) {
    await Storage.remove({ key: DARK_MODE })
  } else {
    await Storage.set({ key: DARK_MODE, value: JSON.stringify(darkMode) })
  }
}

export const setLangData = async (lang?: string) => {
  if (!lang) {
    await Storage.remove({ key: LANG })
  } else {
    await Storage.set({ key: LANG, value: lang })
  }
}

export const setCurrencyData = async (currency?: string) => {
  if (!currency) {
    await Storage.remove({ key: CURRENCY })
  } else {
    await Storage.set({ key: CURRENCY, value: currency })
  }
}

export const setLastRouteData = async (lastRoute?: string) => {
  if (!lastRoute) {
    await Storage.remove({ key: LAST_ROUTE })
  } else {
    await Storage.set({ key: LAST_ROUTE, value: lastRoute })
  }
}

export const setAppData = async (app?: App | null) => {
  if (!app) {
    await Storage.remove({ key: APP_DATA })
  } else {
    await Storage.set({ key: APP_DATA, value: JSON.stringify(app) })
  }
}

export const setWebPushData = async (webPush?: boolean) => {
  await Storage.set({ key: WEB_PUSH, value: JSON.stringify(webPush) })
}

export const setHandData = async (hand: 'right' | 'left') => {
  await Storage.set({ key: HAND, value: hand })
}

export const setListData = async (list: any[], listKey: string) => {
  if (!list) {
    await Storage.remove({ key: listKey })
  } else {
    await Storage.set({ key: listKey, value: JSON.stringify(list) })
  }
  return list
}

export const addListData = async (item: any, listKey: string) => {
  const listRaw = await Storage.get({ key: listKey })
  const list = listRaw?.value ? JSON.parse(listRaw.value) : []
  if (!list.find((k: any) => k.id === item.id)) {
    item.qty = item.qty ? item.qty : 1
    list.push(item)
    await setListData(list, listKey)
  } else {
    const exist = list.find((k: any) => k.id === item.id)
    if (exist.qty > 0) {
      exist.qty = exist.qty + 1
      list.map((x: any) => (x.id === item.id ? { ...exist } : x))
      await setListData(list, listKey)
    }
  }
  const listRes = await Storage.get({ key: listKey })
  return listRes?.value ? JSON.parse(listRes.value) : []
}

export const removeListData = async (item: any, listKey: string) => {
  const listRaw = await Storage.get({ key: listKey })
  const list = listRaw?.value ? JSON.parse(listRaw.value) : []
  const exist = list.find((x: any) => x.id === item.id)
  if (exist && exist.qty === 1) {
    await setListData(
      list.filter((x: any) => x.id !== item.id),
      listKey
    )
  } else if (exist && exist.qty > 1) {
    exist.qty = exist.qty - 1
    await setListData(
      list.map((x: any) => (x.id === item.id ? { ...exist } : x)),
      listKey
    )
  }
  const listRes = await Storage.get({ key: listKey })
  return listRes?.value ? JSON.parse(listRes.value) : []
}

export const setFactorItemListData = async (factorItemList: FactorItem[]) => {
  return await setListData(factorItemList, FACTOR_ITEM_LIST)
}

export const addFactorItemListData = async (factorItem: FactorItem) => {
  return await addListData(factorItem, FACTOR_ITEM_LIST)
}

export const removeFactorItemListData = async (factorItem: FactorItem) => {
  return await removeListData(factorItem, FACTOR_ITEM_LIST)
}

export const setScanBeepData = async (scanBeep: boolean) => {
  await Storage.set({ key: SCAN_BEEP, value: JSON.stringify(scanBeep) })
}

export const setShowScannerData = async (scanBeep: boolean) => {
  await Storage.set({ key: SHOW_SCANNER, value: JSON.stringify(scanBeep) })
}

export const setFactorPayerData = async (factorPayer?: PersonOption | null) => {
  if (factorPayer) await Storage.set({ key: FACTOR_PAYER, value: JSON.stringify(factorPayer) })
  else await Storage.remove({ key: FACTOR_PAYER })
}

export const setTaxFactorData = async (taxFactor?: TaxFactor | null) => {
  if (taxFactor) await Storage.set({ key: TAX_FACTOR, value: JSON.stringify(taxFactor) })
  else await Storage.remove({ key: TAX_FACTOR })
}

export const setFactorDateData = async (factorDate?: string) => {
  if (factorDate) await Storage.set({ key: FACTOR_DATE, value: factorDate })
  else await Storage.remove({ key: FACTOR_DATE })
}
