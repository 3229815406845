import '../../components/select.scss'
import { connect } from '../../data/connect'
import { IonCol, IonRow, IonSelect, IonSelectOption } from '@ionic/react'
import { t } from 'i18next'
import { FC, memo, useEffect, useState } from 'react'

interface OwnProps {
  defaultValue: string | null
  onChange: (value: string | null) => void
  disabled?: boolean
}
interface StateProps {}
interface DispatchProps {}
interface InvoicePaymentMethodTypeProps extends OwnProps, StateProps, DispatchProps {}
const InvoicePaymentMethodType: FC<InvoicePaymentMethodTypeProps> = ({ defaultValue, onChange, disabled = false }) => {
  const [selectedOption, setSelectedOption] = useState<string | null>(defaultValue)

  useEffect(() => {
    if (selectedOption || selectedOption === null) onChange(selectedOption)
  }, [selectedOption])

  useEffect(() => {
    setSelectedOption(defaultValue)
  }, [defaultValue])

  return (
    <>
      <IonRow>
        <IonCol>
          <IonSelect
            label={t('Payment method type')}
            disabled={disabled}
            value={selectedOption}
            onIonChange={(ev) => setSelectedOption(ev.detail.value)}
            okText={t('Select')}
            cancelText={t('Cancel')}
            interface='popover'
          >
            <IonSelectOption value={null}>{t<string>('Select')}</IonSelectOption>
            <IonSelectOption value={'CZECH'}>{t<string>('PMT CZECH')}</IonSelectOption>
            <IonSelectOption value={'CLEARING'}>{t<string>('PMT CLEARING')}</IonSelectOption>
            <IonSelectOption value={'CASH'}>{t<string>('PMT CASH')}</IonSelectOption>
            <IonSelectOption value={'POS'}>{t<string>('PMT POS')}</IonSelectOption>
            <IonSelectOption value={'IPG'}>{t<string>('PMT IPG')}</IonSelectOption>
            <IonSelectOption value={'CARD'}>{t<string>('PMT CARD')}</IonSelectOption>
            <IonSelectOption value={'DEPOSIT'}>{t<string>('PMT DEPOSIT')}</IonSelectOption>
            <IonSelectOption value={'OTHER'}>{t<string>('PMT OTHER')}</IonSelectOption>
          </IonSelect>
        </IonCol>
      </IonRow>
    </>
  )
}

export default connect<OwnProps, StateProps, DispatchProps>({
  mapStateToProps: () => ({}),
  component: memo(InvoicePaymentMethodType),
})
