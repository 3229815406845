import { MeasurementUnit } from '../../models/mfactor/MeasurementUnit'

function stringToEnum<T extends Record<string, string>>(enumObj: T, value: string): T[keyof T] | undefined {
  return Object.values(enumObj).includes(value) ? (value as T[keyof T]) : undefined
}

export const toFarsiText = (unit?: MeasurementUnit | string): string => {
  const normalizedUnit = typeof unit === 'string' ? stringToEnum(MeasurementUnit, unit) : unit
  switch (normalizedUnit) {
    case MeasurementUnit.AMPERE:
      return 'آمپر'
    case MeasurementUnit.BANKEH:
      return 'بانکه'
    case MeasurementUnit.LEAF:
      return 'برگ'
    case MeasurementUnit.PACKAGE:
      return 'بسته'
    case MeasurementUnit.BARREL:
      return 'بشکه'
    case MeasurementUnit.BOTTLE:
      return 'بطری'
    case MeasurementUnit.BLOCK:
      return 'بلوک'
    case MeasurementUnit.BUNDLE:
      return 'بندیل'
    case MeasurementUnit.PALLET:
      return 'پالت'
    case MeasurementUnit.PACKET:
      return 'پاکت'
    case MeasurementUnit.PRESS:
      return 'پرس'
    case MeasurementUnit.TANKER:
      return 'تانکر'
    case MeasurementUnit.PLANK:
      return 'تخته'
    case MeasurementUnit.TON:
      return 'تن'
    case MeasurementUnit.TON_KILOMETER:
      return 'تن کیلومتر'
    case MeasurementUnit.BALL:
      return 'توپ'
    case MeasurementUnit.TUBE:
      return 'تیوب'
    case MeasurementUnit.SECOND:
      return 'ثانیه'
    case MeasurementUnit.THOBE:
      return 'ثوب'
    case MeasurementUnit.CUP:
      return 'جام'
    case MeasurementUnit.BOX:
      return 'جعبه'
    case MeasurementUnit.PAIR:
      return 'جفت'
    case MeasurementUnit.VOLUME:
      return 'جلد'
    case MeasurementUnit.CHALIK:
      return 'چلیک'
    case MeasurementUnit.CAN:
      return 'حلب'
    case MeasurementUnit.RING_DISC:
      return 'حلقه (دیسک)'
    case MeasurementUnit.CANISTER:
      return 'دبه'
    case MeasurementUnit.HAND:
      return 'دست'
    case MeasurementUnit.DEVICE:
      return 'دستگاه'
    case MeasurementUnit.OCCURRENCE:
      return 'دفعه (time)'
    case MeasurementUnit.MINUTE:
      return 'دقیقه'
    case MeasurementUnit.DOZEN:
      return 'دوجین'
    case MeasurementUnit.DAY:
      return 'روز'
    case MeasurementUnit.ROLL:
      return 'رول'
    case MeasurementUnit.ROLL_RING:
      return 'رول - حلقه'
    case MeasurementUnit.RING:
      return 'رینگ - حلقه'
    case MeasurementUnit.SACHET:
      return 'ساشه'
    case MeasurementUnit.HOUR:
      return 'ساعت'
    case MeasurementUnit.YEAR:
      return 'سال'
    case MeasurementUnit.CENTIMETER:
      return 'سانتی متر'
    case MeasurementUnit.SQUARE_CENTIMETER:
      return 'سانتی متر مربع'
    case MeasurementUnit.BASKET:
      return 'سبد'
    case MeasurementUnit.SET:
      return 'ست'
    case MeasurementUnit.BUCKET:
      return 'سطل'
    case MeasurementUnit.MITHQAL:
      return 'سیر'
    case MeasurementUnit.CYLINDER:
      return 'سیلندر'
    case MeasurementUnit.BRANCH:
      return 'شاخه'
    case MeasurementUnit.COMB:
      return 'شانه'
    case MeasurementUnit.FLAME:
      return 'شعله'
    case MeasurementUnit.SHEET:
      return 'شیت'
    case MeasurementUnit.PAGE:
      return 'صفحه'
    case MeasurementUnit.BALE:
      return 'طاقه'
    case MeasurementUnit.TUGHRA:
      return 'طغرا'
    case MeasurementUnit.PIECE:
      return 'عدد'
    case MeasurementUnit.BALE_LARGE:
      return 'عدل'
    case MeasurementUnit.WITHOUT_PACKAGING:
      return 'فاقد بسته بندی'
    case MeasurementUnit.UNIT_FROND:
      return 'فروند'
    case MeasurementUnit.SQUARE_FOOT:
      return 'فوت مربع'
    case MeasurementUnit.MOLD:
      return 'قالب'
    case MeasurementUnit.QARASA:
      return 'قراص'
    case MeasurementUnit.QARASAH_BUNDLE:
      return 'قراصه - bundle'
    case MeasurementUnit.REEL:
      return 'قرقره'
    case MeasurementUnit.ITEM:
      return 'قطعه'
    case MeasurementUnit.TIN:
      return 'قوطی'
    case MeasurementUnit.CARAT:
      return 'قیراط'
    case MeasurementUnit.GALLON:
      return 'گالن'
    case MeasurementUnit.GRAM:
      return 'گرم'
    case MeasurementUnit.GIGABYTE_PER_SECOND:
      return 'گیگا بایت بر ثانیه'
    case MeasurementUnit.SINGLE_PIECE:
      return 'لنگه'
    case MeasurementUnit.LITER:
      return 'لیتر'
    case MeasurementUnit.GLASS:
      return 'لیوان'
    case MeasurementUnit.MONTH:
      return 'ماه'
    case MeasurementUnit.METER:
      return 'متر'
    case MeasurementUnit.SQUARE_METER:
      return 'مترمربع'
    case MeasurementUnit.CUBIC_METER:
      return 'مترمکعب'
    case MeasurementUnit.MITHQAL_WEIGHT:
      return 'مثقال'
    case MeasurementUnit.TANK:
      return 'مخزن'
    case MeasurementUnit.MEGAWATT_HOUR:
      return 'مگا وات ساعت'
    case MeasurementUnit.MEGAYONIT:
      return 'مگایونیت'
    case MeasurementUnit.MILLIAMPERE:
      return 'میلی آمپر'
    case MeasurementUnit.MILLIGRAM:
      return 'میلی گرم'
    case MeasurementUnit.MILLILITER:
      return 'میلی لیتر'
    case MeasurementUnit.MILLIMETER:
      return 'میلی متر'
    case MeasurementUnit.THREAD:
      return 'نخ'
    case MeasurementUnit.COPY:
      return 'نسخه (جلد)'
    case MeasurementUnit.PERSON:
      return 'نفر'
    case MeasurementUnit.PERSON_HOUR:
      return 'نفر-ساعت'
    case MeasurementUnit.TURN:
      return 'نوبت'
    case MeasurementUnit.HALF_DOZEN:
      return 'نیم دو جین'
    case MeasurementUnit.UNIT:
      return 'واحد'
    case MeasurementUnit.PLATE:
      return 'ورق'
    case MeasurementUnit.VIAL:
      return 'ویال'
    case MeasurementUnit.CADRE:
      return 'کادر'
    case MeasurementUnit.CARTON:
      return 'کارتن'
    case MeasurementUnit.MASTER_CARTON:
      return 'کارتن - master case'
    case MeasurementUnit.CAPSULE:
      return 'کپسول'
    case MeasurementUnit.COIL:
      return 'کلاف'
    case MeasurementUnit.BAG:
      return 'کیسه'
    case MeasurementUnit.KILOGRAM:
      return 'کیلوگرم'
    case MeasurementUnit.KILOMETER:
      return 'کیلومتر'
    case MeasurementUnit.KILOWATT_HOUR:
      return 'کیلووات ساعت'
    default:
      return ''
  }
}
