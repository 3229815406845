import { PersonType } from '../../models/mfactor/PersonType'
import { faUserSecret, faUserTie } from '@fortawesome/free-solid-svg-icons'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { IonIcon } from '@ionic/react'
import { business, peopleCircle, personCircle } from 'ionicons/icons'
import { FC } from 'react'

interface OwnProps {
  personType: PersonType
}
interface PersonTypeIconProps extends OwnProps {}
const PersonTypeIcon: FC<PersonTypeIconProps> = ({ personType }) => {
  return (
    <>
      {personType === 'REAL' && <IonIcon icon={personCircle} size='large' />}
      {personType === 'COMPANY' && <IonIcon icon={business} size='large' />}
      {personType === 'CIVIL_PARTNERSHIP' && <IonIcon icon={peopleCircle} size='large' />}
      {personType === 'FOREIGNERS' && <FontAwesomeIcon icon={faUserTie} size='xl' style={{ paddingRight: '4' }} />}
      {personType === 'END_USER' && <FontAwesomeIcon icon={faUserSecret} size='xl' style={{ paddingRight: '4' }} />}
    </>
  )
}

export default PersonTypeIcon
