import { log } from '../../components/util/Log'
import { convertToEnglish } from '../../components/util/Number'
import { failure } from '../../components/util/Toast'
import remoteErrorExtractor from '../../components/util/remoteErrorExtractor'
import { mainSlot, minorSlot } from '../../components/util/resolveSlot'
import { connect } from '../../data/connect'
import { TaxProfile } from '../../models/TaxProfile'
import { FiscalFullInformationModel } from '../../models/mfactor/FiscalFullInformationModel'
import { AppApiService } from '../../services/AppApiService'
import { MFactorService } from '../../services/MFactorService'
import { faPlug, faPlugCircleCheck } from '@fortawesome/free-solid-svg-icons'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { IonButton, IonButtons, IonCol, IonItem, IonLabel, IonText, IonTextarea, IonToggle } from '@ionic/react'
import { IonContent, IonFooter, IonPage } from '@ionic/react'
import { IonRow, useIonToast } from '@ionic/react'
import { IonHeader, IonIcon, IonInput, IonSpinner } from '@ionic/react'
import { IonTitle, IonToolbar } from '@ionic/react'
import { AxiosError, AxiosResponse } from 'axios'
import { t } from 'i18next'
import { add } from 'ionicons/icons'
import React, { FC, useEffect, useState } from 'react'

interface OwnProps {
  appPublicId?: string
  onDismiss: (data?: string | null | undefined | number, role?: string) => void
}
interface StateProps {
  hand: 'right' | 'left'
}
interface DispatchProps {}
interface TaxProfileEditModalProps extends OwnProps, StateProps, DispatchProps {}
const TaxProfileEditModal: FC<TaxProfileEditModalProps> = ({ appPublicId, onDismiss, hand }) => {
  const [modi, setTaxProfile] = useState<TaxProfile | null>({
    appPublicId,
  } as TaxProfile)
  const [fiscalFullInformationModel, setFiscalFullInformationModel] = useState<FiscalFullInformationModel | null>(null)
  const [inprogress, setInprogress] = useState(false)
  const [testing, setTesting] = useState(false)
  const [toast] = useIonToast()

  const findById = async () => {
    if (appPublicId) {
      try {
        const res: AxiosResponse = await MFactorService.Instance.get(`/v1/tax-profile/${appPublicId ?? ''}`, {})
        if (res.status === 200) {
          setTaxProfile(res.data)
        }
      } catch (err) {
        failure(remoteErrorExtractor(err), toast)
      }
    }
  }

  const store = async (silent = false) => {
    setInprogress(true)
    let actualAppPublicId = appPublicId
    try {
      const resApp: AxiosResponse | any = await AppApiService.Instance.post(
        `/v1/apps/${!!appPublicId ? appPublicId : ''}`,
        { name: modi?.name, appPublicId }
      )
      if (resApp.status === 200 || resApp.status === 201) {
        actualAppPublicId = resApp.data.publicId ?? appPublicId
      }
      const res: AxiosResponse = await MFactorService.Instance.post(`/v1/tax-profile/${actualAppPublicId}`, {
        ...modi,
        appPublicId: actualAppPublicId,
      })
      if (res.status === 200 || res.status === 201) {
        if (!silent) {
          setTaxProfile(null)
          onDismiss(null, 'done')
        }
      }
    } catch (err: AxiosError | any) {
      log(err)
      failure(remoteErrorExtractor(err), toast)
    }

    setInprogress(false)
  }

  const verifyTaxProfile = async () => {
    setTesting(true)
    await store(true)
    try {
      const res: AxiosResponse = await MFactorService.Instance.post(`/v1/tax-profile/${modi?.appPublicId}/verify`, {})
      if (res.status === 200 || res.status === 201) {
        const model: FiscalFullInformationModel = res.data
        setTaxProfile({
          ...modi,
          nationalId: model.nationalId || modi?.nationalId,
          economicCode: model.economicCode || modi?.economicCode,
        } as TaxProfile)
        setFiscalFullInformationModel(model)
        await findById()
      }
    } catch (err: AxiosError | any) {
      log(err)
      failure(remoteErrorExtractor(err), toast)
    }

    setTesting(false)
  }

  useEffect(() => {
    findById()
  }, [])

  return (
    <IonPage>
      <IonHeader>
        <IonToolbar>
          <IonButtons slot={minorSlot(hand, document.documentElement.dir)}>
            <IonButton onClick={() => onDismiss(null, 'cancel')}>{t<string>('Cancel')}</IonButton>
          </IonButtons>
          <IonTitle>{t<string>(appPublicId ? 'Edit modi' : 'New modi')}</IonTitle>
          <IonButtons slot={mainSlot(hand, document.documentElement.dir)}>
            {!!fiscalFullInformationModel?.fiscalStatus && (
              <IonText
                color={
                  fiscalFullInformationModel?.fiscalStatus === 'INVALID'
                    ? 'danger'
                    : fiscalFullInformationModel?.fiscalStatus === 'ACTIVE'
                    ? 'success'
                    : ''
                }
              >
                {fiscalFullInformationModel?.fiscalStatus}
              </IonText>
            )}
          </IonButtons>
        </IonToolbar>
      </IonHeader>
      <IonContent>
        <IonRow className='ion-padding-horizontal'>
          <IonCol>
            <IonInput
              disabled={inprogress}
              value={modi?.name}
              onIonInput={(e) => setTaxProfile({ ...modi, name: e.detail.value } as TaxProfile)}
              label={t('Name')}
              placeholder={t('Name')}
              required={true}
            ></IonInput>
          </IonCol>
        </IonRow>
        <IonRow className='ion-padding-horizontal'>
          <IonCol>
            <IonInput
              disabled={inprogress}
              value={modi?.memoryId}
              onIonInput={(e) =>
                setTaxProfile({
                  ...modi,
                  memoryId: convertToEnglish(e.detail.value),
                } as TaxProfile)
              }
              label={t('Tax Memory Id')}
              placeholder={t('Tax Memory Id')}
            ></IonInput>
          </IonCol>
        </IonRow>
        <IonRow className='ion-padding-horizontal'>
          <IonCol>
            {modi?.certificate === 'y' && (
              <IonItem lines='none'>
                <IonLabel>{t<string>('Certificate')}</IonLabel>
                <IonButton
                  disabled={inprogress}
                  onClick={() =>
                    setTaxProfile({
                      ...modi,
                      certificate: '',
                    })
                  }
                >
                  {t<string>('Edit')}
                </IonButton>
              </IonItem>
            )}
            {modi?.certificate !== 'y' && (
              <IonTextarea
                disabled={inprogress}
                value={modi?.certificate}
                onIonInput={(e) =>
                  setTaxProfile({
                    ...modi,
                    certificate: e.detail.value,
                  } as TaxProfile)
                }
                placeholder={t('Certificate')}
                rows={3}
                cols={3}
              ></IonTextarea>
            )}
          </IonCol>
        </IonRow>
        <IonRow className='ion-padding-horizontal'>
          <IonCol>
            {modi?.privateKey === 'y' && (
              <IonItem lines='none'>
                <IonLabel>{t<string>('Private key')}</IonLabel>
                <IonButton
                  disabled={inprogress}
                  onClick={() =>
                    setTaxProfile({
                      ...modi,
                      privateKey: '',
                    })
                  }
                >
                  {t<string>('Edit')}
                </IonButton>
              </IonItem>
            )}
            {modi?.privateKey !== 'y' && (
              <IonTextarea
                disabled={inprogress}
                value={modi?.privateKey}
                onIonInput={(e) =>
                  setTaxProfile({
                    ...modi,
                    privateKey: e.detail.value,
                  } as TaxProfile)
                }
                placeholder={t('Private key')}
                rows={3}
                cols={3}
              ></IonTextarea>
            )}
          </IonCol>
        </IonRow>
        {modi?.verified && (
          <>
            <IonRow className='ion-padding-horizontal'>
              <IonCol>
                <IonInput
                  disabled={true}
                  value={modi?.nationalId}
                  label={t('National Id')}
                  placeholder={t('National Id')}
                ></IonInput>
              </IonCol>
            </IonRow>
            <IonRow className='ion-padding-horizontal'>
              <IonCol>
                <IonInput
                  disabled={true}
                  value={modi?.economicCode}
                  label={t('Economic Code')}
                  placeholder={t('Economic Code')}
                ></IonInput>
              </IonCol>
            </IonRow>
          </>
        )}
        <IonRow>
          <IonCol>
            <IonItem lines='none'>
              <IonToggle
                disabled={inprogress}
                aria-label={t('Sandbox')}
                labelPlacement='start'
                checked={modi?.sandbox}
                onIonChange={(e) => {
                  setTaxProfile({
                    ...modi,
                    sandbox: e.detail.checked,
                  } as TaxProfile)
                }}
              >
                <IonLabel>{t<string>('Sandbox')}</IonLabel>
              </IonToggle>
            </IonItem>
            <IonItem lines='none'>
              <IonLabel>
                <p>
                  {modi?.sandbox === true && (
                    <a href='https://sandboxrc.tax.gov.ir' target='_blank'>
                      https://sandboxrc.tax.gov.ir
                    </a>
                  )}
                  {modi?.sandbox === false && (
                    <a href='https://tp.tax.gov.ir' target='_blank'>
                      https://tp.tax.gov.ir
                    </a>
                  )}
                </p>
              </IonLabel>
            </IonItem>
          </IonCol>
        </IonRow>
        {!!fiscalFullInformationModel && (
          <>
            <IonRow className='ion-padding-horizontal'>
              <IonCol size='3'>{t<string>('Fiscal status')}</IonCol>
              <IonCol>
                <IonText
                  color={
                    fiscalFullInformationModel?.fiscalStatus === 'INVALID'
                      ? 'danger'
                      : fiscalFullInformationModel?.fiscalStatus === 'ACTIVE'
                      ? 'success'
                      : ''
                  }
                >
                  {fiscalFullInformationModel?.fiscalStatus}
                </IonText>
              </IonCol>
            </IonRow>
            {!!fiscalFullInformationModel?.saleThreshold && (
              <IonRow className='ion-padding-horizontal'>
                <IonCol size='3'>{t<string>('Sale threshold')}</IonCol>
                <IonCol>{fiscalFullInformationModel.saleThreshold}</IonCol>
              </IonRow>
            )}
          </>
        )}
      </IonContent>
      <IonFooter>
        <IonToolbar>
          <IonButtons slot={mainSlot(hand, document.documentElement.dir)}>
            <IonButton onClick={() => store()} disabled={inprogress} color='primary' fill='outline'>
              {!inprogress && <IonIcon icon={add} slot='start' />}
              {inprogress && <IonSpinner slot='start' />}
              {t<string>('Save')}
            </IonButton>
          </IonButtons>
          <IonButtons slot={minorSlot(hand, document.documentElement.dir)}>
            {!!modi?.appPublicId && !!modi?.memoryId && !!modi?.certificate && !!modi.privateKey && (
              <IonButton
                onClick={() => verifyTaxProfile()}
                disabled={inprogress}
                color={
                  fiscalFullInformationModel?.fiscalStatus === 'INVALID'
                    ? 'danger'
                    : fiscalFullInformationModel?.fiscalStatus === 'ACTIVE'
                    ? 'success'
                    : ''
                }
                fill='outline'
              >
                {!testing && (
                  <IonText slot='start' className='ion-padding-end'>
                    {' '}
                    <FontAwesomeIcon
                      icon={fiscalFullInformationModel?.fiscalStatus === 'ACTIVE' ? faPlugCircleCheck : faPlug}
                    />
                  </IonText>
                )}
                {testing && <IonSpinner slot='start' />}
                {t<string>('Verification')}
              </IonButton>
            )}
          </IonButtons>
        </IonToolbar>
      </IonFooter>
    </IonPage>
  )
}

export default connect<OwnProps, StateProps, DispatchProps>({
  mapStateToProps: (state) => ({
    hand: state.user.hand,
  }),
  mapDispatchToProps: {},
  component: React.memo(TaxProfileEditModal),
})
