import CurrencyIonInput from '../components/CurrencyIonInput'
import MeasurementUnitSelect from '../components/select/MeasurementUnitSelect'
import TaxIrStuffSelect from '../components/select/TaxIrStuffSelect'
import { log } from '../components/util/Log'
import { failure, info } from '../components/util/Toast'
import { farsiToEnglish } from '../components/util/Word'
import remoteErrorExtractor from '../components/util/remoteErrorExtractor'
import { mainSlot, minorSlot } from '../components/util/resolveSlot'
import { connect } from '../data/connect'
import { setRefreshProductList } from '../data/factor/factor.actions'
import { App } from '../models/App'
import Product from '../models/gtin/Product'
import { GenericOption } from '../models/mfactor/GenericOption'
import { TaxIrStuffOption } from '../models/mfactor/TaxIrStuffOption'
import { GtinService } from '../services/GtinService'
import { TranslateService } from '../services/TranslateService'
import BarcodeScannerModal from './BarcodeScannerModal'
import { IonButton, IonButtons, IonCheckbox, IonCol, IonLabel } from '@ionic/react'
import { IonContent, IonFooter, IonPage } from '@ionic/react'
import { IonRow, useIonToast } from '@ionic/react'
import { IonHeader, IonIcon, IonInput, IonSpinner, IonText } from '@ionic/react'
import { IonItem, IonTitle, IonToolbar } from '@ionic/react'
import { AxiosError, AxiosResponse } from 'axios'
import { t } from 'i18next'
import { add, barcode, language } from 'ionicons/icons'
import React, { FC, useEffect, useState } from 'react'
import CopyToClipboard from 'react-copy-to-clipboard'

interface OwnProps {
  gtin?: string
  name?: string
  nameFa?: string
  price?: number
  onDismiss: (data?: string | null | undefined | number, role?: string) => void
}
interface StateProps {
  lang?: string
  currency?: string
  hand: 'right' | 'left'
  app?: App
  refreshProductList: boolean
}
interface DispatchProps {
  setRefreshProductList: typeof setRefreshProductList
}
interface ProductEditModalProps extends OwnProps, StateProps, DispatchProps {}
const ProductEditModal: FC<ProductEditModalProps> = ({
  gtin,
  name,
  nameFa,
  price,
  onDismiss,
  currency,
  lang,
  hand,
  app,
  refreshProductList,
  setRefreshProductList,
}) => {
  const [product, setProduct] = useState<Product | any>({
    gtin,
    name,
    nameFa,
    price,
  })
  const [inprogress, setInprogress] = useState(false)
  const [translating, setTranslating] = useState(false)
  const [fetching, setFetching] = useState(false)
  const [toast] = useIonToast()

  const findById = async () => {
    if (!!gtin && !!app?.publicId) {
      try {
        const res: AxiosResponse = await GtinService.Instance.get(`/v1/app-product/${app?.publicId}/${gtin}`, {})
        if (res.status === 200) {
          setProduct(res.data)
        }
      } catch (err) {
        failure(remoteErrorExtractor(err), toast)
      }
    }
  }

  const fetchProductInfo = async (gtin: string) => {
    setFetching(true)
    try {
      const res = await GtinService.Instance.get(`/v1/pip/${gtin}/${product?.currency || currency}`)
      if (res.status === 200) {
        if (!!res?.data?.name && !!res?.data?.nameFa)
          setProduct({
            ...product,
            gtin: gtin,
            name: res?.data?.name,
            nameFa: res?.data?.nameFa,
            price: res.data.price,
            discount: res.data.discount,
          })
        else if (!!res?.data?.name)
          setProduct({
            ...product,
            gtin: gtin,
            name: res?.data?.name,
            price: res.data.price,
            discount: res.data.discount,
          })
        else if (!!res?.data?.nameFa)
          setProduct({
            ...product,
            gtin: gtin,
            nameFa: res?.data?.nameFa,
            price: res.data.price,
            discount: res.data.discount,
          })
      }
    } catch (err) {
      failure('Product info not found', toast)
    }
    setFetching(false)
  }

  const translateToEnglish = async () => {
    setTranslating(true)
    const res = await TranslateService.Instance.toEnglish(product?.nameFa)
    if (res?.status === 200) {
      setProduct({ ...product, name: res?.data?.translatedText })
    }
    setTranslating(false)
  }

  const translateToPersian = async () => {
    setTranslating(true)
    const res = await TranslateService.Instance.toPersian(product?.name)
    if (res?.status === 200) {
      setProduct({ ...product, nameFa: res?.data?.translatedText })
    }
    setTranslating(false)
  }

  const store = async () => {
    if (+product?.discount > +product?.price) {
      failure('Discount is higher than price', toast)
      return
    }
    setInprogress(true)
    if (!!app?.publicId) {
      let serverSideProduct = { gtin: '', name: '', nameFa: '' }
      try {
        if (!!product?.gtin) {
          const res: AxiosResponse = await GtinService.Instance.post(`/v1/gs1-wiki/create`, {
            gtin: product?.gtin,
            name: product?.name ?? farsiToEnglish(product?.nameFa),
            nameFa: product?.nameFa,
          })
          if (res.status === 200 || res.status === 201) {
            serverSideProduct = {
              gtin: res.data.gtin,
              name: res.data.name,
              nameFa: res.data.nameFa,
            }
            setProduct({
              ...product,
              name: res.data.name,
              nameFa: res.data.nameFa,
            })
          }
        } else {
          const res: AxiosResponse = await GtinService.Instance.post(`/v1/gs1-wiki/create-local-fa`, {
            name: product?.name ?? farsiToEnglish(product?.nameFa),
            nameFa: product?.nameFa,
          })
          if (res.status === 200 || res.status === 201) {
            serverSideProduct = {
              gtin: res.data.gtin,
              name: res.data.name,
              nameFa: res.data.nameFa,
            }
            setProduct({
              ...product,
              id: res.data.gtin,
              name: res.data.name,
              nameFa: res.data.nameFa,
            })
          }
        }
        try {
          const res: AxiosResponse = await GtinService.Instance.post(
            `/v1/app-product/${app?.publicId}/${serverSideProduct.gtin}`,
            {
              gtin: serverSideProduct.gtin,
              appPublicId: app?.publicId,
              price: +product?.price,
              discount: +product?.discount,
              currency: product?.currency || currency,
              isPriceDynamic: product?.isPriceDynamic,
              measurementUnit: product?.measurementUnit,
              taxIrStuffId: product?.taxIrStuffId,
              taxIrStuffDesc: product?.taxIrStuffDesc,
            }
          )
          if (res.status === 200 || res.status === 201) {
            setProduct(undefined)
            setRefreshProductList(!refreshProductList)
            onDismiss(null, 'done')
          } else {
            failure(`Error ${res.status}`, toast)
          }
        } catch (err) {
          failure(remoteErrorExtractor(err), toast)
        }
      } catch (err: AxiosError | any) {
        log(err)
        failure(remoteErrorExtractor(err), toast)
        setInprogress(false)
      }
    } else {
      failure('Please select your app first', toast)
    }
    setInprogress(false)
  }

  const renderName = () => (
    <IonRow className='dir-ltr ion-padding-horizontal'>
      <IonCol>
        <IonInput
          value={product?.name}
          onIonChange={(e) => setProduct({ ...product, name: e.detail.value })}
          label='Name'
          placeholder={t('in English')}
          disabled={inprogress || translating || !!product?.id}
          className='dir-ltr'
        ></IonInput>
      </IonCol>
      {false && (
        <IonCol size='1'>
          <IonButton
            disabled={true || translating || !(!!product?.nameFa && !product?.name)}
            onClick={translateToEnglish}
            color='light'
          >
            <IonIcon icon={language} />
          </IonButton>
        </IonCol>
      )}
    </IonRow>
  )

  const renderNameFa = () => (
    <IonRow className='dir-rtl  ion-padding-horizontal'>
      <IonCol>
        <IonInput
          label='نام'
          value={product?.nameFa}
          onIonChange={(e: any) => setProduct({ ...product, nameFa: e.detail.value })}
          placeholder={t('in Farsi')}
          disabled={inprogress || translating || !!product?.id}
          required={true}
        ></IonInput>
      </IonCol>
      {false && (
        <IonCol size='1'>
          <IonButton
            disabled={true || translating || !(!!product?.name && !product?.nameFa)}
            onClick={translateToPersian}
            color='light'
          >
            <IonIcon icon={language} />
          </IonButton>
        </IonCol>
      )}
    </IonRow>
  )

  useEffect(() => {
    findById()
  }, [])

  return (
    <IonPage>
      <IonHeader>
        <IonToolbar>
          <IonButtons slot={minorSlot(hand, document.documentElement.dir)}>
            <IonButton onClick={() => onDismiss(null, 'cancel')}>{t<string>('Cancel')}</IonButton>
          </IonButtons>
          <IonTitle>{t<string>('New product')}</IonTitle>
        </IonToolbar>
      </IonHeader>
      <IonContent>
        <IonItem>
          <IonInput value={product?.gtin} disabled={true} label={t('Id')} labelPlacement='floating'></IonInput>
          {!product?.id && (
            <IonButton slot='start' id='open-barcode-scanner-modal'>
              <IonIcon icon={barcode}></IonIcon>
            </IonButton>
          )}
          {fetching && <IonSpinner name='dots' />}
          <CopyToClipboard text={product?.gtin} onCopy={() => info('Copied X', toast, { X: product?.gtin })}>
            <IonText slot='end'>GTIN</IonText>
          </CopyToClipboard>
        </IonItem>
        {renderNameFa()}
        <div className='ion-padding-horizontal'>
          <CurrencyIonInput
            defaultValue={product?.price || null}
            defaultCurrency={product?.currency}
            onValueChange={(value: any) => setProduct({ ...product, price: value })}
            onCurrencyChange={(value: any) => setProduct({ ...product, currency: value })}
            label={t<string>('Price')}
            disabled={inprogress}
          />
        </div>
        <div className='ion-padding-horizontal'>
          <CurrencyIonInput
            defaultValue={product?.discount || null}
            defaultCurrency={product?.currency}
            onValueChange={(value: any) => setProduct({ ...product, discount: value })}
            onCurrencyChange={(value: any) => setProduct({ ...product, currency: value })}
            label={t<string>('Discount')}
            disabled={inprogress}
          />
        </div>
        <IonItem>
          <IonCheckbox
            checked={product?.isPriceDynamic}
            disabled={inprogress}
            onIonChange={(e) => setProduct({ ...product, isPriceDynamic: e.detail.checked })}
          >
            {t<string>('Prompt for price every time')}
          </IonCheckbox>
        </IonItem>
        <IonRow className='ion-align-items-center'>
          <IonCol size='3'>
            <IonLabel className='ion-padding-start'>{t<string>('Measurement unit')}</IonLabel>
          </IonCol>
          <IonCol>
            <MeasurementUnitSelect
              defaultValue={product?.measurementUnit}
              onChange={(value?: GenericOption) => {
                setProduct({
                  ...product,
                  measurementUnit: value?.value,
                })
              }}
            />
          </IonCol>
        </IonRow>
        <TaxIrStuffSelect
          defaultId={product?.taxIrStuffId}
          defaultDesc={product?.taxIrStuffDesc}
          productName={product?.nameFa}
          onChange={(value?: TaxIrStuffOption) => {
            // if (!!value) {
            setProduct({
              ...product,
              taxIrStuffId: value?.value,
              taxIrStuffDesc: value?.label,
            })
            // }
          }}
        />
      </IonContent>
      <IonFooter>
        <IonToolbar>
          <IonButtons slot={mainSlot(hand, document.documentElement.dir)}>
            <IonButton onClick={() => store()} disabled={inprogress} color='primary' fill='outline'>
              {!inprogress && <IonIcon icon={add} slot='start' />}
              {inprogress && <IonSpinner slot='start' />}
              {t<string>('Save')}
            </IonButton>
          </IonButtons>
        </IonToolbar>
      </IonFooter>
      <BarcodeScannerModal
        onDetected={(result: any) => {
          setProduct({ ...product, gtin: result })
          fetchProductInfo(result)
        }}
      />
    </IonPage>
  )
}

export default connect<OwnProps, StateProps, DispatchProps>({
  mapStateToProps: (state) => ({
    lang: state.user.lang,
    currency: state.user.currency,
    hand: state.user.hand,
    app: state.user.app,
    refreshProductList: state.factor.refreshProductList,
  }),
  mapDispatchToProps: {
    setRefreshProductList,
  },
  component: React.memo(ProductEditModal),
})
