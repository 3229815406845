import { connect } from '../data/connect'
import React, { FC } from 'react'
import { sha } from '~build/info'
import now from '~build/time'

interface StateProps {
  lang?: string
}

interface CopyRightAndVersionProps extends StateProps {}

const CopyRightAndVersion: FC<CopyRightAndVersionProps> = ({ lang }) => {
  return (
    <div className='copyright ion-padding-start dir-ltr'>
      &copy;{lang === 'fa' ? '1403' : '2025'},{' '}
      <a href='https://mfactor.ir' style={{ textDecoration: 'none', color: 'black' }}>
        mFactor.ir
      </a>
      ,{' '}
      <span title={now + sha?.substring(0, 6)}>
        v{APP_VERSION}-{sha?.substring(0, 6)}
      </span>
    </div>
  )
}

export default connect<{}, StateProps, {}>({
  mapStateToProps: (state) => ({
    lang: state.user.lang,
  }),
  component: React.memo(CopyRightAndVersion),
})
