import PersonEditModal from './PersonEditModal'
import { IonButton, IonIcon, useIonModal } from '@ionic/react'
import { t } from 'i18next'
import { add } from 'ionicons/icons'
import React, { FC } from 'react'

interface PersonAddButtonProps {
  onDismiss: (data?: string | null | undefined | number, role?: string) => void
}
const PersonAddButton: FC<PersonAddButtonProps> = ({ onDismiss }) => {
  const [present, dismiss] = useIonModal(PersonEditModal, {
    id: null,
    onDismiss: (data: string, role: string) => dismiss(data, role),
  })
  return (
    <>
      <IonButton
        onClick={() =>
          present({
            id: undefined,
            breakpoints: [0, 1],
            initialBreakpoint: 1,
          })
        }
      >
        <IonIcon icon={add} slot='start' />
        {t<string>('New person')}
      </IonButton>
    </>
  )
}

export default React.memo(PersonAddButton)
