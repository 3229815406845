import '../../components/select.scss'
import { connect } from '../../data/connect'
import { InvoiceSubject } from '../../models/mfactor/TaxRecord'
import { IonCol, IonRow, IonSelect, IonSelectOption } from '@ionic/react'
import { t } from 'i18next'
import { FC, memo, useEffect, useState } from 'react'

interface OwnProps {
  defaultValue?: InvoiceSubject
  onChange: (value: InvoiceSubject) => void
  disabled?: boolean
}
interface StateProps {}
interface DispatchProps {}
interface InvoiceSubjectSelectProps extends OwnProps, StateProps, DispatchProps {}
const InvoiceSubjectSelect: FC<InvoiceSubjectSelectProps> = ({ defaultValue, onChange, disabled = false }) => {
  const [selectedOption, setSelectedOption] = useState<InvoiceSubject | undefined>(defaultValue)

  useEffect(() => {
    if (selectedOption) onChange(selectedOption)
  }, [selectedOption])

  useEffect(() => {
    setSelectedOption(defaultValue)
  }, [defaultValue])

  return (
    <>
      <IonRow>
        <IonCol>
          <IonSelect
            label={t('Invoice subject')}
            disabled={disabled}
            value={selectedOption}
            onIonChange={(ev) => setSelectedOption(ev.detail.value)}
            okText={t('Select')}
            cancelText={t('Cancel')}
            interface='popover'
          >
            <IonSelectOption value={'MAIN'}>{t<string>('Subject MAIN')}</IonSelectOption>
            <IonSelectOption value={'CORRECTION'} disabled={true}>
              {t<string>('Subject CORRECTION')}
            </IonSelectOption>
            <IonSelectOption value={'INVALID'} disabled={true}>
              {t<string>('Subject INVALID')}
            </IonSelectOption>
          </IonSelect>
        </IonCol>
      </IonRow>
    </>
  )
}

export default connect<OwnProps, StateProps, DispatchProps>({
  mapStateToProps: () => ({}),
  component: memo(InvoiceSubjectSelect),
})
