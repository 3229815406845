import { dateTimeDisplay } from '../../components/util/Calendar'
import { failure } from '../../components/util/Toast'
import { fixKafYe } from '../../components/util/Word'
import currencySign from '../../components/util/currencySign'
import remoteErrorExtractor from '../../components/util/remoteErrorExtractor'
import { connect } from '../../data/connect'
import { setRefreshFactorList } from '../../data/factor/factor.actions'
import { App } from '../../models/App'
import Factor from '../../models/mfactor/Factor'
import { MFactorDbService } from '../../services/MFactorDbService'
import { MFactorService } from '../../services/MFactorService'
import FactorEditModal from './FactorEditModal'
import { IonButton, IonCol, IonRow, IonSearchbar, IonSelect, IonSelectOption } from '@ionic/react'
import { IonText, useIonModal } from '@ionic/react'
import { IonIcon, IonItem, IonItemOption, IonItemOptions } from '@ionic/react'
import { IonItemSliding, IonLabel, useIonToast } from '@ionic/react'
import { AxiosResponse } from 'axios'
import { t } from 'i18next'
import { trashBin } from 'ionicons/icons'
import React, { FC, useEffect, useState } from 'react'
import { isMobile } from 'react-device-detect'

interface OwnProps {}
interface StateProps {
  app?: App
  lang?: string
  refreshFactorList: boolean
}
interface DispatchProps {
  setRefreshFactorList: typeof setRefreshFactorList
}
interface FactorListProps extends OwnProps, StateProps, DispatchProps {}
const FactorList: FC<FactorListProps> = ({ app, lang, refreshFactorList, setRefreshFactorList }) => {
  const [toast] = useIonToast()
  const [factors, setFactors] = useState<Factor[]>([])
  const [deleting, setDeleting] = useState(false)
  const [showPayers, setShowPayers] = useState(false)
  const [search, setSearch] = useState<string>()
  const [id, setId] = useState<string>()
  const [inprogress, setInprogress] = useState(false)
  const [page, setPage] = useState(0)
  const [size, setSize] = useState(5)
  const [present, dismiss] = useIonModal(FactorEditModal, {
    id,
    onDismiss: (data: string, role: string) => {
      dismiss(data, role)
      if (role === 'deleted') fetchFactors()
    },
  })

  const fetchFactors = async () => {
    if (app?.publicId) {
      setInprogress(true)

      try {
        const res: AxiosResponse = await MFactorService.Instance.get(
          `/v1/factor/${app?.publicId}?page=${page}&size=${size}${!!search ? `&search=${search}` : ''}`,
          {}
        )
        if (res.status === 200) setFactors(res.data)
      } catch (err: any) {
        failure(remoteErrorExtractor(err), toast)
      }
      setInprogress(false)
    }
  }

  const deleteFactor = async (factor: Factor) => {
    if (app?.publicId) {
      setDeleting(true)
      try {
        const res: AxiosResponse = await MFactorService.Instance.delete(`/v1/factor/${app?.publicId}/${factor.id}`, {})
        if (res.status === 204) await fetchFactors()
      } catch (err) {
        failure(remoteErrorExtractor(err), toast)
      }
      setDeleting(false)
    }
  }

  useEffect(() => {
    fetchFactors()
  }, [refreshFactorList, app?.publicId, search, page, size])

  useEffect(() => {
    setPage(0)
  }, [search, app?.publicId])

  return (
    <>
      <IonRow>
        <IonCol>
          <IonSearchbar
            debounce={500}
            placeholder={t('Search factor')}
            onIonInput={(e: any) => setSearch(fixKafYe(e.detail.value))}
          ></IonSearchbar>
        </IonCol>
        <IonCol size='1'>
          <IonSelect
            value={size}
            onIonChange={(e: any) => setSize(e.detail.value)}
            okText={t('Select')}
            cancelText={t('Cancel')}
            interface='popover'
          >
            <IonSelectOption value={5}>5</IonSelectOption>
            <IonSelectOption value={10}>10</IonSelectOption>
            <IonSelectOption value={25}>25</IonSelectOption>
            <IonSelectOption value={50}>50</IonSelectOption>
          </IonSelect>
        </IonCol>
      </IonRow>
      {!deleting &&
        factors.map((f: Factor) => (
          <IonItemSliding key={f.id}>
            <IonItem
              button={true}
              onClick={() => {
                setId(f.id)
                present()
              }}
            >
              {(MFactorDbService.Instance.personCache.get(f.payer) || showPayers) && (
                <IonText slot={lang === 'fa' ? 'start' : 'end'}>{f.payerName}</IonText>
              )}
              {!isMobile && <IonText slot={lang === 'fa' ? 'start' : 'end'}>{f?.id}</IonText>}
              <IonLabel className='dir-ltr'>
                {currencySign(f.currency)} {f.total?.toLocaleString(navigator.language)}
                <p>
                  <span
                    title={f.factorDate}
                    style={{ display: 'inline-block' }}
                    className={lang === 'fa' ? 'dir-rtl' : 'dir-ltr'}
                  >
                    {dateTimeDisplay(f.factorDate, lang)}
                  </span>
                </p>
              </IonLabel>
            </IonItem>
            <IonItemOptions>
              <IonItemOption color={'danger'} onClick={() => deleteFactor(f)} disabled={deleting}>
                <IonIcon slot='icon-only' icon={trashBin} />
              </IonItemOption>
            </IonItemOptions>
          </IonItemSliding>
        ))}
      <IonRow className='ion-align-items-center ion-padding-top'>
        <IonCol className='ion-text-center'>
          {page > 0 && (
            <IonButton onClick={() => setPage(page - 1)} disabled={inprogress} fill='clear'>
              {t<string>('Previous')}
            </IonButton>
          )}
        </IonCol>
        <IonCol className='ion-text-center'>{t<string>('Page X', { X: page + 1 })}</IonCol>
        <IonCol className='ion-text-center'>
          {factors.length === size && (
            <IonButton onClick={() => setPage(page + 1)} disabled={inprogress} fill='clear'>
              {t<string>('Next')}
            </IonButton>
          )}
        </IonCol>
      </IonRow>
    </>
  )
}

export default connect<OwnProps, StateProps, DispatchProps>({
  mapStateToProps: (state) => ({
    app: state.user.app,
    lang: state.user.lang,
    syncing: state.factor.syncing,
    refreshFactorList: state.factor.refreshFactorList,
  }),
  mapDispatchToProps: {
    setRefreshFactorList,
  },
  component: React.memo(FactorList),
})
