import NumberIonInput from '../../components/NumberIonInput'
import InvoiceTemplateSelect from '../../components/select/InvoicePatternSelect'
import InvoicePaymentMethodType from '../../components/select/InvoicePaymentMethodType'
import InvoiceSettlementMethod from '../../components/select/InvoiceSettlementMethod'
import InvoiceSubjectSelect from '../../components/select/InvoiceSubjectSelect'
import InvoiceTypeSelect from '../../components/select/InvoiceTypeSelect'
import { dateTimeDisplay } from '../../components/util/Calendar'
import TkDateTime from '../../components/util/TkDateTime'
import { connect } from '../../data/connect'
import { addFactorItem, setTaxFactor } from '../../data/factor/factor.actions'
import { FactorItem } from '../../models/mfactor/FactorItem'
import { TaxFactor } from '../../models/mfactor/TaxFactor'
import { InvoiceSubject } from '../../models/mfactor/TaxRecord'
import FactorItems from '../factor/FactorItems'
import FactorTotal from '../factor/FactorTotal'
import { PersonOption } from '../person/PersonOption'
import PersonSelect from '../person/PersonSelect'
import ProductSelectCard from '../product/ProductSelectCard'
import TaxRecordSerialInput from '../tax-record/TaxRecordSerialInput'
import { IonCol, IonInput, IonRow, IonText } from '@ionic/react'
import { t } from 'i18next'
import React, { FC, useEffect, useState } from 'react'

interface OwnProps {}
interface StateProps {
  factorItemList: FactorItem[]
  factorSaving: boolean
  taxFactor?: TaxFactor
}
interface DispatchProps {
  setTaxFactor: typeof setTaxFactor
  addFactorItem: typeof addFactorItem
}
interface DeskFormProps extends OwnProps, StateProps, DispatchProps {}
const DeskForm: FC<DeskFormProps> = ({ taxFactor, factorSaving, factorItemList, setTaxFactor, addFactorItem }) => {
  const [showWithDelay, setShowWithDelay] = useState(false)
  const [factorDate, setFactorDate] = useState<string | null>(taxFactor?.factor?.factorDate || null)
  const [payer, setPayer] = useState<string | null>(taxFactor?.factor?.payer ?? null)
  const [payerName, setPayerName] = useState<string | null>(taxFactor?.factor?.payerName ?? null)
  const [serial, setSerial] = useState(taxFactor?.taxRecord?.serial)
  const [invoiceSubject, setInvoiceSubject] = useState<InvoiceSubject | undefined>(
    taxFactor?.taxRecord?.invoice?.header?.invoiceSubject
  )
  const [invoicePattern, setInvoicePattern] = useState(taxFactor?.taxRecord?.invoice?.header?.invoicePattern)
  const [invoiceType, setInvoiceType] = useState(taxFactor?.taxRecord?.invoice?.header?.invoiceType)
  const [settlementMethod, setSettlementMethod] = useState(taxFactor?.taxRecord?.invoice?.header?.settlementMethod)
  const [sellerBranchCode, setSellerBranchCode] = useState(taxFactor?.taxRecord?.invoice?.header?.sellerBranchCode)
  const [buyerBranchCode, setBuyerBranchCode] = useState(taxFactor?.taxRecord?.invoice?.header?.buyerBranchCode)
  const [paymentSwitchNumber, setPaymentSwitchNumber] = useState(
    taxFactor?.taxRecord?.invoice?.payments?.[0]?.paymentSwitchNumber
  )
  const [acquirerNumber, setAcquirerNumber] = useState(taxFactor?.taxRecord?.invoice?.payments?.[0]?.acquirerNumber)
  const [terminalNumber, setTerminalNumber] = useState(taxFactor?.taxRecord?.invoice?.payments?.[0]?.terminalNumber)
  const [paymentMethodType, setPaymentMethodType] = useState(
    taxFactor?.taxRecord?.invoice?.payments?.[0]?.paymentMethodType ?? null
  )
  const [paymentDateTime, setPaymentDateTime] = useState<string | null>(
    taxFactor?.taxRecord?.invoice?.payments?.[0]?.paymentDateTime ?? null
  )
  const [trackingNumber, setTrackingNumber] = useState(taxFactor?.taxRecord?.invoice?.payments?.[0]?.trackingNumber)

  useEffect(() => {
    setTimeout(() => {
      setShowWithDelay(true)
    }, 300)
  }, [factorSaving])

  const setTaxFactorForReal = () => {
    setTaxFactor({
      ...taxFactor,
      factor: {
        ...taxFactor?.factor,
        factorDate,
        payer,
        payerName,
      },
      taxRecord: {
        ...taxFactor?.taxRecord,
        serial,
        invoice: {
          ...taxFactor?.taxRecord?.invoice,
          header: {
            ...taxFactor?.taxRecord?.invoice?.header,
            invoiceSubject,
            invoicePattern,
            invoiceType,
            settlementMethod,
            sellerBranchCode,
            buyerBranchCode,
          },
          payments: [
            {
              ...taxFactor?.taxRecord?.invoice?.payments?.[0],
              paymentSwitchNumber,
              acquirerNumber,
              terminalNumber,
              paymentMethodType,
              paymentDateTime,
              trackingNumber,
            },
          ],
        },
      },
    })
  }

  const disabled = () => factorSaving || (taxFactor?.taxRecord?.status && taxFactor?.taxRecord?.status !== 'FAILED')

  useEffect(() => {
    setFactorDate(taxFactor?.factor?.factorDate || null)
    setPayer(taxFactor?.factor?.payer || null)
    setPayerName(taxFactor?.factor?.payerName || null)
    setSerial(taxFactor?.taxRecord?.serial)
    setInvoiceSubject(taxFactor?.taxRecord?.invoice?.header?.invoiceSubject)
    setInvoicePattern(taxFactor?.taxRecord?.invoice?.header?.invoicePattern)
    setInvoiceType(taxFactor?.taxRecord?.invoice?.header?.invoiceType)
    setSettlementMethod(taxFactor?.taxRecord?.invoice?.header?.settlementMethod)
    setSellerBranchCode(taxFactor?.taxRecord?.invoice?.header?.sellerBranchCode)
    setBuyerBranchCode(taxFactor?.taxRecord?.invoice?.header?.buyerBranchCode)
    setPaymentSwitchNumber(taxFactor?.taxRecord?.invoice?.payments?.[0]?.paymentSwitchNumber)
    setAcquirerNumber(taxFactor?.taxRecord?.invoice?.payments?.[0]?.acquirerNumber)
    setTerminalNumber(taxFactor?.taxRecord?.invoice?.payments?.[0]?.terminalNumber)
    setPaymentMethodType(taxFactor?.taxRecord?.invoice?.payments?.[0]?.paymentMethodType ?? null)
    setPaymentDateTime(taxFactor?.taxRecord?.invoice?.payments?.[0]?.paymentDateTime ?? null)
    setTrackingNumber(taxFactor?.taxRecord?.invoice?.payments?.[0]?.trackingNumber)
  }, [taxFactor])

  useEffect(() => {
    setTaxFactorForReal()
  }, [
    invoiceSubject,
    invoicePattern,
    invoiceType,
    settlementMethod,
    sellerBranchCode,
    buyerBranchCode,
    paymentSwitchNumber,
    acquirerNumber,
    terminalNumber,
    paymentMethodType,
    paymentDateTime,
    trackingNumber,
    factorDate,
    payer,
    serial,
  ])

  return (
    <div>
      <IonRow className='ion-align-items-center'>
        <IonCol size='12' sizeMd='4'>
          <sub>{t<string>('Factor date')}</sub>
          {!disabled() && (
            <TkDateTime
              id='desk-factor-date'
              defaultDate={factorDate}
              onChange={(value: string | null) => setFactorDate(value)}
              calendarPopperPosition='bottom'
              disabled={disabled()}
            />
          )}
          {disabled() && (
            <IonText>
              <br />
              {factorDate && dateTimeDisplay(factorDate, 'fa')}
            </IonText>
          )}
        </IonCol>
        <IonCol size='12' sizeMd='4' className='ion-align-self-start'>
          <InvoiceSubjectSelect
            defaultValue={invoiceSubject ?? 'MAIN'}
            onChange={(value: InvoiceSubject) => {
              setInvoiceSubject(value)
            }}
            disabled={true}
          />
        </IonCol>
        <IonCol size='12' sizeMd='4'>
          <TaxRecordSerialInput
            serial={serial}
            defaultSerial={serial}
            serialType={!invoicePattern || invoicePattern === 'MAIN' ? 'MAIN' : 'OTHER'}
            onChange={(value) => {
              setSerial(value)
            }}
            disabled={disabled() || !!taxFactor?.taxRecord?.taxId}
          />
        </IonCol>
      </IonRow>
      <IonRow>
        <IonCol size='12' sizeMd='4'>
          <InvoiceTemplateSelect
            defaultValue={invoicePattern ?? 'SELL'}
            onChange={(value: string) => {
              setInvoicePattern(value)
            }}
            disabled={disabled()}
          />
        </IonCol>
        <IonCol size='12' sizeMd='4'>
          <div>
            {showWithDelay && (
              <PersonSelect
                defaultId={payer}
                onChange={(value?: PersonOption | null) => {
                  if (value?.value === null || value?.value) {
                    setPayerName(value?.label)
                    setPayer(value?.value)
                  }
                }}
                disabled={disabled()}
              />
            )}
          </div>
        </IonCol>
        <IonCol size='12' sizeMd='4' className='ion-align-self-start'>
          <InvoiceTypeSelect
            defaultValue={invoiceType ?? 'TYPE_1'}
            onChange={(value: string) => {
              setInvoiceType(value)
            }}
            disabled={disabled()}
          />
        </IonCol>
      </IonRow>
      <ProductSelectCard factorItemList={factorItemList} addFactorItem={addFactorItem} disabled={disabled()} />
      <FactorItems disabled={disabled()} />
      <IonRow>
        <IonCol>
          <FactorTotal factorItems={factorItemList} disabled={disabled()} />
        </IonCol>
      </IonRow>
      <IonRow className='ion-align-items-center'>
        <IonCol size='12' sizeMd='4' className='ion-align-self-start'>
          <InvoiceSettlementMethod
            defaultValue={settlementMethod ?? 'CASH'}
            onChange={(value: string) => {
              setSettlementMethod(value)
            }}
            disabled={disabled()}
          />
        </IonCol>
      </IonRow>
      <IonRow className='ion-align-items-center'>
        <IonCol size='12' sizeMd='4' className='ion-align-self-start'>
          <IonInput
            labelPlacement='floating'
            value={paymentSwitchNumber}
            onIonInput={(e: any) => {
              setPaymentSwitchNumber(e.detail.value)
            }}
            label={t('Payment switch number')}
            disabled={disabled()}
          />
        </IonCol>
        <IonCol size='12' sizeMd='4' className='ion-align-self-start'>
          <IonInput
            labelPlacement='floating'
            value={acquirerNumber}
            onIonInput={(e: any) => {
              setAcquirerNumber(e.detail.value)
            }}
            label={t('Merchant code')}
            disabled={disabled()}
          />
        </IonCol>
        <IonCol size='12' sizeMd='4' className='ion-align-self-start'>
          <IonInput
            labelPlacement='floating'
            value={acquirerNumber}
            onIonInput={(e: any) => {
              setAcquirerNumber(e.detail.value)
            }}
            label={t('Terminal code')}
            disabled={disabled()}
          />
        </IonCol>
      </IonRow>
      <IonRow className='ion-align-items-center'>
        <IonCol size='12' sizeMd='4' className='ion-align-self-start'>
          <IonInput
            labelPlacement='floating'
            value={trackingNumber}
            onIonInput={(e: any) => {
              setTrackingNumber(e.detail.value)
            }}
            label={t('Reference code')}
            disabled={disabled()}
          />
        </IonCol>
        <IonCol size='12' sizeMd='4' className='ion-align-self-start'>
          <InvoicePaymentMethodType
            defaultValue={paymentMethodType}
            onChange={(value: string | null) => {
              setPaymentMethodType(value)
            }}
            disabled={disabled()}
          />
        </IonCol>
        <IonCol size='12' sizeMd='4' className='ion-align-self-start'>
          {taxFactor?.taxRecord?.invoice?.payments?.[0]?.paymentMethodType && (
            <>
              <sub>{t<string>('Payment date')}</sub>
              {!disabled() && (
                <TkDateTime
                  id='desk-payment-date'
                  defaultDate={paymentDateTime}
                  onChange={(value: string | null) => {
                    setPaymentDateTime(value)
                  }}
                  calendarPopperPosition='top'
                  disabled={disabled()}
                />
              )}
              {disabled() && (
                <IonText>
                  <br />
                  {paymentDateTime && dateTimeDisplay(paymentDateTime, 'fa')}
                </IonText>
              )}
            </>
          )}
        </IonCol>
      </IonRow>
      <IonRow className='ion-align-items-center'>
        <IonCol size='12' sizeMd='4' className='ion-align-self-start'>
          <NumberIonInput
            labelPlacement='floating'
            value={sellerBranchCode}
            onValueChange={setSellerBranchCode}
            label={t('Seller branch code')}
            disabled={disabled()}
            maxlength={4}
          />
        </IonCol>
        <IonCol size='12' sizeMd='4' className='ion-align-self-start'>
          <NumberIonInput
            labelPlacement='floating'
            value={buyerBranchCode}
            onValueChange={setBuyerBranchCode}
            label={t('Buyer branch code')}
            disabled={disabled()}
            maxlength={4}
          />
        </IonCol>
      </IonRow>
    </div>
  )
}

export default connect<OwnProps, StateProps, DispatchProps>({
  mapStateToProps: (state) => ({
    factorItemList: state.factor.factorItemList,
    factorSaving: state.factor.factorSaving,
    taxFactor: state.factor.taxFactor,
  }),
  mapDispatchToProps: {
    setTaxFactor,
    addFactorItem,
  },
  component: React.memo(DeskForm),
})
